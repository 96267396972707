import { injectable } from 'inversify'
import { getImageSrc } from './app-image.utils'
import { AppImageFactoryInterface, Factory } from '~/abstracts/factory'
import { GQLImage } from '~/types/gql'
import { AppImageInterface } from '~/app/image/app-image.interface'
import { AppImage } from '~/app/image/app-image'

@injectable()
export class AppImageFactory
  extends Factory<GQLImage, AppImageInterface>
  implements AppImageFactoryInterface {
  public create(input?: GQLImage): AppImageInterface {
    return input ? this.createFromGQLImage(input) : this.createFromLocalImage()
  }

  private createFromGQLImage(gqlImage: GQLImage): AppImageInterface {
    return new AppImage({
      path: gqlImage.path,
      name: gqlImage.name,
      alt: gqlImage.alt,
      title: gqlImage.title,
    })
  }

  private createFromLocalImage(): AppImageInterface {
    return new AppImage({
      src: getImageSrc(),
      // TODO: подумать, откуда брать alt, title для заглушек
      alt: '',
      title: '',
    })
  }
}
