import { inject, injectable } from 'inversify'
import {
  Factory,
  UserAddressInputFactoryInterface,
  UserInputFactoryInterface,
} from '~/abstracts/factory'
import { User } from '~/features/user/user.interface'
import { GQLUserAddressInput, GQLUserInput } from '~/types/gql'
import DiTypes from '~/config/DiTypes'
import { ITimestamp } from '~/utils/timestamp'

@injectable()
export class UserInputFactory
  extends Factory<User, GQLUserInput>
  implements UserInputFactoryInterface {
  constructor(
    @inject(DiTypes.USER_ADDRESS_INPUT_FACTORY)
    private userAddressInputFactory: UserAddressInputFactoryInterface,
    @inject(DiTypes.TIMESTAMP)
    private timestamp: ITimestamp
  ) {
    super()
  }

  public create(input: User): GQLUserInput {
    return {
      name: input.name,
      email: input.email,
      birthday: input.birthday
        ? this.timestamp.format(input.birthday)
        : undefined,
      addresses: this.withDefaultAddress(
        this.userAddressInputFactory.fromArray(input.addresses)
      ),
    }
  }

  private withDefaultAddress(
    addresses: GQLUserAddressInput[]
  ): GQLUserAddressInput[] {
    if (!addresses.length) return []

    const hasDefault = addresses.some(
      ({ default: defaultAddress }) => defaultAddress
    )
    if (!hasDefault) {
      addresses[0].default = true
    }
    return addresses
  }
}
