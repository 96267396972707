import { HeaderDesktopMainSearch } from './components/header-desktop-main-search'

import styles from './header-desktop-main.css?module'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { Container } from '~/components/layout/common/container'
import { ControlItem } from '~/components/layout/common/control-item'
import { RootModule, useStore } from '~/store/root'

@Component
export class HeaderDesktopMain extends VueComponent {
  store = useStore<RootModule>(this.$store)

  get cartItemsCounter(): number {
    return this.store.cart.productsCount
  }

  handleProfileClick() {
    if (!this.store.authorization.authenticated) {
      return this.store.authorization.setPopupShow(true)
    }

    this.$router.push('/profile')
  }

  render(): VNode {
    return (
      <div class={styles.headerMain}>
        <Container class={styles.container}>
          <nuxt-link to="/">
            <img class={styles.logo} src={require('~/assets/icons/logo.svg')} />
          </nuxt-link>
          <HeaderDesktopMainSearch class={styles.search} />
          <ControlItem.Button
            class={styles.firstControl}
            icon={require('~/assets/icons/profile.svg')}
            label="Профиль"
            whenClick={this.handleProfileClick}
          />
          {/* <ControlItem.Link */}
          {/*  icon={require('~/assets/icons/heart.svg')} */}
          {/*  label="Избранное" */}
          {/*  link="/" */}
          {/* /> */}
          <ControlItem.Link
            icon={require('~/assets/icons/cart.svg')}
            label="Корзина"
            link="/cart"
            counter={this.cartItemsCounter}
          />
        </Container>
      </div>
    )
  }
}
