// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_2a6ln{height:75px;width:100%}.wrapper_2a6ln .input_jVR7p{display:block;border:1px solid #dee0e7;box-sizing:border-box;width:100%;height:56px;font-weight:500;font-size:32px;line-height:32px;text-align:center;letter-spacing:1.8em}.wrapper_2a6ln .input_jVR7p::-moz-placeholder{font-size:14px;line-height:56px;letter-spacing:normal;font-weight:400}.wrapper_2a6ln .input_jVR7p:-ms-input-placeholder{font-size:14px;line-height:56px;letter-spacing:normal;font-weight:400}.wrapper_2a6ln .input_jVR7p::placeholder{font-size:14px;line-height:56px;letter-spacing:normal;font-weight:400}@media (max-width:940px){.wrapper_2a6ln .input_jVR7p{font-size:24px}}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_2a6ln",
	"input": "input_jVR7p"
};
module.exports = exports;
