import { Context, Plugin } from '@nuxt/types'
import { useStore } from '~/store/root'
import type {
  RootModule,
  AuthorizationModule,
  CartModule,
  CategoryTreeModule,
} from '~/store/root'
import { ClientStorageManager } from '~/app/client-storage-manager/client-storage-manager'
import { ClientStorageKey } from '~/app/client-storage-manager/client-storage-manager.types'

// TODO: connect with path config
// blocked: https://bosco.myjetbrains.com/youtrack/issue/BP-777
const CLIENT_ONLY_PATHS = [
  '/checkout',
  '/cart',
  '/profile',
  '/profile/favourites',
  '/profile/orders',
]

const initAuthorization = (authorizationModule: AuthorizationModule) => {
  authorizationModule.setAuthenticatedFromLocalValue()
}

const initCategoryTree = (categoryTreeModule: CategoryTreeModule) => {
  categoryTreeModule.initiate()
}

const initCart = (cartModule: CartModule) => {
  const cartToken = ClientStorageManager.getValue<string>(
    ClientStorageKey.CartToken
  )

  if (cartToken) {
    cartModule.setCartToken(cartToken)
  }

  cartModule.getCartItems()
}

/*
 *   Инициализация данных на стороне клиента.
 *   Используется для клиентских путей и данных, завязанных на значения из
 *   localStorage
 * */
const clientSideStoreInit: Plugin = ({ store, route }: Context) => {
  const { authorization, categoryTree, cart } = useStore<RootModule>(store)
  // инит авторизации доступе для всех путей
  initAuthorization(authorization)
  // инит дерева категорий только для client-side путей
  if (
    typeof route.name === 'string' &&
    CLIENT_ONLY_PATHS.includes(route.path)
  ) {
    initCategoryTree(categoryTree)
  }
  /*
   * Инит корзины для всех путей, кроме /cart и /checkout. Для них инит происходит
   * в миддлваре. Костыль из-за того, что миддлвара отрабатывает до того, как придет
   * ответ на запрос из плагина
   * */
  if (
    typeof route.name === 'string' &&
    !['/cart', '/checkout'].includes(route.path)
  ) {
    initCart(cart)
  }
}

export default clientSideStoreInit
