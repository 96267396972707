import { Plugin } from '@nuxt/types'
import { ITimestamp } from '~/utils/timestamp/timestamp.interface'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'

declare module 'vue/types/vue' {
  interface Vue {
    $timestamp: ITimestamp
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $timestamp: ITimestamp
  }

  interface Context {
    $timestamp: ITimestamp
  }
}

const timestampPlugin: Plugin = (_, inject) => {
  inject('timestamp', container.get<ITimestamp>(DiTypes.TIMESTAMP))
}

export default timestampPlugin
