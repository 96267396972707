import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import { ILogger } from '~/app/logger'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'

declare module 'vue/types/vue' {
  // this.$logger inside Vue components
  interface Vue {
    $logger: ILogger
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$logger inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $logger: ILogger
  }
  // nuxtContext.$logger
  interface Context {
    $logger: ILogger
  }
}

declare module 'vuex/types/index' {
  // this.$logger inside Vuex stores
  interface Store<S> {
    $logger: ILogger
  }
}

const logger = container.get<ILogger>(DiTypes.LOGGER)

Vue.config.errorHandler = (err) => {
  logger.error(err)
}

const loggerPlugin: Plugin = (_context, inject) => {
  inject('logger', logger)
}

export default loggerPlugin
