import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _061fd1dc = () => interopDefault(import('../pages/cart/index.tsx' /* webpackChunkName: "pages/cart/index" */))
const _78830fb8 = () => interopDefault(import('../pages/checkout/index.tsx' /* webpackChunkName: "pages/checkout/index" */))
const _151312f6 = () => interopDefault(import('../pages/contacts/index.tsx' /* webpackChunkName: "pages/contacts/index" */))
const _73f64634 = () => interopDefault(import('../pages/delivery/index.tsx' /* webpackChunkName: "pages/delivery/index" */))
const _e8e1b92a = () => interopDefault(import('../pages/license/index.tsx' /* webpackChunkName: "pages/license/index" */))
const _22fb3d0e = () => interopDefault(import('../pages/politika/index.tsx' /* webpackChunkName: "pages/politika/index" */))
const _13d8f25a = () => interopDefault(import('../pages/profile/index.tsx' /* webpackChunkName: "pages/profile/index" */))
const _6b3132cc = () => interopDefault(import('../pages/search/index.tsx' /* webpackChunkName: "pages/search/index" */))
const _f1cc0cf8 = () => interopDefault(import('../pages/soglashenie/index.tsx' /* webpackChunkName: "pages/soglashenie/index" */))
const _49e7eb1d = () => interopDefault(import('../pages/styleguide/index.tsx' /* webpackChunkName: "pages/styleguide/index" */))
const _06cadde0 = () => interopDefault(import('../pages/cart/empty/index.tsx' /* webpackChunkName: "pages/cart/empty/index" */))
const _29b0c570 = () => interopDefault(import('../pages/checkout/spasibo/index.tsx' /* webpackChunkName: "pages/checkout/spasibo/index" */))
const _3d7cf522 = () => interopDefault(import('../pages/order/payment/index.tsx' /* webpackChunkName: "pages/order/payment/index" */))
const _9c176702 = () => interopDefault(import('../pages/profile/favourites.tsx' /* webpackChunkName: "pages/profile/favourites" */))
const _5ed1ac0c = () => interopDefault(import('../pages/profile/orders.tsx' /* webpackChunkName: "pages/profile/orders" */))
const _453e8eb2 = () => interopDefault(import('../pages/details/_slug/index.tsx' /* webpackChunkName: "pages/details/_slug/index" */))
const _5fbd988e = () => interopDefault(import('../pages/index.tsx' /* webpackChunkName: "pages/index" */))
const _3e2a33ed = () => interopDefault(import('../routes/catalog' /* webpackChunkName: "pages/catalog/category" */))
const _4e9d296e = () => interopDefault(import('../routes/brand/brand-page' /* webpackChunkName: "pages/brand/slug" */))
const _9d77c76a = () => interopDefault(import('../routes/brand/brands-list' /* webpackChunkName: "pages/brands" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _061fd1dc,
    name: "cart"
  }, {
    path: "/checkout",
    component: _78830fb8,
    name: "checkout"
  }, {
    path: "/contacts",
    component: _151312f6,
    name: "contacts"
  }, {
    path: "/delivery",
    component: _73f64634,
    name: "delivery"
  }, {
    path: "/license",
    component: _e8e1b92a,
    name: "license"
  }, {
    path: "/politika",
    component: _22fb3d0e,
    name: "politika"
  }, {
    path: "/profile",
    component: _13d8f25a,
    name: "profile"
  }, {
    path: "/search",
    component: _6b3132cc,
    name: "search"
  }, {
    path: "/soglashenie",
    component: _f1cc0cf8,
    name: "soglashenie"
  }, {
    path: "/styleguide",
    component: _49e7eb1d,
    name: "styleguide"
  }, {
    path: "/cart/empty",
    component: _06cadde0,
    name: "cart-empty"
  }, {
    path: "/checkout/spasibo",
    component: _29b0c570,
    name: "checkout-spasibo"
  }, {
    path: "/order/payment",
    component: _3d7cf522,
    name: "order-payment"
  }, {
    path: "/profile/favourites",
    component: _9c176702,
    name: "profile-favourites"
  }, {
    path: "/profile/orders",
    component: _5ed1ac0c,
    name: "profile-orders"
  }, {
    path: "/details/:slug",
    component: _453e8eb2,
    name: "details-slug"
  }, {
    path: "/",
    component: _5fbd988e,
    name: "index"
  }, {
    path: "/catalog/:category",
    component: _3e2a33ed,
    name: "catalog-category"
  }, {
    path: "/catalog/:category/:subcategory",
    component: _3e2a33ed,
    name: "catalog-subcategory"
  }, {
    path: "/catalog/:category/:subcategory/:subSubcategory",
    component: _3e2a33ed,
    name: "catalog-subSubcategory"
  }, {
    path: "/brand/:slug",
    component: _4e9d296e,
    name: "brand-slug"
  }, {
    path: "/brands",
    component: _9d77c76a,
    name: "brands"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
