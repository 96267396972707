// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headerDesktop_28RNS{display:none}@media (min-width:960px){.headerDesktop_28RNS{display:block}}", ""]);
// Exports
exports.locals = {
	"headerDesktop": "headerDesktop_28RNS"
};
module.exports = exports;
