import { Factory } from '~/abstracts/factory'
import { GQLSeo } from '~/types/gql'
import { Seo } from '~/features/seo/seo.interface'

export class SeoFactory extends Factory<GQLSeo, Seo> {
  public create({ title, description, keywords }: GQLSeo): Seo {
    return {
      title,
      keywords,
      description,
    }
  }
}
