// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_QxUbX{align-self:flex-start;margin-top:50px;width:100%}@media (min-width:961px){.wrapper_QxUbX{margin:0}}.bottomAction_RIM8O,.subheader_3o_4B{text-align:center}.bottomAction_RIM8O{color:var(--color-tiffany-cactus);cursor:pointer}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_QxUbX",
	"bottomAction": "bottomAction_RIM8O",
	"subheader": "subheader_3o_4B"
};
module.exports = exports;
