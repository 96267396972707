import { inject, injectable } from 'inversify'

import { Apollo } from '~/api/GraphqlClient'
import DiTypes from '~/config/DiTypes'
import { Product } from '~/entities/product'
import { ProductData } from '~/entities/product-data'
import {
  GQLLoginBySmsInput,
  GQLOrderInput,
  GQLOrderItemInput,
  GQLOrderPayInput,
  GQLPaymentForm,
  GQLPaymentStatus,
  GQLPaymentStatusCode,
  GQLProductFilterInput,
  GQLProductSearchInput,
  GQLProductsFilter,
  GQLUpdatePaymentStatusInput,
  GQLUserInput,
} from '~/types/gql'
import { CategoryTreeType } from '~/entities/category-tree'
import { ProductSearch } from '~/components/layout/header/types'
import { User } from '~/features/user/user.interface'
import { UserOrder } from '~/features/user/user-order/user-order.interface'
import { WithValidationErrors } from '~/types/with-validation-errors'
import { OrderPayment } from '~/features/order/order-payment/order-payment.interface'
import { Banner } from '~/features/banner/banner.interface'
import { Seo } from '~/features/seo'
import { PaginatedResponse } from '~/types/response'
import { ProductFilterOptions } from '~/features/product/product-filter/product-filter-options.interface'

@injectable()
export abstract class Repository {
  public apollo: Apollo
  constructor(@inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo) {
    this.apollo = apollo
  }
}

export interface ProductRepositoryInterface {
  getProducts: () => Promise<Product[]>
  getProductData: (slug: string) => Promise<ProductData | undefined>
  getCategoryProducts: (
    slug: string[],
    page?: number,
    filter?: GQLProductFilterInput
  ) => Promise<{
    products: Product[]
    totalPages: number
    seo: Seo | null
  }>
  getCategoryProductsByID: (
    categoryID: number
  ) => Promise<{
    products: Product[]
    totalPages: number
  }>
  getProductSearchHints: (name: string) => Promise<ProductSearch[]>
  findProducts: (filters: {
    query: string
    page: number
  }) => Promise<{
    products: Product[]
    totalPages: number
    count: number
  }>
  getCategoryProductsCount: (
    slug: string[],
    filter: GQLProductFilterInput
  ) => Promise<number>
  getProductAnalogs: (slug: string) => Promise<Product[]>
  getProductPaginated: (
    page: number,
    input?: GQLProductSearchInput
  ) => Promise<PaginatedResponse<Product>>
  getProductFilterOptions: (
    categoryIDs: string[]
  ) => Promise<ProductFilterOptions>
  getProductsCount: (input: GQLProductSearchInput) => Promise<number>
}

export interface OrderRepositoryInterface {
  addOrder: (input: GQLOrderInput) => Promise<string>
  orderPay: (input: GQLOrderPayInput) => Promise<GQLPaymentForm>
  orderUpdatePaymentStatus: (
    input: GQLUpdatePaymentStatusInput
  ) => Promise<GQLPaymentStatus>
  getOrderPayments: (input: GQLOrderItemInput[]) => Promise<OrderPayment[]>
}

export interface CategoryRepositoryInterface {
  getCategoriesTree: () => Promise<CategoryTreeType[]>
  getCategorySeo: (categoryID: string) => Promise<Seo | null>
  getCategoryInfo: (
    categoryID: string
  ) => Promise<{ seo: Seo | null; description?: string }>
}

export interface UserRepositoryInterface {
  getAuthorizationCode: (phone: string) => Promise<boolean>
  loginBySms: (input: GQLLoginBySmsInput) => Promise<string>
  getUserDetails: () => Promise<User>
  updateUserDetails: (
    input: GQLUserInput
  ) => Promise<WithValidationErrors<User>>
  getUserOrders: () => Promise<UserOrder[]>
  favouriteAdd: (productID: string) => Promise<boolean>
  favouriteRemove: (productID: string) => Promise<boolean>
  getUserFavourites: () => Promise<Product[]>
}

export interface CategoryFiltersRepositoryInterface {
  getIngredients: () => Promise<string[]>
  getManufacturers: () => Promise<string[]>
  getCountries: () => Promise<string[]>
  getBrands: () => Promise<string[]>
  getFilteredProductsCount: (
    categoryId: number,
    filter: GQLProductFilterInput
  ) => Promise<number>
}

export interface IBannerRepository {
  getBanners: () => Promise<Banner[]>
}
