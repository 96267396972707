import styles from './footer-desktop.css?module'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'

import { Link } from '~/entities/link'
import { Container } from '~/components/layout/common/container'

const navItems: Link[] = [
  // {
  //   name: 'Программа лояльности',
  //   slug: '/',
  // },
  // {
  //   name: 'Наши клиенты',
  //   slug: '/',
  // },
  {
    name: 'Доставка, оплата и возврат',
    slug: '/delivery',
  },
  {
    name: 'Пользовательское соглашение',
    slug: '/soglashenie',
  },
  // {
  //   name: 'Акции и скидки',
  //   slug: '/',
  // },
  // {
  //   name: 'Сотрудники',
  //   slug: '/',
  // },
  {
    name: 'Политика конфиденциальности',
    slug: '/politika',
  },
  {
    name: 'Лицензии и реквизиты',
    slug: '/license',
  },
  {
    name: 'Контакты',
    slug: '/contacts',
  },
]

@Component
export class FooterDesktop extends VueComponent {
  render(): VNode {
    return (
      <Container class={styles.footer}>
        <div class={styles.company}>
          <img class={styles.logo} src={require('~/assets/icons/logo.svg')} />
          <span class={styles.copyright}>©2020, ООО «Аптека Боско» </span>
        </div>
        <div class={styles.navigation}>
          {navItems.map((item, i) => (
            <nuxtLink key={i} to={item.slug}>
              {item.name}
            </nuxtLink>
          ))}
        </div>
        {/* <div class={styles.social}> */}
        {/*  <h4>Аптека Bosco в социальных сетях</h4> */}
        {/*  <div class={styles.socialIcons}> */}
        {/*    <a */}
        {/*      href="https://www.facebook.com/BoscoApteka" */}
        {/*      target="_blank" */}
        {/*      rel="nofollow" */}
        {/*    > */}
        {/*      <img src={require('~/assets/icons/fb.svg')} /> */}
        {/*    </a> */}
        {/*    <a */}
        {/*      href="https://www.instagram.com/apteka_bosco/" */}
        {/*      target="_blank" */}
        {/*      rel="nofollow" */}
        {/*    > */}
        {/*      <img src={require('~/assets/icons/insta.svg')} /> */}
        {/*    </a> */}
        {/*  </div> */}
        {/* </div> */}
      </Container>
    )
  }
}
