import styles from './error-page.css?module'
import { Component, Prop, VNode, VueComponent } from '~/types/vue-ts-component'
import { Container } from '~/components/layout/common/container'

interface Props {
  header?: string
  image: string
}

@Component
export class ErrorPage extends VueComponent<Props> {
  @Prop() readonly header!: Props['header']
  @Prop() readonly image!: Props['image']

  render(): VNode {
    return (
      <Container paddingY class={styles.layout}>
        <div class={styles.content}>
          {this.header && <h1 class={styles.header}>{this.header}</h1>}
          {this.$slots.default}
        </div>
        <img class={[styles.illustration]} src={this.image} alt={this.header} />
      </Container>
    )
  }
}
