import { Link } from '~/entities/link'

export const MENU_LINKS: Link[] = [
  // { name: 'Программа лояльности', slug: '/' },
  // { name: 'Акции и скидки', slug: '/' },
  { name: 'Доставка, оплата и возврат', slug: '/delivery' },
  // { name: 'Наши клиенты', slug: '/' },
  { name: 'Пользовательское соглашение', slug: '/soglashenie' },
  { name: 'Политика конфиденциальности', slug: '/politika' },
  { name: 'Лицензии и реквизиты', slug: '/license' },
  { name: 'Контакты', slug: '/contacts' },
]
