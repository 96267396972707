import { Plugin, Context } from '@nuxt/types'
import isMobile from 'ismobilejs'

declare module 'vue/types/vue' {
  interface Vue {
    $isMobile: boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isMobile: boolean
  }

  interface Context {
    $isMobile: boolean
  }
}

const isMobilePlugin: Plugin = (ctx: Context, inject) => {
  const userAgent = window?.navigator ?? ctx.req.headers['user-agent']
  inject('isMobile', isMobile(userAgent).phone)
}

export default isMobilePlugin
