import { injectable } from 'inversify'
import {
  EcommerceProduct,
  EcommerceProductItem,
  DataLayer,
  IEcommerce,
} from '~/app/ecommerce/ecommerce.types'
import { getEcommerceProducts } from '~/app/ecommerce/ecommerce.utils'

@injectable()
export class Ecommerce implements IEcommerce {
  private dataLayer: DataLayer

  constructor() {
    if (typeof window === 'undefined' || !window.dataLayer) {
      this.dataLayer = {
        push: (input: any) => {
          // eslint-disable-next-line no-console
          console.log('local ecommerce', { input })
        },
      }
    } else {
      this.dataLayer = window.dataLayer
    }
  }

  public add(items: EcommerceProductItem[]): void {
    this.dataLayer.push({
      ecommerce: {
        add: {
          products: getEcommerceProducts(items),
        },
      },
    })
  }

  public detail(product: EcommerceProduct): void {
    this.dataLayer.push({
      ecommerce: {
        detail: {
          products: [product],
        },
      },
    })
  }

  public purchase({
    orderNumber,
    items,
  }: {
    orderNumber: string
    items: EcommerceProductItem[]
  }): void {
    this.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: orderNumber,
          },
          products: getEcommerceProducts(items),
        },
      },
    })
  }

  public remove(items: EcommerceProductItem[]): void {
    this.dataLayer.push({
      ecommerce: {
        remove: {
          products: getEcommerceProducts(items),
        },
      },
    })
  }
}
