// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_1v0NL{position:absolute;top:0;left:0;width:100%;background-color:var(--color-snow-white);z-index:99;font-size:14px}.list_16TGa{border-bottom:1px solid #eee;border-top:1px solid #eee}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_1v0NL",
	"list": "list_16TGa"
};
module.exports = exports;
