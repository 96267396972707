import { OrderItem } from '~/entities/order-item'
import { LOCAL_STORAGE_KEYS } from '~/config/local-storage-keys'

export const saveToLocalstorage = (cartItems: OrderItem[]): void => {
  if (typeof window === 'undefined') return
  localStorage.setItem(LOCAL_STORAGE_KEYS.CART_ITEMS, JSON.stringify(cartItems))
}

export const getFromLocalStorage = (): OrderItem[] => {
  if (typeof window === 'undefined') return []
  const rawItems = localStorage.getItem(LOCAL_STORAGE_KEYS.CART_ITEMS)
  return rawItems ? JSON.parse(rawItems) : []
}

export const removeSaved = (): void => {
  if (typeof window === 'undefined') return
  localStorage.removeItem(LOCAL_STORAGE_KEYS.CART_ITEMS)
}
