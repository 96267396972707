import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { ScrollTopBtn } from '~/components/UI/scroll-top-btn'

const SCROLL_TOP_VALUE_DESKTOP = 1680
const SCROLL_TOP_VALUE_MOBILE = 1000

@Component
export class WithScrollTopBtn extends VueComponent {
  showScrollTopBtn = false

  get scrollTopValue(): number {
    return this.$isMobile ? SCROLL_TOP_VALUE_MOBILE : SCROLL_TOP_VALUE_DESKTOP
  }

  whenScrolled() {
    if (window.scrollY > this.scrollTopValue) {
      !this.showScrollTopBtn && (this.showScrollTopBtn = true)
      return
    }
    this.showScrollTopBtn && (this.showScrollTopBtn = false)
  }

  handleScrollTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }

  mounted() {
    window.addEventListener('scroll', this.whenScrolled)
  }

  destroyed() {
    window.removeEventListener('scroll', this.whenScrolled)
  }

  render(): VNode {
    return (
      <div>
        {this.$slots.default}
        <ScrollTopBtn
          vShow={this.showScrollTopBtn}
          whenClicked={this.handleScrollTop}
        />
      </div>
    )
  }
}
