import { NuxtError } from '@nuxt/types'
import { Component, Prop, VNode, VueComponent } from '~/types/vue-ts-component'

import '~/assets/styles/reset.css'

import { AppButton } from '~/components/UI/ui-kit'
import { ErrorPage } from '~/components/layout/common/error-page'

@Component
export default class ErrorLayout extends VueComponent {
  @Prop() readonly error!: NuxtError

  get is404(): boolean {
    return this.error?.statusCode === 404
  }

  render(): VNode {
    return (
      <ErrorPage
        header={this.is404 ? '404' : '500'}
        image={require(`~/assets/icons/${this.is404 ? '404' : '500'}.svg`)}
      >
        <div style={{ display: 'contents' }}>
          <p>
            {this.is404
              ? `
                  Ой, здесь ничего нет…
                  Похоже, препараты в этой категории закончились. А быть может никогда
                  и не существовали.
                `
              : `
                  Ой, что-то пошло не так…
                  На сервере возникла маленькая
                  техническая неувязка. Обновите страницу.
                  Не помогло? Зайдите на сайт позднее.
                `}
          </p>
          <AppButton whenClick={() => this.$router.push('/')}>
            На главную
          </AppButton>
        </div>
      </ErrorPage>
    )
  }
}
