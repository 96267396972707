// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_1BpR-{width:100%;display:grid;grid-template-columns:1fr;align-content:space-between;grid-row-gap:34px;row-gap:34px}.heading_2K-ZJ{font-weight:500;font-size:22px;line-height:28px;text-align:center}", ""]);
// Exports
exports.locals = {
	"container": "container_1BpR-",
	"heading": "heading_2K-ZJ"
};
module.exports = exports;
