// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2PnYQ{width:100%;max-width:100%;margin:0 auto;padding:0 16px}.container_2PnYQ.paddingY_2sVBs{padding-bottom:16px;padding-top:16px}@media (min-width:414px){.container_2PnYQ{padding:0 24px}}@media (min-width:768px){.container_2PnYQ{padding:0 32px}}@media (min-width:1440px){.container_2PnYQ{padding:0 64px;max-width:1440px}}", ""]);
// Exports
exports.locals = {
	"container": "container_2PnYQ",
	"paddingY": "paddingY_2sVBs"
};
module.exports = exports;
