import { VueComponent } from '~/types/vue-ts-component'
import { removeAuthToken } from '~/utils/auth-token'
import { RootModule, useStore } from '~/store/root'

async function unAuthenticate(this: VueComponent) {
  const { authorization } = useStore<RootModule>(this.$store)
  removeAuthToken()
  authorization.setAuthenticated(false)
  await this.$router.push('/')
}

export { unAuthenticate }
