import { Getter, Mutation, State, Action } from 'vuex-simple'
import DiTypes from '~/config/DiTypes'
import { ICartRepository } from '~/repositories/cart/cart.repository.interface'
import { CartItem } from '~/features/cart/cart-item.interface'
import { container } from '~/config/invercify'
import { OrderItem } from '~/entities/order-item'
import { deepCopy } from '~/utils/helpers'
import { cartItemInputMapper } from '~/features/cart/cart-item-input.mapper'
import { ClientStorageManager } from '~/app/client-storage-manager/client-storage-manager'
import { ClientStorageKey } from '~/app/client-storage-manager/client-storage-manager.types'
import { RootModule } from '~/store/root'

export class CartUpdatedModule {
  private cartRepository!: ICartRepository

  constructor(private root: RootModule) {
    this.cartRepository = container.get(DiTypes.CART_REPOSITORY)
  }

  @State()
  cartItems: CartItem[] = []

  @State()
  cartToken?: string

  @Getter()
  public get productsCount(): number {
    return this.cartItems.reduce((count, item) => {
      return count + item.qty
    }, 0)
  }

  @Getter()
  public get amount(): number {
    return this.cartItems.reduce((price, item) => {
      return price + item.product.price * item.qty
    }, 0)
  }

  @Mutation()
  setCartItems(cartItems: OrderItem[] = []) {
    this.cartItems = cartItems
  }

  @Mutation()
  setCartToken(token?: string) {
    this.cartToken = token
  }

  @Action()
  public async addToCart(cartItem: CartItem) {
    const [currentCartItems, foundIndex] = this.cartActionSetup(
      this.cartItems,
      cartItem.product.ID
    )

    if (foundIndex >= 0) {
      this.root.$logger.log(
        `
            Can't add product ${
              cartItem.product.ID
            } to cart. Item already exists.\n
            ${JSON.stringify({ cart: this.cartItems, adding: cartItem })}
          `
      )
      return
    }

    currentCartItems.push(cartItem)
    await this.updateCartItemsAndReload(currentCartItems)
  }

  @Action()
  public async increaseQty(productID: string) {
    const [currentCartItems, foundIndex] = this.cartActionSetup(
      this.cartItems,
      productID
    )

    if (foundIndex < 0) {
      this.root.$logger.log(
        `
            Can't increase product qty ${productID}. Item does not exist.\n
            ${JSON.stringify({ cart: this.cartItems })}
          `
      )
      return
    }

    currentCartItems[foundIndex].qty += 1
    await this.updateCartItemsAndReload(currentCartItems)
  }

  @Action()
  public async decreaseQty(productID: string) {
    const [currentCartItems, foundIndex] = this.cartActionSetup(
      this.cartItems,
      productID
    )

    if (foundIndex < 0) {
      this.root.$logger.log(
        `
            Can't decrease product qty ${productID}. Item does not exist.\n
            ${JSON.stringify({ cart: this.cartItems })}
          `
      )
      return
    }

    currentCartItems[foundIndex].qty -= 1
    await this.updateCartItemsAndReload(currentCartItems)
  }

  @Action()
  public async removeFromCart(productID: string) {
    const [currentCartItems, foundIndex] = this.cartActionSetup(
      this.cartItems,
      productID
    )

    if (foundIndex < 0) {
      this.root.$logger.log(
        `
            Can't remove product from cart ${productID}. Item does not exist.\n
            ${JSON.stringify({ cart: this.cartItems })}
          `
      )
      return
    }

    currentCartItems.splice(foundIndex, 1)
    await this.updateCartItemsAndReload(currentCartItems)
  }

  @Action()
  async getCartItems(): Promise<void> {
    try {
      const { cartItems, token } = await this.cartRepository.getCart(
        this.cartToken
      )
      this.setCartItems(cartItems)

      if (!this.cartToken || this.cartToken !== token) {
        ClientStorageManager.setValue(ClientStorageKey.CartToken, token)
      }

      this.setCartToken(token)
    } catch (error) {
      this.root.$logger.error(error)
    }
  }

  @Action()
  public cleanCart() {
    this.updateCartItemsAndReload([])
  }

  private async updateCartItems(cartItems: CartItem[]): Promise<boolean> {
    const inputItems = cartItems.map(cartItemInputMapper)
    try {
      return await this.cartRepository.updateCart({
        items: inputItems,
        token: this.cartToken as string,
      })
    } catch (error) {
      this.root.$logger.error(error)
      return false
    }
  }

  private async updateCartItemsAndReload(cartItems: CartItem[]) {
    const success = await this.updateCartItems(cartItems)
    if (success) {
      this.getCartItems()
    }
  }

  private findCartItemIndex = (
    cartItems: OrderItem[],
    productID: string
  ): number => {
    return cartItems.findIndex(({ product: { ID } }) => ID === productID)
  }

  private cartActionSetup(
    cartItems: CartItem[],
    productID: string
  ): [CartItem[], number] {
    const currentCartItems = deepCopy(cartItems)
    const foundIndex = this.findCartItemIndex(currentCartItems, productID)

    return [currentCartItems, foundIndex]
  }

  public isInCart(productID: string): boolean {
    return (
      this.cartItems.findIndex(({ product }) => product.ID === productID) >= 0
    )
  }
}
