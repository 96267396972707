import { cndLink } from '~/utils/cdn'
import { AppImageInput, GQLImageInput } from '~/app/image/app-image.interface'

import { GQLGalleryImage } from '~/types/gql'

export const isGQLImageInput = (
  input: AppImageInput
): input is GQLImageInput => {
  return (input as GQLImageInput).name !== undefined
}

type CompareResult = 0 | 1 | -1

export const getImageSrc = (url?: string): string => {
  return url ? cndLink(url) : require('~/assets/images/no-img-good.png')
}

export const compareImages = (
  img1: GQLGalleryImage,
  img2: GQLGalleryImage
): CompareResult => {
  if (img1.sortOrder === img2.sortOrder) return 0

  return img1.sortOrder > img2.sortOrder ? 1 : -1
}

export const findMainImage = (images: GQLGalleryImage[]): GQLGalleryImage => {
  const [mainImage] = images.sort(compareImages)
  return mainImage
}
