import { injectable, inject } from 'inversify'
import { ProductRepositoryInterface, Repository } from '~/abstracts/repository'
import { ProductFactoryInterface } from '~/features/product/product-factory'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import {
  GQLProductFilterInput,
  GQLProductSearchInput,
  GQLProductsFilter,
  GQLQuery,
} from '~/types/gql'
import { Product } from '~/entities/product'
import { ProductData } from '~/entities/product-data'
import { ISeoFactory, ProductDataFactoryInterface } from '~/abstracts/factory'
import { ProductSearch } from '~/components/layout/header/types'
import { Seo } from '~/features/seo'
import { PaginatedResponse } from '~/types/response'
import { sortGqlProducts } from '~/features/product/product.utils'
import { ProductFilterOptions } from '~/features/product/product-filter/product-filter-options.interface'

@injectable()
export class ProductRepository
  extends Repository
  implements ProductRepositoryInterface {
  private productFactory: ProductFactoryInterface
  private productDataFactory: ProductDataFactoryInterface
  private seoFactory: ISeoFactory

  constructor(
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo,
    @inject(DiTypes.PRODUCT_FACTORY) productFactory: ProductFactoryInterface,
    @inject(DiTypes.PRODUCT_DATA_FACTORY)
    productDataFactory: ProductDataFactoryInterface,
    @inject(DiTypes.SEO_FACTORY) seoFactory: ISeoFactory
  ) {
    super(apollo)
    this.productFactory = productFactory
    this.productDataFactory = productDataFactory
    this.seoFactory = seoFactory
  }

  public async getProducts(): Promise<Product[]> {
    const { data } = await this.apollo.client.query<{
      Products: GQLQuery['Products']
    }>({
      query: await import('~/graphql/products.graphql'),
      variables: {
        page: 1,
      },
    })

    const sortedProducts = sortGqlProducts(data.Products.data)
    return this.productFactory.fromArray(sortedProducts)
  }

  public async getProductPaginated(
    page: number,
    input?: GQLProductSearchInput
  ): Promise<PaginatedResponse<Product>> {
    const { data } = await this.apollo.client.query<
      { ProductSearch: GQLQuery['ProductSearch'] },
      { first: number; page: number; input: GQLProductSearchInput }
    >({
      query: await import('~/graphql/product/product-search.graphql'),
      variables: {
        first: 24,
        page,
        input: input || {},
      },
    })

    const sortedProducts = sortGqlProducts(data.ProductSearch.products.data)

    return {
      totalPages: data.ProductSearch.products.paginatorInfo?.lastPage ?? 0,
      items: this.productFactory.fromArray(sortedProducts),
      count: data.ProductSearch.products.paginatorInfo.total,
    }
  }

  public async getProductData(slug: string): Promise<ProductData | undefined> {
    const { data } = await this.apollo.client.query<{
      Product: GQLQuery['Product']
    }>({
      query: await import('~/graphql/product-data.graphql'),
      variables: { slug },
    })

    if (!data?.Product) return undefined

    return this.productDataFactory.create(data.Product)
  }

  public getCategoryProducts = async (
    slug: string[],
    page?: number,
    filter?: GQLProductFilterInput
  ): Promise<{
    products: Product[]
    totalPages: number
    seo: Seo | null
  }> => {
    const { data } = await this.apollo.client.query<{
      CategoryByPath: GQLQuery['CategoryByPath']
    }>({
      query: await import('~/graphql/category-products.graphql'),
      variables: {
        slug,
        page,
        filter,
      },
    })

    const products = data?.CategoryByPath?.allProducts?.data || []
    const paginatorInfo = data?.CategoryByPath?.allProducts?.paginatorInfo

    return {
      products: this.productFactory.fromArray(products as any),
      totalPages: paginatorInfo?.lastPage ?? 0,
      seo: data.CategoryByPath?.seo
        ? this.seoFactory.create(data.CategoryByPath.seo)
        : null,
    }
  }

  public async getCategoryProductsCount(
    slug: string[],
    filter: GQLProductFilterInput
  ): Promise<number> {
    const { data } = await this.apollo.client.query<{
      CategoryByPath: GQLQuery['CategoryByPath']
    }>({
      query: await import('~/graphql/product/category-products-count.graphql'),
      variables: {
        slug,
        filter,
      },
    })

    return data.CategoryByPath?.allProducts.paginatorInfo.total || 0
  }

  public getProductSearchHints = async (
    query: string
  ): Promise<ProductSearch[]> => {
    const { data } = await this.apollo.client.query<{
      ProductSuggestion: GQLQuery['ProductSuggestion']
    }>({
      query: await import('~/graphql/product-search-hints.graphql'),
      variables: { text: query },
    })

    if (data?.ProductSuggestion === undefined) {
      return []
    }

    return (
      data?.ProductSuggestion.map((product) => {
        return {
          name: product.name,
          slug: product.slug,
        }
      }) || []
    )
  }

  public findProducts = async ({
    query,
    page,
  }: {
    query: string
    page: number
  }): Promise<{
    products: Product[]
    totalPages: number
    count: number
  }> => {
    const { data } = await this.apollo.client.query<{
      Products: GQLQuery['Products']
    }>({
      query: await import('~/graphql/products.graphql'),
      variables: {
        query,
        page,
      },
    })

    const products = data?.Products?.data || []
    const paginatorInfo = data?.Products?.paginatorInfo

    return {
      products: this.productFactory.fromArray(products),
      totalPages: paginatorInfo?.lastPage ?? 0,
      count: data?.Products?.paginatorInfo.total || 0,
    }
  }

  public async getCategoryProductsByID(
    categoryID: number
  ): Promise<{ products: Product[]; totalPages: number }> {
    const { data } = await this.apollo.client.query<{
      Category: GQLQuery['Category']
    }>({
      query: await import('~/graphql/category-products-by-id.graphql'),
      variables: {
        ID: categoryID,
        page: 1,
      },
    })

    const products = data?.Category?.products?.data || []
    const paginatorInfo = data?.Category?.products?.paginatorInfo

    return {
      products: this.productFactory.fromArray(products as any),
      totalPages: paginatorInfo?.lastPage ?? 0,
    }
  }

  public async getProductAnalogs(slug: string): Promise<Product[]> {
    const { data } = await this.apollo.client.query<
      { ProductAnalogs: GQLQuery['ProductAnalogs'] },
      { slug: string }
    >({
      query: await import('~/graphql/product/product-analogs.graphql'),
      variables: {
        slug,
      },
    })

    return this.productFactory.fromArray(data.ProductAnalogs || [])
  }

  public async getProductFilterOptions(
    categoryIDs: string[]
  ): Promise<ProductFilterOptions> {
    const { data } = await this.apollo.client.query<{
      ProductSearch: GQLQuery['ProductSearch']
    }>({
      query: await import(
        '~/graphql/product/product-search-filter-options.graphql'
      ),
      variables: {
        categoryIDs,
      },
    })

    const sortFilterOptions = (filterOptions: string[]): string[] => {
      return filterOptions.sort((a, b) => (a > b ? 1 : -1))
    }

    return {
      [GQLProductsFilter.Brand]: sortFilterOptions(
        (data.ProductSearch.filterOptions.brand as string[]) || []
      ),
      [GQLProductsFilter.Manufacturer]: sortFilterOptions(
        (data.ProductSearch.filterOptions.manufacturer as string[]) || []
      ),
      [GQLProductsFilter.Ingredient]: sortFilterOptions(
        (data.ProductSearch.filterOptions.ingredient as string[]) || []
      ),
      [GQLProductsFilter.Country]: sortFilterOptions(
        (data.ProductSearch.filterOptions.country as string[]) || []
      ),
    }
  }

  public async getProductsCount(input: GQLProductSearchInput): Promise<number> {
    const { data } = await this.apollo.client.query<
      { ProductSearch: GQLQuery['ProductSearch'] },
      { input: GQLProductSearchInput }
    >({
      query: await import('~/graphql/product/product-search-count.graphql'),
      variables: {
        input,
      },
    })

    return data.ProductSearch.products.paginatorInfo.total
  }
}
