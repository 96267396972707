import {
  AppButtonProps as Props,
  ButtonInputType,
  ButtonSize,
  ButtonType,
} from './types'

import styles from './button.css?module'
import { Component, Prop, VueComponent, VNode } from '~/types/vue-ts-component'

@Component
export class AppButton extends VueComponent<Props> {
  static Type = ButtonType
  static Size = ButtonSize
  static InputType = ButtonInputType

  @Prop({
    default: ButtonType.Primary,
  })
  readonly type!: NonNullable<Props['type']>

  @Prop({
    default: ButtonInputType.button,
  })
  readonly inputType: Props['inputType']

  @Prop({
    default: ButtonSize.M,
  })
  readonly size!: NonNullable<Props['size']>

  @Prop() readonly whenClick!: Props['whenClick']

  render(): VNode {
    return (
      <button
        class={[styles.button, styles[this.type], styles[this.size]]}
        type={this.inputType}
        onClick={this.whenClick}
      >
        {this.$slots.default}
      </button>
    )
  }
}
