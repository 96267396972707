import { OperationVariables } from '@apollo/client/core/types'
import { Repository } from '~/abstracts/repository'
import { GQLCartUpdateInput, GQLMutation, GQLQuery } from '~/types/gql'
import { cartItemMapper } from '~/features/cart/cart-item.mapper'
import {
  ICartRepository,
  UpdateCartInput,
} from '~/repositories/cart/cart.repository.interface'

export class CartRepository extends Repository implements ICartRepository {
  public async getCart(token?: string) {
    const context: OperationVariables['context'] = {}

    if (token) {
      context.headers = {
        'X-App-Cart': token,
      }
    }

    const { data } = await this.apollo.client.query<{ Cart: GQLQuery['Cart'] }>(
      {
        query: await import('~/graphql/cart/get-cart.graphql'),
        context,
      }
    )

    return {
      cartItems: data.Cart.items.map(cartItemMapper),
      token: data.Cart.token,
    }
  }

  public async updateCart({ items, token }: UpdateCartInput): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<
      { CartUpdate: GQLMutation['CartUpdate'] },
      { input: GQLCartUpdateInput }
    >({
      mutation: await import('~/graphql/cart/cart-update.graphql'),
      variables: {
        input: {
          items,
        },
      },
      context: {
        headers: {
          'X-App-Cart': token,
        },
      },
    })

    return Boolean(data?.CartUpdate)
  }
}
