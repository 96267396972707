import { Link } from '~/entities/link'

export const breadcrumbsReducer = (acc: Link[], link: Link): Link[] => {
  const slugPrefix = acc[acc.length - 1]?.slug ?? '/catalog'
  acc.push({
    name: link.name,
    slug: slugPrefix + `/${link.slug}`,
  })
  return acc
}
