import { BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { createMask } from 'imask'
import styles from './form-input-code.css?module'
import { FormInputCodeProps as Props } from './types'
import { Component, VueComponent, Prop, VNode } from '~/types/vue-ts-component'

const codeMasker = createMask({
  mask: '0000',
})

const CODE_REGEXP = /\d{4}/

@Component
export class FormInputCode extends VueComponent<Props> {
  @Prop() readonly code!: Props['code']

  @Prop() readonly whenChange!: Props['whenChange']

  @Prop() readonly errorMessage!: Props['errorMessage']

  codeIsValid = true

  get hasErrorMessage(): boolean {
    return Boolean(this.errorMessage)
  }

  get codeInputState(): false | undefined {
    if (this.hasErrorMessage) return false
    return this.codeIsValid ? undefined : false
  }

  get codeHasValidFormat(): boolean {
    return CODE_REGEXP.test(this.code)
  }

  formatCode(code: string): string {
    return codeMasker.resolve(code)
  }

  validateCode(): boolean {
    this.codeIsValid = this.codeHasValidFormat
    return this.codeIsValid
  }

  render(): VNode {
    return (
      <div class={styles.wrapper}>
        <BFormInput
          type="text"
          maxLength={4}
          placeholder="Введите код из СМС"
          class={styles.input}
          state={this.codeInputState}
          formatter={this.formatCode}
          value={this.code}
          onInput={this.whenChange}
        />
        <BFormInvalidFeedback>
          {this.errorMessage ||
            (!this.codeIsValid && 'Для входа укажите код из SMS')}
        </BFormInvalidFeedback>
      </div>
    )
  }
}
