import styles from './styles.css?module'
import { Component, VueComponent, VNode, Watch } from '~/types/vue-ts-component'
import { AppInput } from '~/components/UI/ui-kit/input'
import { ProductSearch, minSearchText } from '~/components/layout/header/types'
import { ProductRepositoryInterface } from '~/abstracts/repository'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { debounce } from '~/decorators/debounce'

@Component
export class HeaderDesktopMainSearch extends VueComponent {
  repository!: ProductRepositoryInterface
  hints: ProductSearch[] = []

  searchText: string | null = null
  dropdownActive = false

  get dropdownIsShown(): boolean {
    return this.dropdownActive && this.hints.length > 0
  }

  clearState(): void {
    this.searchText = null
    this.hints = []
  }

  handleClickOutside(e: MouseEvent): void {
    e.stopPropagation()
    if (e.target !== this.$refs.dropdown) {
      this.dropdownActive = false
    }
  }

  @debounce(300)
  private async textUpdate(text: string): Promise<void> {
    this.searchText = text
    if (text.length < minSearchText) {
      this.hints = []
      this.dropdownActive = false
      return
    }
    this.hints = await this.repository.getProductSearchHints(text)
    this.dropdownActive = true
  }

  async handleHintClick(e: MouseEvent, slug: string): Promise<void> {
    e.preventDefault()
    await this.$router.push(`/details/${slug}`)
    this.clearState()
  }

  async redirectToSearchPage(): Promise<void> {
    if (!this.searchText || this.searchText.length < minSearchText) return

    await this.$router.push({
      path: '/search',
      query: {
        text: this.searchText,
      },
    })
    this.clearState()
  }

  @Watch('dropdownIsShown')
  handleDropdownClose(val: boolean) {
    val
      ? document.addEventListener('click', this.handleClickOutside)
      : document.removeEventListener('click', this.handleClickOutside)
  }

  created() {
    this.repository = container.get<ProductRepositoryInterface>(
      DiTypes.PRODUCT_REPOSITORY
    )
  }

  render(): VNode {
    return (
      <div class={styles.searchContainer}>
        <AppInput
          text={this.searchText}
          class={styles.searchInput}
          placeholder="Ночь, улица, фонарь, аптека..."
          textUpdate={this.textUpdate}
          whenEnterPressed={this.redirectToSearchPage}
        />
        <button class={styles.searchButton} onClick={this.redirectToSearchPage}>
          <img src={require('~/assets/icons/loop-white.svg')} />
        </button>
        <div
          vShow={this.dropdownIsShown}
          class={styles.searchResult}
          ref="dropdown"
        >
          <ul class={styles.hints}>
            {this.hints.map((product, i) => (
              <a
                href={`/details/${product.slug}`}
                onClick={(e: MouseEvent) =>
                  this.handleHintClick(e, product.slug)
                }
              >
                <li key={i} class={styles.hintItem}>
                  {product.name}
                </li>
              </a>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
