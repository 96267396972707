export const stringifyObject = (target: object): string =>
  JSON.stringify(target, null, 2)

export const isEmpty = (target?: object): boolean =>
  target == null || !(Object.keys(target) || target).length

export const trimObject = (target: any): object => {
  if (isEmpty(target)) {
    return {}
  }

  return Object.keys(target)
    .filter((key: string) => target?.[key])
    .reduce(
      (a: object, key: string): object => ({
        ...a,
        [key]: target[key],
      }),
      {}
    )
}
