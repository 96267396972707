// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".link_1fbjv{display:inline;font-size:16px;line-height:24px;font-weight:500;color:var(--color-tiffany-cactus);margin-left:5px}.content_1CugE{margin-bottom:31px;text-align:center}.resendLink_tN5ne{display:block;text-align:center;margin-top:14px}.resend_1ai23{text-align:left;font-size:14px;line-height:20px;color:var(--color-mystery-gray);margin-left:15px;margin-top:8px}@media (min-width:768px){.content_1CugE{margin-bottom:17px}}", ""]);
// Exports
exports.locals = {
	"link": "link_1fbjv",
	"content": "content_1CugE",
	"resendLink": "resendLink_tN5ne",
	"resend": "resend_1ai23"
};
module.exports = exports;
