import { injectable } from 'inversify'

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client'
import fetch from 'node-fetch'

import { createErrorLink } from './links/error-link'
import { getAuthLink } from './links/auth'
import { GraphqlClientInterface } from './types'

const getHttpLink = (uri: string) =>
  createHttpLink({
    uri,
    fetch: (fetch as unknown) as WindowOrWorkerGlobalScope['fetch'],
    headers: { 'X-App-Name': process.env.applicationName },
  })

const cache = new InMemoryCache()

const createClient = () => {
  if (process.env.graphQLEndpoint === undefined) {
    throw new Error('GraphQL endpoint is undefined')
  }

  const httpLink = getHttpLink(process.env.graphQLEndpoint)
  const errorLink = createErrorLink()
  const authLink = getAuthLink()

  const apolloLinks = ApolloLink.from([
    (errorLink as unknown) as ApolloLink,
    authLink,
    httpLink,
  ])

  return new ApolloClient({
    link: apolloLinks,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

@injectable()
class Apollo implements GraphqlClientInterface {
  public client: ApolloClient<any>

  constructor() {
    this.client = createClient()
  }
}

export { Apollo }
