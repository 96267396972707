// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headerMobile_3N2P-{margin-top:16px!important;margin-bottom:16px!important}.headerContainer_1NyXA{display:flex;align-items:center;justify-content:space-between}.headerContainer_1NyXA .logo_HtlOI{width:125px;height:30px}.search_3ozOG{margin-top:14px}.hints_gwU-E{margin-top:8px!important}.hintItem_epeMJ{display:block;border-bottom:1px solid #eee}.hintItem_epeMJ li{padding:8px 12px}@media (min-width:960px){.headerMobile_3N2P-{display:none}}", ""]);
// Exports
exports.locals = {
	"headerMobile": "headerMobile_3N2P-",
	"headerContainer": "headerContainer_1NyXA",
	"logo": "logo_HtlOI",
	"search": "search_3ozOG",
	"hints": "hints_gwU-E",
	"hintItem": "hintItem_epeMJ"
};
module.exports = exports;
