import { CheckoutForm } from '~/entities/checkout-form'
import { Factory, OrderInputFactoryInterface } from '~/abstracts/factory'
import { GQLOrderInput, GQLOrderItemInput } from '~/types/gql'
import { OrderItemInputFactory } from '~/features/order/factories/order-item-input-factory'
import { OrderCustomerInputFactory } from '~/features/order/factories/order-customer-input-factory'
import { OrderItem } from '~/entities/order-item'

export class OrderInputFactory
  extends Factory<CheckoutForm, GQLOrderInput>
  implements OrderInputFactoryInterface {
  private itemInputFactory = new OrderItemInputFactory()
  private customerInputFactory = new OrderCustomerInputFactory()

  public create(form: CheckoutForm) {
    return {
      items: form.items.map(this.itemInputFactory.create),
      shipping: form.shipping,
      payment: form.payment,
      customer: this.customerInputFactory.create(form.customer),
      address: this.createAddress(form.deliveryAddress),
      comment: form.comment,
    }
  }

  private createAddress(
    formAddress: CheckoutForm['deliveryAddress']
  ): GQLOrderInput['address'] {
    if (!formAddress) return
    return {
      address: formAddress.address,
      apartment: formAddress.apartment,
      entrance: formAddress.entrance,
      intercom: formAddress.intercom,
      floor: formAddress.floor,
    }
  }

  public createOrderItems(items: OrderItem[]): GQLOrderItemInput[] {
    return this.itemInputFactory.fromArray(items)
  }
}
