import { Mutation, State, Action } from 'vuex-simple'
import { getAuthToken } from '~/utils/auth-token'

export class AuthorizationModule {
  @State()
  public authPopup: boolean = false

  @State()
  public authenticated: boolean = false

  @Mutation()
  public setPopupShow(value: boolean): void {
    this.authPopup = value
  }

  @Mutation()
  public setAuthenticated(value: boolean): void {
    this.authenticated = value
  }

  @Action()
  public setAuthenticatedFromLocalValue() {
    const hasToken = Boolean(getAuthToken())
    if (this.authenticated !== hasToken) {
      this.setAuthenticated(hasToken)
    }
  }
}
