import { LOCAL_STORAGE_KEYS } from '~/config/local-storage-keys'

const TOKEN_PREFIX = 'Bearer '

export const setAuthToken = (token: string): void => {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, TOKEN_PREFIX + token)
}

export const getAuthToken = (): string | null => {
  if (typeof window === 'undefined') return null
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
}

export const removeAuthToken = (): void => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)
}
