import { Context, Middleware } from '@nuxt/types'
import { RootModule, useStore } from '~/store/root'
import { ClientStorageManager } from '~/app/client-storage-manager/client-storage-manager'
import { ClientStorageKey } from '~/app/client-storage-manager/client-storage-manager.types'

export const loadCartAndCheckIfEmpty: Middleware = async ({
  redirect,
  store: vuexStore,
}: Context): Promise<void> => {
  const { cart } = useStore<RootModule>(vuexStore)

  const cartToken = ClientStorageManager.getValue<string>(
    ClientStorageKey.CartToken
  )

  if (cartToken) {
    cart.setCartToken(cartToken)
  }

  await cart.getCartItems()

  if (cart.cartItems.length === 0) {
    redirect('/cart/empty')
  }
}
