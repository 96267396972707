import styles from './header-desktop-navigation.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

import { Container } from '~/components/layout/common/container'
import { Link } from '~/entities/link'

interface Props {
  items: Link[]
}

@Component
export class HeaderDesktopNavigation extends VueComponent<Props> {
  @Prop() readonly items!: Props['items']

  render(): VNode {
    return (
      <nav class={styles.navigation}>
        <Container>
          <ul class={styles.navList}>
            {this.items.map((item, i) => (
              <li key={i} class={styles.navItem}>
                <nuxt-link to={`/catalog/${item.slug}`}>{item.name}</nuxt-link>
              </li>
            ))}
          </ul>
        </Container>
      </nav>
    )
  }
}
