import { AuthorizationFormLayout } from '../authorization-form-layout'
import {
  Component,
  VueComponent,
  Prop,
  Ref,
  VNode,
} from '~/types/vue-ts-component'
import { AppButton } from '~/components/UI/ui-kit'
import { FormInputPhone } from '~/components/UI/form/form-input-phone'

interface Props {
  phone: string
  updatePhone: (phone: string) => void
  sendCode: () => void
  heading?: string
}

@Component
export class AuthorizationPhoneForm extends VueComponent<Props> {
  @Prop() readonly phone!: Props['phone']

  @Prop() readonly updatePhone!: Props['updatePhone']

  @Prop() readonly sendCode!: Props['sendCode']

  @Prop({
    default: 'Войдите, чтобы продолжить',
  })
  readonly heading!: NonNullable<Props['heading']>

  @Ref('phoneInput')
  phoneInput!: FormInputPhone

  handleSubmit(): void {
    if (!this.phoneInput.validatePhone()) return
    this.sendCode()
  }

  render(): VNode {
    return (
      <AuthorizationFormLayout heading={this.heading}>
        {this.$slots.subHeader}
        <FormInputPhone
          ref="phoneInput"
          phone={this.phone}
          whenChange={this.updatePhone}
        />
        <AppButton whenClick={this.handleSubmit}>Получить код</AppButton>
        {this.$slots.bottom}
      </AuthorizationFormLayout>
    )
  }
}
