import { CheckoutForm } from '~/entities/checkout-form'
import { Factory, OrderInputFactoryInterface } from '~/abstracts/factory'
import { GQLOrderInput, GQLOrderItemInput } from '~/types/gql'
import { OrderItemInputFactory } from '~/features/order/factories/order-item-input-factory'
import { OrderCustomerInputFactory } from '~/features/order/factories/order-customer-input-factory'
import { OrderItem } from '~/features/order/order-item/order-item.interface'

export class PickupOrderInputFactory
  extends Factory<CheckoutForm, GQLOrderInput>
  implements OrderInputFactoryInterface {
  private itemInputFactory = new OrderItemInputFactory()
  private customerInputFactory = new OrderCustomerInputFactory()

  public create(form: CheckoutForm) {
    return {
      items: form.items.map(this.itemInputFactory.create),
      shipping: form.shipping,
      payment: form.payment,
      customer: this.customerInputFactory.create(form.customer),
      comment: form.comment,
    }
  }

  createOrderItems(items: OrderItem[]): GQLOrderItemInput[] {
    return this.itemInputFactory.fromArray(items)
  }
}
