// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout_Y-H6R{display:flex;flex-direction:column;height:auto;min-height:100vh}.contentWrapper_2OIEM{position:relative;flex:1;height:100%}", ""]);
// Exports
exports.locals = {
	"layout": "layout_Y-H6R",
	"contentWrapper": "contentWrapper_2OIEM"
};
module.exports = exports;
