import { Module, State, Mutation, Action } from 'vuex-simple'

import { CartModule } from './modules/cart'
import { LayoutModule } from './modules/layout'
import { AuthorizationModule } from './modules/authorization'
import { ProductModule } from '~/store/modules/product'
import { CategoryTreeModule } from '~/store/modules/category-tree'
import { CartUpdatedModule } from '~/store/modules/cart-updated'
import { container } from '~/config/invercify'
import { ILogger } from '~/app/logger'
import DiTypes from '~/config/DiTypes'
export { useStore } from 'vuex-simple'

export class RootModule {
  $logger = container.get<ILogger>(DiTypes.LOGGER)

  @Module()
  public cartOld = new CartModule()

  @Module()
  public layout = new LayoutModule()

  @Module()
  public authorization = new AuthorizationModule()

  @Module()
  public product = new ProductModule()

  @Module()
  public categoryTree = new CategoryTreeModule()

  @Module()
  public cart = new CartUpdatedModule(this)

  @State()
  public version = '1.0.0'

  @Mutation()
  public setVersion(version: string): void {
    this.version = version
  }

  @Action()
  async nuxtServerInit() {
    await this.categoryTree.initiate()
  }
}

export {
  CartUpdatedModule as CartModule,
  LayoutModule,
  AuthorizationModule,
  ProductModule,
  CategoryTreeModule,
}
