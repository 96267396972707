import { injectable } from 'inversify'
import { IFbq, FbStandardEvent } from '~/app/fbq/fbq.interface'

@injectable()
export class FbqLocal implements IFbq {
  init(appId: string): void {
    // eslint-disable-next-line no-console
    console.log(`[Vue Facebook Pixel] Initializing app ${appId}`)
  }

  track(name: FbStandardEvent, data: object | undefined): void {
    // eslint-disable-next-line no-console
    console.groupCollapsed(`[Facebook Pixel] Track event "${name}"`)
    // eslint-disable-next-line no-console
    console.log(`With data: ${data}`)
    // eslint-disable-next-line no-console
    console.groupEnd()
  }
}
