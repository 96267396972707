// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footerContainer_LoXgz{display:grid;grid-template-columns:repeat(2,1fr);padding-top:28px;padding-bottom:28px;grid-column-gap:12px;-moz-column-gap:12px;column-gap:12px;grid-row-gap:16px;row-gap:16px;z-index:1;position:relative}.main_2M2Fp{font-weight:700;font-size:9px;color:var(--color-ripe-plum)}.contacts_2eHyc{font-size:9px;line-height:12px;color:var(--color-mystery-gray)}.social_37sGc{font-weight:500;font-size:11px;line-height:14px}.socialIcons_1apsr{display:grid;grid-template-columns:-webkit-max-content -webkit-max-content;grid-template-columns:max-content max-content;grid-column-gap:12px;-moz-column-gap:12px;column-gap:12px}@media (min-width:768px){.footer_349hr{margin-bottom:0}}", ""]);
// Exports
exports.locals = {
	"footerContainer": "footerContainer_LoXgz",
	"main": "main_2M2Fp",
	"contacts": "contacts_2eHyc",
	"social": "social_37sGc",
	"socialIcons": "socialIcons_1apsr",
	"footer": "footer_349hr"
};
module.exports = exports;
