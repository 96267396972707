import { injectable } from 'inversify'
import { GQLCategoryTree } from '~/types/gql'
import { CategoryTreeType } from '~/entities/category-tree'

@injectable()
export class CategoryTreeFactory {
  create(input: GQLCategoryTree[]): CategoryTreeType[] {
    const flatTree = input.map((item) => this.createNode(item, input))

    return this.flatToTree(flatTree)
  }

  private createNode(
    category: GQLCategoryTree,
    input: GQLCategoryTree[]
  ): CategoryTreeType {
    return {
      text: category.name,
      children: [],
      data: {
        id: Number(category.id),
        parentId: category.parentId ? Number(category.parentId) : undefined,
        path: this.getCategoryPath(category, input),
        slug: category.slug,
        flags: category.flags || [],
      },
      state: {
        disabled: false,
        expanded: false,
        selectable: false,
      },
    }
  }

  private getCategoryPath(
    category: GQLCategoryTree,
    input: GQLCategoryTree[]
  ): string {
    const INITIAL_PATH = '/catalog/'

    const findParent = (parentId: string): GQLCategoryTree | undefined => {
      return input.find(({ id }) => id === parentId)
    }

    const getSlugChain = (inputCategory: GQLCategoryTree): string[] => {
      if (!inputCategory.parentId) {
        return [inputCategory.slug]
      }
      const parent = findParent(inputCategory.parentId)

      if (!parent) {
        return [inputCategory.slug]
      }

      return [inputCategory.slug, ...getSlugChain(parent)]
    }

    return INITIAL_PATH + getSlugChain(category).reverse().join('/')
  }

  private flatToTree(categories: CategoryTreeType[]): CategoryTreeType[] {
    const roots: CategoryTreeType[] = []
    const map: { [key: string]: number } = {}

    for (let i = 0; i < categories.length; i += 1) {
      map[categories[i].data.id] = i
    }

    for (let i = 0; i < categories.length; i += 1) {
      const node: CategoryTreeType = categories[i]
      if (node.data.parentId) {
        categories[map[node.data.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }
}
