export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type GQLBanner = {
  __typename?: 'Banner';
  id: Scalars['ID'];
  desktopImage: Scalars['String'];
  mobileImage: Scalars['String'];
  isActive: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
};

export type GQLBannerInput = {
  id?: Maybe<Scalars['ID']>;
  desktopImage?: Maybe<Scalars['String']>;
  mobileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type GQLBanners = {
  banners: Array<GQLBannerInput>;
};

export type GQLBrand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  isPageActive: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  seo: GQLSeo;
};

export type GQLBrandInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  isPageActive: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  seo?: Maybe<GQLSeoInput>;
};

export type GQLCart = {
  __typename?: 'Cart';
  items: Array<GQLCartItem>;
  token: Scalars['String'];
};

export type GQLCartItem = {
  __typename?: 'CartItem';
  product: GQLProduct;
  quantity: Scalars['Int'];
};

export type GQLCartItemInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLCartUpdateInput = {
  items: Array<GQLCartItemInput>;
};

export type GQLCategory = {
  __typename?: 'Category';
  /** Category internal ID */
  id: Scalars['ID'];
  /** Category name */
  name: Scalars['String'];
  /** Category site path */
  slug: Scalars['String'];
  /** Category description */
  description: Scalars['String'];
  /** Active flag */
  isActive: Scalars['Boolean'];
  /** Allows to pay for items in the category */
  onlinePayment: Scalars['Boolean'];
  /** Cursor to parent ID */
  parentId?: Maybe<Scalars['ID']>;
  /** Available filters */
  filters?: Maybe<Array<GQLProductsFilter>>;
  /** Attributes */
  flags?: Maybe<Array<GQLCategoryFlag>>;
  /** Parent category */
  parent?: Maybe<GQLCategory>;
  /** Category ancestors */
  ancestors: Array<GQLCategoryTree>;
  /** Category children */
  children: Array<GQLCategory>;
  /** Category order number */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Products in children's category */
  allProducts: GQLProductPaginator;
  /** Search optimization fields */
  seo: GQLSeo;
  /** Products in category */
  products?: Maybe<GQLProductPaginator>;
};


export type GQLCategoryAllProductsArgs = {
  first: Scalars['Int'];
  page: Scalars['Int'];
  filter?: Maybe<GQLProductFilterInput>;
};


export type GQLCategoryProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type GQLCategoryFilter = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  depthLimit?: Maybe<Scalars['Int']>;
};

export enum GQLCategoryFlag {
  Menu = 'menu',
  Main = 'main'
}

export type GQLCategorySaveInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  onlinePayment: Scalars['Boolean'];
  parentId?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<GQLProductsFilter>>;
  flags?: Maybe<Array<GQLCategoryFlag>>;
  seo: GQLSeoInput;
};

export type GQLCategoryTree = {
  __typename?: 'CategoryTree';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  flags?: Maybe<Array<GQLCategoryFlag>>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<GQLCategoryTree>;
  ancestors: Array<GQLCategoryTree>;
  sortOrder?: Maybe<Scalars['Int']>;
  seo: GQLSeo;
};

export type GQLCategoryTreeSaveInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
};

export type GQLCountry = {
  __typename?: 'Country';
  /** Country name */
  name: Scalars['String'];
};

export type GQLCustomerAddress = {
  __typename?: 'CustomerAddress';
  address: Scalars['String'];
  apartment?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  intercom?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type GQLCustomerAddressInput = {
  address: Scalars['String'];
  apartment?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  intercom?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
};


export type GQLDateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};



export type GQLGalleryImage = {
  __typename?: 'GalleryImage';
  /** Image data */
  image: GQLImage;
  /** Image order number */
  sortOrder: Scalars['Int'];
};

export type GQLImage = {
  __typename?: 'Image';
  /** Image internal ID */
  id: Scalars['ID'];
  /**
   * Image name
   * @deprecated Use the `title` field
   */
  name: Scalars['String'];
  /** Image CDN path */
  path: Scalars['String'];
  /** Image alt attribute */
  alt: Scalars['String'];
  /** Image title attribute */
  title: Scalars['String'];
};

export type GQLImageUploadInput = {
  image: Scalars['String'];
};

export type GQLImageUploadResult = {
  __typename?: 'ImageUploadResult';
  url: Scalars['String'];
};

export type GQLIngredient = {
  __typename?: 'Ingredient';
  /** Ingredient name */
  name: Scalars['String'];
};


export type GQLLoginBySmsInput = {
  phone: Scalars['String'];
  code: Scalars['Int'];
};

export type GQLLoginCodeInput = {
  phone: Scalars['String'];
};

export type GQLLoginInfo = {
  __typename?: 'LoginInfo';
  token: Scalars['String'];
  user: GQLUser;
};

export type GQLLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type GQLManagerInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  birthday?: Maybe<Scalars['Date']>;
  role: GQLRole;
};

export type GQLManagerLog = {
  __typename?: 'ManagerLog';
  entity?: Maybe<GQLManagerLogEntity>;
  user: GQLUser;
  type: GQLManagerLogType;
  log?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTimeTz'];
};

export type GQLManagerLogEntity = GQLProduct;

export type GQLManagerLogsFilter = {
  createdAt?: Maybe<GQLDateRange>;
};

export enum GQLManagerLogType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export type GQLManufacturer = {
  __typename?: 'Manufacturer';
  /** Manufacturer name */
  name: Scalars['String'];
};

export type GQLMenu = {
  __typename?: 'Menu';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  children: Array<GQLMenuLink>;
};

export type GQLMenuLink = {
  __typename?: 'MenuLink';
  id: Scalars['ID'];
  parent?: Maybe<GQLMenuLink>;
  sortOrder: Scalars['Int'];
  children: Array<GQLMenuLink>;
};

export type GQLMeta = {
  __typename?: 'Meta';
  inFavourite: Scalars['Boolean'];
};

export type GQLMutation = {
  __typename?: 'Mutation';
  ProductSave: GQLProduct;
  ProductRemove?: Maybe<GQLProduct>;
  ProductUpdate: GQLProduct;
  CategorySave: GQLCategory;
  CategoryRemove?: Maybe<GQLCategory>;
  CategoryTreeSave: Scalars['Boolean'];
  BannerSave: Array<GQLBanner>;
  BannerRemove?: Maybe<GQLBanner>;
  CartUpdate: Scalars['Boolean'];
  OrderCreate: GQLOrder;
  OrderSave: GQLOrder;
  OrderStatusUpdate: GQLOrder;
  OrderRemove?: Maybe<GQLOrder>;
  OrderPay: GQLPaymentForm;
  UpdatePaymentStatus: GQLPaymentStatus;
  /** Log in to a new session and get the user. */
  Login: GQLLoginInfo;
  /** Send a verification code to user. */
  SendLoginCode: GQLSmsInfo;
  /** Log in to a new session and get the user by SMS. */
  LoginBySMS: GQLLoginInfo;
  /** Log out from the current session, showing the user one last time. */
  Logout?: Maybe<GQLUser>;
  /** Update current user. */
  UpdateMe: GQLUser;
  UserFavouriteUpdate: Scalars['Boolean'];
  /** Update manager. */
  ManagerSave: GQLUser;
  ManagerPasswordReset: Scalars['Boolean'];
  BrandSave: GQLBrand;
  BrandRemove: GQLBrand;
  ImageUpload: GQLImageUploadResult;
};


export type GQLMutationProductSaveArgs = {
  input: GQLProductSaveInput;
};


export type GQLMutationProductRemoveArgs = {
  id: Scalars['ID'];
};


export type GQLMutationProductUpdateArgs = {
  input: GQLProductUpdateInput;
};


export type GQLMutationCategorySaveArgs = {
  input: GQLCategorySaveInput;
};


export type GQLMutationCategoryRemoveArgs = {
  id: Scalars['ID'];
};


export type GQLMutationCategoryTreeSaveArgs = {
  input: Array<GQLCategoryTreeSaveInput>;
};


export type GQLMutationBannerSaveArgs = {
  input: GQLBanners;
};


export type GQLMutationBannerRemoveArgs = {
  id: Scalars['ID'];
};


export type GQLMutationCartUpdateArgs = {
  input: GQLCartUpdateInput;
};


export type GQLMutationOrderCreateArgs = {
  input: GQLOrderCreateInput;
};


export type GQLMutationOrderSaveArgs = {
  input: GQLOrderInput;
};


export type GQLMutationOrderStatusUpdateArgs = {
  id: Scalars['ID'];
  newStatusCode: GQLOrderStatusCode;
};


export type GQLMutationOrderRemoveArgs = {
  id: Scalars['ID'];
};


export type GQLMutationOrderPayArgs = {
  input: GQLOrderPayInput;
};


export type GQLMutationUpdatePaymentStatusArgs = {
  input: GQLUpdatePaymentStatusInput;
};


export type GQLMutationLoginArgs = {
  input: GQLLoginInput;
};


export type GQLMutationSendLoginCodeArgs = {
  input: GQLLoginCodeInput;
};


export type GQLMutationLoginBySmsArgs = {
  input: GQLLoginBySmsInput;
};


export type GQLMutationUpdateMeArgs = {
  input?: Maybe<GQLUserInput>;
};


export type GQLMutationUserFavouriteUpdateArgs = {
  input: GQLUserFavouriteInput;
};


export type GQLMutationManagerSaveArgs = {
  input?: Maybe<GQLManagerInput>;
};


export type GQLMutationManagerPasswordResetArgs = {
  id: Scalars['ID'];
};


export type GQLMutationBrandSaveArgs = {
  input: GQLBrandInput;
};


export type GQLMutationBrandRemoveArgs = {
  id: Scalars['ID'];
};


export type GQLMutationImageUploadArgs = {
  input: GQLImageUploadInput;
};

export type GQLOrder = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  status: GQLOrderStatus;
  shipping: GQLOrderShipping;
  payment: GQLOrderPayment;
  amount: Scalars['Int'];
  customer: GQLOrderCustomer;
  address?: Maybe<GQLCustomerAddress>;
  comment?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  items: Array<GQLOrderItem>;
  createdAt: Scalars['DateTimeTz'];
};

/** Allows ordering a list of records. */
export type GQLOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: GQLSortOrder;
};

export type GQLOrderCreateInput = {
  /** Array of product IDs */
  items: Array<GQLOrderItemInput>;
  /** Shipping code */
  shipping: GQLOrderShippingCode;
  /** Payment code */
  payment: GQLOrderPaymentCode;
  /** Customer info */
  customer: GQLOrderCustomerInput;
  /** Address info */
  address?: Maybe<GQLCustomerAddressInput>;
  /** Comment from user */
  comment?: Maybe<Scalars['String']>;
};

export type GQLOrderCustomer = {
  __typename?: 'OrderCustomer';
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type GQLOrderCustomerInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type GQLOrderInput = {
  id?: Maybe<Scalars['ID']>;
  /** Array of product IDs */
  items: Array<GQLOrderItemInput>;
  /** Shipping code */
  shipping: GQLOrderShippingCode;
  /** Payment code */
  payment: GQLOrderPaymentCode;
  /** Customer info */
  customer: GQLOrderCustomerInput;
  /** Address info */
  address?: Maybe<GQLCustomerAddressInput>;
  /** Comment from user */
  comment?: Maybe<Scalars['String']>;
};

export type GQLOrderItem = {
  __typename?: 'OrderItem';
  name: Scalars['String'];
  slug: Scalars['String'];
  sku: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  oldPrice: Scalars['Int'];
  product?: Maybe<GQLProduct>;
};

export type GQLOrderItemInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

/** A paginated list of Order items. */
export type GQLOrderPaginator = {
  __typename?: 'OrderPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: GQLPaginatorInfo;
  /** A list of Order items. */
  data: Array<GQLOrder>;
};

export type GQLOrderPayInput = {
  order: Scalars['String'];
};

export type GQLOrderPayment = {
  __typename?: 'OrderPayment';
  name: Scalars['String'];
  code: GQLOrderPaymentCode;
  isActive: Scalars['Boolean'];
};

export enum GQLOrderPaymentCode {
  Cash = 'CASH',
  Card = 'CARD'
}

export type GQLOrdersFilter = {
  status?: Maybe<Scalars['String']>;
};

export type GQLOrderShipping = {
  __typename?: 'OrderShipping';
  name: Scalars['String'];
  code: GQLOrderShippingCode;
  cost: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export enum GQLOrderShippingCode {
  Pickup = 'PICKUP',
  DeliveryInMoscow = 'DELIVERY_IN_MOSCOW'
}

export type GQLOrderStatus = {
  __typename?: 'OrderStatus';
  name: Scalars['String'];
  code: GQLOrderStatusCode;
};

export enum GQLOrderStatusCode {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Paid = 'PAID',
  Failed = 'FAILED',
  Delivered = 'DELIVERED',
  Cancelled = 'CANCELLED'
}

/** Pagination information about the corresponding list of items. */
export type GQLPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type GQLPaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type GQLPaymentForm = {
  __typename?: 'PaymentForm';
  formUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  error?: Maybe<GQLPaymentFormError>;
};

export type GQLPaymentFormError = {
  __typename?: 'PaymentFormError';
  code: GQLPaymentFormErrorCode;
  message: Scalars['String'];
};

export enum GQLPaymentFormErrorCode {
  AlreadyPaid = 'ALREADY_PAID',
  Failed = 'FAILED',
  Denied = 'DENIED'
}

export type GQLPaymentStatus = {
  __typename?: 'PaymentStatus';
  order: GQLOrder;
  code: GQLPaymentStatusCode;
};

export enum GQLPaymentStatusCode {
  Done = 'DONE',
  Error = 'ERROR',
  Denied = 'DENIED'
}

export type GQLPriceRange = {
  __typename?: 'PriceRange';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type GQLPriceRangeInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type GQLProduct = {
  __typename?: 'Product';
  /** Internal product ID */
  id: Scalars['ID'];
  /** Product name */
  name: Scalars['String'];
  /** Product site path */
  slug: Scalars['String'];
  /** Stock Keeping Unit */
  sku: Scalars['String'];
  /** Product description */
  description: Scalars['String'];
  /** Instructions for medical preparation */
  instruction?: Maybe<Scalars['JSON']>;
  /** Producing country */
  country?: Maybe<GQLCountry>;
  /** Company */
  manufacturer?: Maybe<GQLManufacturer>;
  /** Active substances */
  ingredient?: Maybe<GQLIngredient>;
  /** Brand */
  brand?: Maybe<GQLBrand>;
  /** Price in penny */
  price: Scalars['Int'];
  /** Previous price in penny */
  oldPrice: Scalars['Int'];
  /** Stock synchronization flag */
  isStockSync: Scalars['Boolean'];
  /** Quantity in stock */
  inStock?: Maybe<Scalars['Int']>;
  /** Stock update date */
  inStockUpdate?: Maybe<Scalars['DateTimeTz']>;
  /** Active flag */
  isActive: Scalars['Boolean'];
  /** Product images */
  images: Array<GQLGalleryImage>;
  /** Main product category */
  category?: Maybe<GQLCategory>;
  /** Other product categories */
  categories: Array<GQLCategory>;
  /** Search optimization fields */
  seo: GQLSeo;
  meta: GQLMeta;
  searchWords: Array<Scalars['String']>;
  score: Scalars['Float'];
};

export type GQLProductCategoryInput = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type GQLProductFilter = {
  query?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  priceRange?: Maybe<GQLPriceRangeInput>;
  categories?: Maybe<Array<Scalars['ID']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isStockSync?: Maybe<Scalars['Boolean']>;
};

export type GQLProductFilterInput = {
  price?: Maybe<GQLProductPriceFilter>;
  country?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Array<Scalars['String']>>;
  manufacturer?: Maybe<Array<Scalars['String']>>;
  ingredient?: Maybe<Array<Scalars['String']>>;
};

export type GQLProductFilterOptions = {
  __typename?: 'ProductFilterOptions';
  category?: Maybe<Array<Scalars['ID']>>;
  country?: Maybe<Array<Scalars['String']>>;
  ingredient?: Maybe<Array<Scalars['String']>>;
  manufacturer?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Array<Scalars['String']>>;
};

export type GQLProductImageSaveInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
};

/** A paginated list of Product items. */
export type GQLProductPaginator = {
  __typename?: 'ProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: GQLPaginatorInfo;
  /** A list of Product items. */
  data: Array<GQLProduct>;
};

export type GQLProductPriceFilter = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type GQLProductSaveInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sku: Scalars['String'];
  description: Scalars['String'];
  instruction?: Maybe<Scalars['JSON']>;
  price: Scalars['Int'];
  oldPrice: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isStockSync: Scalars['Boolean'];
  images: Array<GQLProductImageSaveInput>;
  categories: Array<GQLProductCategoryInput>;
  categoryId?: Maybe<Scalars['ID']>;
  seo: GQLSeoInput;
  country?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  ingredient?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  searchWords?: Maybe<Array<Scalars['String']>>;
};

export type GQLProductSearchInput = {
  /** Searching by name, sku, description */
  query?: Maybe<Scalars['String']>;
  priceRange?: Maybe<GQLPriceRangeInput>;
  category?: Maybe<Array<Scalars['ID']>>;
  country?: Maybe<Array<Scalars['String']>>;
  ingredient?: Maybe<Array<Scalars['String']>>;
  manufacturer?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Array<Scalars['String']>>;
};

export type GQLProductSearchResult = {
  __typename?: 'ProductSearchResult';
  filterOptions: GQLProductFilterOptions;
  priceRange: GQLPriceRange;
  products: GQLProductPaginator;
};


export type GQLProductSearchResultProductsArgs = {
  first: Scalars['Int'];
  page: Scalars['Int'];
};

export enum GQLProductsFilter {
  Price = 'price',
  Country = 'country',
  Brand = 'brand',
  Manufacturer = 'manufacturer',
  Ingredient = 'ingredient'
}

export type GQLProductUpdateInput = {
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isStockSync?: Maybe<Scalars['Boolean']>;
};

export type GQLQuery = {
  __typename?: 'Query';
  Products: GQLProductPaginator;
  Product?: Maybe<GQLProduct>;
  ProductAnalogs: Array<GQLProduct>;
  ProductSuggestion: Array<GQLProduct>;
  ProductSearch: GQLProductSearchResult;
  Countries: Array<GQLCountry>;
  Manufacturers: Array<GQLManufacturer>;
  Ingredients: Array<GQLIngredient>;
  Brands: Array<GQLBrand>;
  Brand: GQLBrand;
  Categories: Array<GQLCategoryTree>;
  Category?: Maybe<GQLCategory>;
  CategoryByPath?: Maybe<GQLCategory>;
  Banners: Array<GQLBanner>;
  Cart: GQLCart;
  Order: GQLOrder;
  OrderStatuses: Array<GQLOrderStatus>;
  OrderShippings: Array<GQLOrderShipping>;
  OrderPayments: Array<GQLOrderPayment>;
  Me: GQLUser;
  Users: GQLUsersPaginator;
  User: GQLUser;
  ManagerLogs: Array<Maybe<GQLManagerLog>>;
  SearchHistory: GQLSearchQueryPaginator;
  Orders?: Maybe<GQLOrderPaginator>;
};


export type GQLQueryProductsArgs = {
  first: Scalars['Int'];
  page: Scalars['Int'];
  filter?: Maybe<GQLProductFilter>;
};


export type GQLQueryProductArgs = {
  slug: Scalars['String'];
};


export type GQLQueryProductAnalogsArgs = {
  slug: Scalars['String'];
};


export type GQLQueryProductSuggestionArgs = {
  query: Scalars['String'];
};


export type GQLQueryProductSearchArgs = {
  input: GQLProductSearchInput;
};


export type GQLQueryBrandArgs = {
  slug: Scalars['String'];
};


export type GQLQueryCategoriesArgs = {
  filter?: Maybe<GQLCategoryFilter>;
};


export type GQLQueryCategoryArgs = {
  id: Scalars['ID'];
};


export type GQLQueryCategoryByPathArgs = {
  slug: Array<Scalars['String']>;
};


export type GQLQueryOrderArgs = {
  id: Scalars['ID'];
};


export type GQLQueryOrderPaymentsArgs = {
  input?: Maybe<Array<GQLOrderItemInput>>;
};


export type GQLQueryUsersArgs = {
  first: Scalars['Int'];
  page: Scalars['Int'];
  filter?: Maybe<GQLUserFilterInput>;
};


export type GQLQueryUserArgs = {
  id: Scalars['ID'];
};


export type GQLQueryManagerLogsArgs = {
  filter: GQLManagerLogsFilter;
};


export type GQLQuerySearchHistoryArgs = {
  first: Scalars['Int'];
  page: Scalars['Int'];
  historyType?: Maybe<GQLSearchHistoryType>;
};


export type GQLQueryOrdersArgs = {
  orderBy?: Maybe<Array<GQLQueryOrdersOrderByOrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

/** Allowed column names for the `orderBy` argument on field `Orders` on type `Query`. */
export enum GQLQueryOrdersOrderByColumn {
  StatusId = 'STATUS_ID',
  OrderNumber = 'ORDER_NUMBER',
  CreatedAt = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `Orders`. */
export type GQLQueryOrdersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: GQLQueryOrdersOrderByColumn;
  /** The direction that is used for ordering. */
  order: GQLSortOrder;
};

export enum GQLRole {
  Admin = 'admin',
  OrderManager = 'orderManager',
  ContentManager = 'contentManager',
  Stock = 'stock',
  User = 'user'
}

export enum GQLSearchHistoryType {
  Grouped = 'grouped',
  Timeline = 'timeline'
}

export type GQLSearchQuery = GQLSearchQueryGroupedResult | GQLSearchQueryTimelineResult;

export type GQLSearchQueryGroupedResult = {
  __typename?: 'SearchQueryGroupedResult';
  query: Scalars['String'];
  total: Scalars['Int'];
  lastRequest: Scalars['DateTime'];
};

export type GQLSearchQueryPaginator = {
  __typename?: 'SearchQueryPaginator';
  paginatorInfo: GQLPaginatorInfo;
  data: Array<GQLSearchQuery>;
};

export type GQLSearchQueryTimelineResult = {
  __typename?: 'SearchQueryTimelineResult';
  query: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type GQLSeo = {
  __typename?: 'Seo';
  /** Page title */
  title: Scalars['String'];
  /** Meta keywords */
  keywords: Scalars['String'];
  /** Meta description */
  description: Scalars['String'];
};

export type GQLSeoInput = {
  title: Scalars['String'];
  keywords: Scalars['String'];
  description: Scalars['String'];
};

/** Pagination information about the corresponding list of items. */
export type GQLSimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
};

export type GQLSmsInfo = {
  __typename?: 'SMSInfo';
  expiredAt: Scalars['DateTimeTz'];
};

/** The available directions for ordering a list of records. */
export enum GQLSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum GQLTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type GQLUpdatePaymentStatusInput = {
  paymentToken: Scalars['String'];
};

export type GQLUser = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  birthday?: Maybe<Scalars['Date']>;
  addresses: Array<GQLUserAddress>;
  defaultAddress?: Maybe<GQLUserAddress>;
  /** @deprecated Field no longer supported */
  created_at: Scalars['DateTime'];
  createdAt: Scalars['DateTimeTz'];
  /** @deprecated Field no longer supported */
  updated_at: Scalars['DateTime'];
  updatedAt: Scalars['DateTimeTz'];
  roles: Array<GQLRole>;
  orders?: Maybe<GQLOrderPaginator>;
  favourites?: Maybe<GQLProductPaginator>;
};


export type GQLUserOrdersArgs = {
  orderBy?: Maybe<Array<GQLUserOrdersOrderByOrderByClause>>;
  filter?: Maybe<GQLOrdersFilter>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type GQLUserFavouritesArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type GQLUserAddress = {
  __typename?: 'UserAddress';
  id: Scalars['ID'];
  default: Scalars['Boolean'];
  base: Scalars['String'];
  apartment?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  intercom?: Maybe<Scalars['String']>;
};

export type GQLUserAddressInput = {
  id?: Maybe<Scalars['ID']>;
  default: Scalars['Boolean'];
  base: Scalars['String'];
  apartment?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  intercom?: Maybe<Scalars['String']>;
};

export enum GQLUserFavouriteAction {
  Add = 'add',
  Remove = 'remove'
}

export type GQLUserFavouriteInput = {
  productId: Scalars['ID'];
  action: GQLUserFavouriteAction;
};

export type GQLUserFilterInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<GQLRole>>;
};

export type GQLUserInput = {
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  addresses: Array<GQLUserAddressInput>;
};

/** Allowed column names for the `orderBy` argument on field `orders` on type `User`. */
export enum GQLUserOrdersOrderByColumn {
  StatusId = 'STATUS_ID',
  CreatedAt = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `orders`. */
export type GQLUserOrdersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: GQLUserOrdersOrderByColumn;
  /** The direction that is used for ordering. */
  order: GQLSortOrder;
};

export type GQLUsersPaginator = {
  __typename?: 'UsersPaginator';
  paginatorInfo: GQLPaginatorInfo;
  data: Array<GQLUser>;
};

export enum GQLValidationCode {
  Validation = 'validation'
}
