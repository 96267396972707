import { clone } from 'ramda'

export const filterStringArray = filterArray<string>()

function filterArray<T>() {
  return function (arr: Array<T | undefined>): T[] {
    return arr.filter((el) => Boolean(el)) as T[]
  }
}

// https://ramdajs.com/docs/#clone
export function deepCopy<T>(obj: T): T {
  return clone<T>(obj)
}
