import {
  ControlItemLinkProps as LinkProps,
  ControlItemDesktopButtonProps as ButtonProps,
} from './types'

import styles from './control-item.css?module'
import { Component, Prop, VNode, VueComponent } from '~/types/vue-ts-component'

@Component
class ControlItemLink extends VueComponent<LinkProps> {
  @Prop() readonly icon!: LinkProps['icon']
  @Prop() readonly label!: LinkProps['label']
  @Prop() readonly link!: LinkProps['link']
  @Prop() readonly counter!: LinkProps['counter']

  render(): VNode {
    return (
      <nuxtLink to={this.link}>
        <div class={styles.controlsItem}>
          <img src={this.icon} />
          <div class={styles.label}>{this.label}</div>
          {Boolean(this.counter) && (
            <span class={styles.counter}>{this.counter}</span>
          )}
        </div>
      </nuxtLink>
    )
  }
}

@Component
class ControlItemDesktopButton extends VueComponent<ButtonProps> {
  @Prop() readonly icon!: ButtonProps['icon']
  @Prop() readonly label!: ButtonProps['label']
  @Prop() readonly counter!: ButtonProps['counter']
  @Prop() readonly whenClick!: ButtonProps['whenClick']

  render(): VNode {
    return (
      <div class={styles.controlsItem} onClick={this.whenClick}>
        <img src={this.icon} />
        <div class={styles.label}>{this.label}</div>
        {Boolean(this.counter) && (
          <span class={styles.counter}>{this.counter}</span>
        )}
      </div>
    )
  }
}

export class ControlItem {
  static Link = ControlItemLink
  static Button = ControlItemDesktopButton
}
