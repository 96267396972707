// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_Lw1WO .input_2kbu5{width:100%;height:48px;border:1px solid #dee0e7;box-sizing:border-box;padding:.375rem .75rem;font-size:14px;line-height:20px;color:#222;border-radius:0}.wrapper_Lw1WO{width:100%}@media (min-width:768px){.wrapper_Lw1WO .input_2kbu5{height:56px;font-size:16px;line-height:24px}}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_Lw1WO",
	"input": "input_2kbu5"
};
module.exports = exports;
