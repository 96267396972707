import {
  HeaderDesktopInfo,
  HeaderDesktopMain,
  HeaderDesktopNavigation,
} from './components'

import styles from './header-desktop.css?module'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { Link } from '~/entities/link'
import { createLinkFromCategoryTreeType } from '~/utils/create-link'
import { RootModule, useStore } from '~/store/root'

@Component
export class HeaderDesktop extends VueComponent {
  store = useStore<RootModule>(this.$store)

  get navItems(): Link[] {
    return (
      this.store.categoryTree.menuCategoryTree.map((item) =>
        createLinkFromCategoryTreeType(item)
      ) || []
    )
  }

  render(): VNode {
    return (
      <div class={styles.headerDesktop}>
        <HeaderDesktopInfo />
        <HeaderDesktopMain />
        <HeaderDesktopNavigation items={this.navItems} />
      </div>
    )
  }
}
