import Vue from 'vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

export enum AllowedFloatingVueTheme {
  Tooltip = 'tooltip',
  Dropdown = 'dropdown',
  Menu = 'menu',
  FiltersDropdown = 'filters-dropdown',
}

interface Theme {
  $extend?: AllowedFloatingVueTheme
  [key: string]: string[] | string | boolean | number | undefined
}

const filtersDropdownTheme: Theme = {
  $extend: AllowedFloatingVueTheme.Dropdown,
  triggers: ['click'],
  autoHide: true,
  placement: 'bottom',
  flip: false,
}

const CUSTOM_THEMES = {
  [AllowedFloatingVueTheme.FiltersDropdown]: filtersDropdownTheme,
}

Object.keys(CUSTOM_THEMES).forEach(async (theme) => {
  await import(`~/assets/styles/floating-vue/${theme}.theme.css`)
})

Vue.use(FloatingVue, {
  themes: CUSTOM_THEMES,
})
