import { inject } from 'inversify'
import {
  AppImageFactoryInterface,
  Factory,
  ISeoFactory,
} from '~/abstracts/factory'
import { GQLProduct } from '~/types/gql'
import { ProductData } from '~/entities/product-data'
import { centsToPrice } from '~/utils/format-price'
import { Link } from '~/entities/link'
import { breadcrumbsReducer } from '~/utils/breadcrumbs-reducer'
import { AppImageInterface } from '~/app/image/app-image.interface'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'

export class ProductDataFactory extends Factory<GQLProduct, ProductData> {
  seoFactory: ISeoFactory

  constructor(@inject(DiTypes.SEO_FACTORY) seoFactory: ISeoFactory) {
    super()
    this.seoFactory = seoFactory
  }

  public create(input: GQLProduct): ProductData {
    return {
      ID: input.id,
      name: input.name,
      sku: input.sku,
      slug: input.slug,
      description: input.description,
      price: centsToPrice(input.price),
      images: this.getImages(input.images),
      country: input.country?.name ?? '',
      manufacturer: input.manufacturer?.name ?? '',
      breadcrumbs: this.getProductBreadcrumbs(input),
      seo: this.seoFactory.create(input.seo),
      instruction: input.instruction ?? [],
      inFavourite: input.meta.inFavourite,
      ingredient: input.ingredient?.name,
      brand: this.getProductBrand(input.brand),
      oldPrice: input.oldPrice,
    }
  }

  private getProductBreadcrumbs({ category, name, slug }: GQLProduct): Link[] {
    if (!category) return []

    const links: Link[] = []

    category.ancestors.forEach(({ name, slug }) => {
      const ancestorLink: Link = { name, slug }
      links.push(ancestorLink)
    })

    links.push({ name: category.name, slug: category.slug })
    links.push({ name, slug })

    return links.reduce(breadcrumbsReducer, [])
  }

  private getImages(productImages: GQLProduct['images']): AppImageInterface[] {
    return container
      .get<AppImageFactoryInterface>(DiTypes.APP_IMAGE_FACTORY)
      .fromArray(productImages.map(({ image }) => image))
  }

  private getProductBrand(gqlBrand: GQLProduct['brand']): ProductData['brand'] {
    if (!gqlBrand) return

    return {
      name: gqlBrand.name,
      slug: gqlBrand.slug,
    }
  }
}
