import { injectable } from 'inversify'
import {
  CategoryFiltersRepositoryInterface,
  Repository,
} from '~/abstracts/repository'
import { GQLProductFilterInput, GQLQuery } from '~/types/gql'

// TODO: удалить после https://bosco.myjetbrains.com/youtrack/issue/BP-703
@injectable()
export class CategoryFiltersRepository
  extends Repository
  implements CategoryFiltersRepositoryInterface {
  public async getIngredients(): Promise<string[]> {
    const { data } = await this.apollo.client.query<{
      Ingredients: GQLQuery['Ingredients']
    }>({
      query: await import('~/graphql/category-filters/ingredients.graphql'),
    })

    return data.Ingredients.map(({ name }) => name)
  }

  public async getManufacturers(): Promise<string[]> {
    const { data } = await this.apollo.client.query<{
      Manufacturers: GQLQuery['Manufacturers']
    }>({
      query: await import('~/graphql/category-filters/manufacturers.graphql'),
    })

    return data.Manufacturers.map(({ name }) => name)
  }

  public async getCountries(): Promise<string[]> {
    const { data } = await this.apollo.client.query<{
      Countries: GQLQuery['Countries']
    }>({
      query: await import('~/graphql/category-filters/countries.graphql'),
    })

    return data.Countries.map(({ name }) => name)
  }

  public async getBrands(): Promise<string[]> {
    const { data } = await this.apollo.client.query<{
      Brands: GQLQuery['Brands']
    }>({
      query: await import('~/graphql/category-filters/brands.graphql'),
    })

    return data.Brands.map(({ name }) => name)
  }

  public async getFilteredProductsCount(
    categoryId: number,
    filter: GQLProductFilterInput
  ): Promise<number> {
    const { data } = await this.apollo.client.query<
      {
        Category: GQLQuery['Category']
      },
      { categoryId: number; filter: GQLProductFilterInput }
    >({
      query: await import(
        '~/graphql/category-filters/filtered-porducts-count.graphql'
      ),
      variables: {
        categoryId,
        filter,
      },
    })

    return data.Category?.allProducts.paginatorInfo.total || 0
  }
}
