import { injectable } from 'inversify'
import {
  Factory,
  ProductFilterInputFactoryInterface,
} from '~/abstracts/factory'
import {
  GQLProductFilterInput,
  GQLProductPriceFilter,
  GQLProductsFilter,
} from '~/types/gql'
import { priceToCents } from '~/utils/format-price'

export type FilterParam =
  | GQLProductsFilter.Brand
  | GQLProductsFilter.Ingredient
  | GQLProductsFilter.Country
  | GQLProductsFilter.Manufacturer
  | 'priceFrom'
  | 'priceTo'

@injectable()
export class ProductFilterInputFactory
  extends Factory<
    Partial<Record<GQLProductsFilter, string[]>>,
    GQLProductFilterInput
  >
  implements ProductFilterInputFactoryInterface {
  public create(input: Record<FilterParam, string[]>): GQLProductFilterInput {
    return {
      country: input[GQLProductsFilter.Country],
      brand: input[GQLProductsFilter.Brand],
      manufacturer: input[GQLProductsFilter.Manufacturer],
      ingredient: input[GQLProductsFilter.Ingredient],
      price: ProductFilterInputFactory.getPriceFilter({
        priceFrom: input.priceFrom,
        priceTo: input.priceTo,
      }),
    }
  }

  static getPriceFilter({
    priceFrom,
    priceTo,
  }: {
    priceFrom?: string[]
    priceTo?: string[]
  }): GQLProductPriceFilter | undefined {
    return {
      min: priceFrom ? priceToCents(Number(priceFrom)) : undefined,
      max: priceTo ? priceToCents(Number(priceTo)) : undefined,
    }
  }
}
