import { EcommerceProductItem } from '~/app/ecommerce/ecommerce.types'
import { OrderItem } from '~/entities/order-item'

export const getEcommerceProducts = (
  items: EcommerceProductItem[]
): Array<{ [key: string]: string | number | undefined }> => {
  return items.map(({ product, quantity }) => ({
    ...product,
    quantity,
  }))
}

export const ecommerceProductItemsFromOrderItems = (
  orderItems: OrderItem[]
): EcommerceProductItem[] => {
  return orderItems.map(({ qty, product }) => ({
    quantity: qty,
    product: {
      id: product.ID,
      name: product.name,
      price: product.price,
    },
  }))
}
