import { BrandData } from '~/features/brand'
import { Mapper } from '~/repositories/types'
import { GQLQuery } from '~/types/gql'
import { cndLink } from '~/utils/cdn'

type Input = GQLQuery['Brand']

export const getBrandDataOutput: Mapper<Input, BrandData> = (gqlBrand) => {
  return {
    id: gqlBrand.id,
    name: gqlBrand.name,
    slug: gqlBrand.slug,
    title: gqlBrand.title,
    description: gqlBrand.description,
    logo: gqlBrand.logo ? cndLink('/' + gqlBrand.logo) : undefined,
    banner: gqlBrand.banner ? cndLink('/' + gqlBrand.banner) : undefined,
  }
}
