import { FooterMobile } from './footer-mobile'
import { FooterDesktop } from './footer-desktop'

import styles from './footer.css?module'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { RootModule, useStore } from '~/store/root'

@Component
export class AppFooter extends VueComponent {
  store = useStore<RootModule>(this.$store)

  render(): VNode {
    return (
      <footer class={styles.footer}>
        <img
          src={require('~/assets/icons/tree.svg')}
          class={[styles.illustration, styles.illustrationMobile]}
        />
        <img
          src={require('~/assets/icons/tree-desktop.svg')}
          class={[styles.illustration, styles.illustrationDesktop]}
        />
        <FooterMobile class={styles.mobile} />
        <FooterDesktop class={styles.desktop} />
      </footer>
    )
  }
}
