import { Operation } from 'apollo-link'
import { GraphQLError } from 'graphql'
import { Breadcrumb, Severity } from '@sentry/types'
import { ServerError } from '@apollo/client/link/utils'
import { ServerParseError } from '@apollo/client/link/http'
import { container } from '~/config/invercify'
import { ILogger } from '~/app/logger'
import DiTypes from '~/config/DiTypes'
import {
  geBreadcrumb,
  getTagsFromExtensions,
} from '~/api/GraphqlClient/links/error-link/error.utils'

export const graphqlErrorHandler = (
  operation: Operation,
  errors: ReadonlyArray<GraphQLError>
) => {
  errors.forEach((error) => {
    const logger = container.get<ILogger>(DiTypes.LOGGER)
    const breadcrumb: Breadcrumb = geBreadcrumb(operation, error)

    logger.withScope(
      (scope) => {
        const tags = getTagsFromExtensions(error.extensions)
        tags &&
          Object.entries(tags).forEach(([key, value]) => {
            scope.setTag(key, value)
          })

        logger.log(error.message, Severity.Error)
      },
      [breadcrumb]
    )
  })
}

export const networkErrorHandler = (
  error: Error | ServerError | ServerParseError
) => {
  container.get<ILogger>(DiTypes.LOGGER).error(JSON.stringify(error))
}
