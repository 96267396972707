import { Factory, IOrderPaymentFactory } from '~/abstracts/factory'
import { GQLOrderPayment } from '~/types/gql'
import { OrderPayment } from '~/features/order/order-payment/order-payment.interface'

export class OrderPaymentFactory
  extends Factory<GQLOrderPayment, OrderPayment>
  implements IOrderPaymentFactory {
  create({ name, code }: GQLOrderPayment): OrderPayment {
    return {
      name,
      code,
    }
  }
}
