import { injectable } from 'inversify'
import { IFbq, FbStandardEvent } from '~/app/fbq/fbq.interface'
import { installFbq } from '~/app/fbq/utils/fbq-installer'

@injectable()
export class Fbq implements IFbq {
  constructor() {
    installFbq()
  }

  private isBbqEnabled(): boolean {
    return typeof window !== 'undefined' && typeof window.fbq !== 'undefined'
  }

  private query(...args: [string, ...any[]]): void {
    if (!this.isBbqEnabled()) return
    window.fbq(...args)
  }

  public init(appId: string): void {
    if (!this.isBbqEnabled()) return
    this.query('init', appId)
  }

  public track(name: FbStandardEvent, data: object | undefined): void {
    this.query('track', name, data)
  }
}
