import { inject } from 'inversify'
import { ISeoFactory } from '~/abstracts/factory'
import { Repository } from '~/abstracts/repository'
import type { Apollo } from '~/api/GraphqlClient'
import DiTypes from '~/config/DiTypes'
import { Brand, BrandData, IBrandRepository } from '~/features/brand'
import { GQLQuery } from '~/types/gql'
import { getBrandOutput } from './brand'
import { getBrandDataOutput } from './brand-data'

export class BrandRepository extends Repository implements IBrandRepository {
  seoFactory: ISeoFactory

  constructor(
    @inject(DiTypes.SEO_FACTORY) seoFactory: ISeoFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.seoFactory = seoFactory
  }

  public async getBrandData(slug: string) {
    const { data, errors } = await this.apollo.client.query<{
      Brand: GQLQuery['Brand']
    }>({
      query: await import('~/graphql/brand/brand-data.graphql'),
      variables: {
        slug,
      },
    })

    if (errors?.length) {
      throw new Error(`Brand with slug ${slug} does not exist`)
    }

    return {
      brandData: getBrandDataOutput(data.Brand),
      seo: this.seoFactory.create(data.Brand.seo)
    }
  }

  public async getBrands() {
    const { data } = await this.apollo.client.query<{Brands: GQLQuery['Brands']}>({
      query: await import('~/graphql/brand/brands.graphql')
    })

    return getBrandOutput(data.Brands)
  }
}
