import { Action } from 'vuex-simple'
import { UserRepositoryInterface } from '~/abstracts/repository'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { IFbq } from '~/app/fbq'

export class ProductModule {
  private userRepository: UserRepositoryInterface

  constructor() {
    this.userRepository = container.get(DiTypes.USER_REPOSITORY)
  }

  @Action()
  async addProductToFavourite(productID: string): Promise<boolean> {
    const success = await this.userRepository.favouriteAdd(productID)
    success && container.get<IFbq>(DiTypes.FBQ).track('AddToWishlist')
    return success
  }

  @Action()
  async removeProductFromFavourite(productID: string): Promise<boolean> {
    return await this.userRepository.favouriteRemove(productID)
  }
}
