import { inject, injectable } from 'inversify'
import {
  Factory,
  OrderItemFactoryInterface,
  UserOrderFactoryInterface,
} from '~/abstracts/factory'
import { GQLOrder, GQLOrderItem } from '~/types/gql'
import { UserOrder } from '~/features/user/user-order/user-order.interface'
import { centsToPrice } from '~/utils/format-price'
import DiTypes from '~/config/DiTypes'

@injectable()
export class UserOrderFactory
  extends Factory<GQLOrder, UserOrder>
  implements UserOrderFactoryInterface {
  constructor(
    @inject(DiTypes.ORDER_ITEM_FACTORY)
    private orderItemFactory: OrderItemFactoryInterface
  ) {
    super()
  }

  create(input: GQLOrder): UserOrder {
    return {
      number: input.orderNumber,
      date: new Date(input.createdAt),
      amount: centsToPrice(input.amount),
      discount: this.getDiscount(input.items),
      shipping: {
        name: input.shipping.name,
        code: input.shipping.code,
        cost: centsToPrice(input.shipping.cost),
      },
      items: this.orderItemFactory.fromArray(input.items),
    }
  }

  // TEMP: перенести на backend
  private getDiscount(items: GQLOrderItem[]): number {
    return items.reduce<number>((discount, item) => {
      return item.oldPrice ? (discount += item.oldPrice - item.price) : discount
    }, 0)
  }
}
