import { AuthorizationFormLayout } from '../authorization-form-layout'
import styles from './authorization-code-form.css?module'
import { AuthorizationCodeFormProps as Props } from './types'
import {
  Component,
  VueComponent,
  Prop,
  VNode,
  Ref,
} from '~/types/vue-ts-component'
import { FormInputCode } from '~/components/UI/form/form-input-code'
import { AppButton } from '~/components/UI/ui-kit'

const RESEND_TIMEOUT = 60

@Component
export class AuthorizationCodeForm extends VueComponent<Props> {
  @Prop() readonly phone!: Props['phone']

  @Prop() readonly moveBackwards!: Props['moveBackwards']

  @Prop() readonly resendCode!: Props['resendCode']

  @Prop({ default: false }) readonly codeIncorrect!: Props['codeIncorrect']

  @Prop() readonly whenSubmit!: Props['whenSubmit']

  @Ref('codeInput')
  codeInput!: FormInputCode

  code = ''

  seconds = RESEND_TIMEOUT

  interval: NodeJS.Timeout | null = null

  get canResendCode(): boolean {
    return this.seconds === 0
  }

  countHandler(): void {
    if (this.seconds === 0) {
      return this.clearInterval()
    }
    this.seconds--
  }

  updateCode(code: string): void {
    this.code = code
  }

  handleBackwards(e: Event): void {
    e.preventDefault()
    this.moveBackwards()
  }

  setInterval(): void {
    this.interval = setInterval(this.countHandler, 1000)
  }

  resetInterval(): void {
    this.clearInterval()
    this.seconds = RESEND_TIMEOUT
    this.setInterval()
  }

  clearInterval(): void {
    this.interval && clearInterval(this.interval)
  }

  handleResend(e: Event): void {
    e.preventDefault()
    this.resendCode(this.phone)
    this.resetInterval()
  }

  handleSubmit(): void {
    if (!this.codeInput.validateCode()) return
    this.whenSubmit(this.code)
  }

  mounted(): void {
    this.setInterval()
  }

  beforeDestroy(): void {
    this.clearInterval()
  }

  render(): VNode {
    return (
      <AuthorizationFormLayout heading="Введите код">
        <div>
          <p class={styles.content}>
            Мы отправили код подтверждения
            <br /> на номер {this.phone}
            <a href="#" class={styles.link} onClick={this.handleBackwards}>
              Изменить
            </a>
          </p>
          <FormInputCode
            ref="codeInput"
            code={this.code}
            whenChange={this.updateCode}
            errorMessage={
              this.codeIncorrect
                ? 'Неверный код. Попробуйте ещё раз'
                : undefined
            }
          />
          {this.canResendCode ? (
            <a
              href="#"
              class={[styles.link, styles.resendLink]}
              onClick={this.handleResend}
            >
              Получить новый код
            </a>
          ) : (
            <p class={styles.resend}>
              Получить новый код можно через {this.seconds}
            </p>
          )}
        </div>
        <AppButton whenClick={this.handleSubmit}>Войти</AppButton>
      </AuthorizationFormLayout>
    )
  }
}
