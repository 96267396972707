import { createMask } from 'imask'

/**
 * Преобразование телефона в инпут
 * @param phone - пример: +7 (926) 111-11-11
 * @returns input - пример: 79261111111
 */
export const phoneToInput = (phone: string): string => {
  return phone.replace(/\D+/g, '')
}

const phoneMasker = createMask({
  mask: '+7 (000) 000-00-00',
})

export const inputToPhone = (input: string): string => {
  return phoneMasker.resolve(input.replace(/^8/, '+7'))
}
