// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3goJj{position:relative;width:100%;display:flex}.input_KkA3z{flex:1;border-radius:27px;background-color:var(--color-lightest-gray);padding:8px 12px;font-size:14px;line-height:18px;color:var(--color-darkest-dark)}.input_KkA3z.withIcon_2zAw8{padding-left:30px}.icon_3lNDD{position:absolute;top:50%;left:0;width:16px;height:16px;transform:translate(50%,-50%)}@media (min-width:941px){.input_KkA3z{font-size:16px;line-height:24px;padding-left:24px}}", ""]);
// Exports
exports.locals = {
	"container": "container_3goJj",
	"input": "input_KkA3z",
	"withIcon": "withIcon_2zAw8",
	"icon": "icon_3lNDD"
};
module.exports = exports;
