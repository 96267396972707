// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".searchContainer_2XcP3{width:100%;position:relative}.searchInput_2_Nw5{z-index:3}.searchButton_3D51-{display:none}.searchResult_1eWkB{position:absolute;top:0;left:0;width:100%;border-radius:25px 25px 0 0;border:1px solid var(--color-fog-gray);box-shadow:0 2px 4px rgba(0,0,0,.05);z-index:2}.hints_53Ghl{margin-top:50px!important;padding:0}.hintItem_d57we{padding:14px 10px;font-size:16px;color:var(--color-darkest-dark);background-color:#fff}.hintItem_d57we:hover{background-color:var(--color-lightest-gray)}@media (min-width:1440px){.searchButton_3D51-{background-color:var(--color-ripe-plum);padding:12px;border-radius:50%;display:flex;justify-content:center;align-items:center;position:absolute;top:0;right:0;z-index:3;cursor:pointer;transition:background-color .2s}.searchButton_3D51-:hover{background-color:var(--color-tiffany-cactus)}}", ""]);
// Exports
exports.locals = {
	"searchContainer": "searchContainer_2XcP3",
	"searchInput": "searchInput_2_Nw5",
	"searchButton": "searchButton_3D51-",
	"searchResult": "searchResult_1eWkB",
	"hints": "hints_53Ghl",
	"hintItem": "hintItem_d57we"
};
module.exports = exports;
