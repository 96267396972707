import styles from './input.css?module'
import { Component, Prop, VueComponent, VNode } from '~/types/vue-ts-component'

interface InputEventTarget {
  target: {
    value: string
  }
}

interface Props {
  text: string | null
  textUpdate: (value: string) => void
  icon?: string
  placeholder?: string
  whenEnterPressed?: () => void
}

@Component
export class AppInput extends VueComponent<Props> {
  @Prop() readonly icon!: Props['icon']
  @Prop() readonly text!: Props['text']
  @Prop() readonly placeholder!: Props['placeholder']
  @Prop() readonly textUpdate!: Props['textUpdate']
  @Prop() readonly whenEnterPressed!: Props['whenEnterPressed']

  get inputClassList(): string[] {
    const classList = [styles.input]
    this.icon && classList.push(styles.withIcon)
    return classList
  }

  handleKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      // eslint-disable-next-line no-unused-expressions
      this.whenEnterPressed?.()
    }
  }

  render(): VNode {
    return (
      <div class={styles.container}>
        {this.icon && <img src={this.icon} class={styles.icon} />}
        <input
          type="text"
          value={this.text}
          class={this.inputClassList}
          placeholder={this.placeholder}
          on={{
            input: (e: InputEventTarget) => this.textUpdate(e.target.value),
            focus: (e: FocusEvent) => this.$emit('focus', e),
            keydown: this.handleKeyDown,
          }}
        />
      </div>
    )
  }
}
