import styles from './authorization-form-error.scss?module'
import { AuthorizationFormErrorProps as Props } from './authorization-form-error.types'
import { Component, Prop, VueComponent, VNode } from '~/types/vue-ts-component'
import { AppButton } from '~/components/UI/ui-kit'
import { COMPANY_CONTACTS } from '~/config/company-contacts'

@Component
export class AuthorizationFormError extends VueComponent<Props> {
  @Prop() readonly whenClick!: Props['whenClick']

  render(): VNode {
    return (
      <div class={styles.wrapper}>
        <img src={require('~/assets/icons/500.svg')} />
        <h2 class={styles.heading}>Что-то пошло не так</h2>
        <p class={styles.content}>
          Попробуйте зайти к нам чуть позднее или свяжитесь с нами по телефону:
          <br />
          {COMPANY_CONTACTS.phone.main}
        </p>
        <AppButton class={styles.action} whenClick={this.whenClick}>
          Окей
        </AppButton>
      </div>
    )
  }
}
