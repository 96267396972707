import { injectable } from 'inversify'
import { Factory, UserAddressFactoryInterface } from '~/abstracts/factory'
import { GQLUserAddress } from '~/types/gql'
import { UserAddress } from '~/features/user/user-address/user-address.inteface'

@injectable()
export class UserAddressFactory
  extends Factory<GQLUserAddress, UserAddress>
  implements UserAddressFactoryInterface {
  public create(input: GQLUserAddress): UserAddress {
    return {
      ID: input.id,
      default: input.default,
      base: input.base,
      apartment: input.apartment || '',
      entrance: input.entrance || '',
      floor: input.floor || '',
      intercom: input.intercom || '',
    }
  }
}
