import { CategoryTreeType } from '~/entities/category-tree'
import { GQLCategoryFlag } from '~/types/gql'

/**
 * Возвращает редьюсер для фильтрации дерева по флагу нод
 * @param flag
 */
const filterTreeNodesByFlag = (flag: GQLCategoryFlag) => {
  const reducer = (
    result: CategoryTreeType[],
    node: CategoryTreeType
  ): CategoryTreeType[] => {
    if (!node.data.flags.includes(flag)) return result
    if (node.children?.length) {
      const children = node.children.reduce(reducer, [])
      result.push({ ...node, children })
    } else {
      result.push(node)
    }

    return result
  }
  return reducer
}

/**
 * Возвращает редьюсер для поиска в дереве по флагу
 * @param flag
 */
const findTreeNodesByFlag = (
  flag: GQLCategoryFlag
): ((
  result: CategoryTreeType[],
  node: CategoryTreeType
) => CategoryTreeType[]) => {
  const flatterNode = (node: CategoryTreeType): CategoryTreeType => ({
    ...node,
    children: [],
  })

  const reducer = (result: CategoryTreeType[], node: CategoryTreeType) => {
    if (!node.data.flags.includes(flag) && !node.children?.length) {
      return result
    }

    const children: CategoryTreeType[] = node.children?.length
      ? node.children.reduce(reducer, [])
      : []

    node.data.flags.includes(flag) && result.push(flatterNode(node))

    result.push(...children.map(flatterNode))

    return result
  }
  return reducer
}

const findTreeNodesByPath = (
  path: string
): ((
  result: CategoryTreeType[],
  node: CategoryTreeType
) => CategoryTreeType[]) => {
  const reducer = (result: CategoryTreeType[], node: CategoryTreeType) => {
    if (node.data.path !== path && !node.children?.length) {
      return result
    }

    const children: CategoryTreeType[] = node.children?.length
      ? node.children.reduce(reducer, [])
      : []

    node.data.path === path && result.push(node)

    result.push(...children)

    return result
  }
  return reducer
}

export const filterByFlag = (
  categoryTree: CategoryTreeType[],
  flag: GQLCategoryFlag
): CategoryTreeType[] => {
  return categoryTree.reduce(filterTreeNodesByFlag(flag), [])
}

/**
 * Осуществляет поиск по дереву
 * @param categoryTree - массив нод дерева категорий
 * @param flag - флаг для поиска
 * @returns одномерный массив нод дерева
 */
export const findByTag = (
  categoryTree: CategoryTreeType[],
  flag: GQLCategoryFlag
): CategoryTreeType[] => categoryTree.reduce(findTreeNodesByFlag(flag), [])

export const findByPath = (
  categoryTree: CategoryTreeType[],
  path: string
): CategoryTreeType | undefined =>
  categoryTree.reduce(findTreeNodesByPath(path), [])[0]
