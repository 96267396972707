// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer_2OUli{display:grid;grid-template-columns:[company-start] 200px [company-end] 1fr [nav-start] auto [nav-end] 1fr [social-start] 200px [social-end];padding-top:60px;padding-bottom:60px;z-index:1;position:relative}.company_3pOmP{grid-column:company-start/company-end}.company_3pOmP .logo_1GWTe{width:168px;height:40px;margin-bottom:24px}.company_3pOmP .copyright_2mUEv{font-size:14px;line-height:18px;color:var(--color-ash-gray)}.navigation_1UxmZ{display:grid;grid-column:nav-start/nav-end;grid-template-columns:repeat(2,auto);grid-column-gap:65px;-moz-column-gap:65px;column-gap:65px;font-size:16px;line-height:32px;color:var(--color-mystery-gray);white-space:nowrap}.social_1oNrm{grid-column:social-start/social-end}.socialIcons_RQBqp{display:grid;grid-template-columns:-webkit-max-content -webkit-max-content;grid-template-columns:max-content max-content;grid-column-gap:12px;-moz-column-gap:12px;column-gap:12px;margin-top:18px}@media (min-width:1440px){.navigation_1UxmZ{grid-template-columns:repeat(3,auto)}}", ""]);
// Exports
exports.locals = {
	"footer": "footer_2OUli",
	"company": "company_3pOmP",
	"logo": "logo_1GWTe",
	"copyright": "copyright_2mUEv",
	"navigation": "navigation_1UxmZ",
	"social": "social_1oNrm",
	"socialIcons": "socialIcons_RQBqp"
};
module.exports = exports;
