import { GQLCartItem } from '~/types/gql'
import { CartItem } from '~/features/cart/cart-item.interface'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { ProductFactoryInterface } from '~/features/product/product-factory'

export const cartItemMapper = (gqlCartItem: GQLCartItem): CartItem => {
  return {
    qty: gqlCartItem.quantity,
    product: container
      .get<ProductFactoryInterface>(DiTypes.PRODUCT_FACTORY)
      .create(gqlCartItem.product),
  }
}
