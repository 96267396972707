// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/icons/close-but.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper_fnG79{position:fixed;width:100%;height:100%;top:0;left:0;z-index:999;display:flex;justify-content:center;align-items:center;background:rgba(180,186,203,.8)}.block_1w4jY{--block-width:720px;min-width:416px;max-width:var(--block-width);height:auto;min-height:240px;padding:34px;background:#fff;box-sizing:border-box;box-shadow:0 2px 4px rgba(58,62,70,.1);overflow:hidden;grid-template-rows:auto 1fr auto;grid-row-gap:16px;row-gap:16px}.block_1w4jY,.heading_2N3Yj{width:100%;display:grid}.heading_2N3Yj{grid-template-columns:1fr auto;grid-column-gap:16px;-moz-column-gap:16px;column-gap:16px;margin-bottom:auto}.content_1f6cq,.heading_2N3Yj{align-items:center}.content_1f6cq{flex:1;display:flex;justify-content:stretch}.title_1qv-c:first-letter{text-transform:capitalize}.closeIcon_3y5BZ{grid-column:2/3;width:21px;height:21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-repeat:no-repeat;transition:all .3s;cursor:pointer}.closeIcon_3y5BZ:hover{opacity:.75}.footer_dljOq{margin-top:auto}@media (max-width:768px){.wrapper_fnG79{background-color:#fff;align-items:flex-start}.block_1w4jY{width:100vw;min-width:auto;max-width:unset;height:90vh;padding:42px 16px;grid-row-gap:8px;row-gap:8px;box-shadow:none}.closeIcon_3y5BZ{grid-column:1/2;justify-self:start}.title_1qv-c{display:none}}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_fnG79",
	"block": "block_1w4jY",
	"heading": "heading_2N3Yj",
	"content": "content_1f6cq",
	"title": "title_1qv-c",
	"closeIcon": "closeIcon_3y5BZ",
	"footer": "footer_dljOq"
};
module.exports = exports;
