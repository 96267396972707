import { injectable } from 'inversify'
import { MetaInfo } from 'vue-meta'
import {
  GQLCategoryTree,
  GQLOrderInput,
  GQLProduct,
  GQLImage,
  GQLUserAddress,
  GQLUser,
  GQLUserInput,
  GQLUserAddressInput,
  GQLOrderItem,
  GQLOrder,
  GQLProductFilterInput,
  GQLOrderItemInput,
  GQLOrderPayment,
  GQLBanner,
  GQLSeo,
} from '~/types/gql'
import { ProductData } from '~/entities/product-data'
import { CheckoutForm } from '~/entities/checkout-form'
import { CategoryTreeType } from '~/entities/category-tree'
import { AppImageInterface } from '~/app/image/app-image.interface'
import { UserAddress } from '~/features/user/user-address/user-address.inteface'
import { User } from '~/features/user/user.interface'
import { OrderItem } from '~/features/order/order-item/order-item.interface'
import { UserOrder } from '~/features/user/user-order/user-order.interface'
import { FilterParam } from '~/features/product/product-filter/product-filter-input.factory'
import { OrderPayment } from '~/features/order/order-payment/order-payment.interface'
import { Seo } from '~/features/seo'
import { Banner } from '~/features/banner/banner.interface'
import { MetaInput } from '~/app/meta-info/meta-info.factory'

/**
 * Фабрика используется для приведения данных из api к локальному интерфейсу
 */
@injectable()
export abstract class Factory<T, B> {
  public abstract create(input: T): B

  public fromArray(input: T[]): B[] {
    return input.map((item) => this.create(item))
  }
}

export interface ProductDataFactoryInterface {
  create: (input: GQLProduct) => ProductData
  fromArray: (input: GQLProduct[]) => ProductData[]
}

export interface OrderInputFactoryInterface {
  create: (input: CheckoutForm) => GQLOrderInput
  createOrderItems: (items: OrderItem[]) => GQLOrderItemInput[]
}

export interface CategoryTreeFactoryInterface {
  create: (input: GQLCategoryTree[]) => CategoryTreeType[]
}

export interface AppImageFactoryInterface {
  create: (input?: GQLImage) => AppImageInterface
  fromArray: (input: GQLImage[]) => AppImageInterface[]
}

export interface UserAddressFactoryInterface {
  create: (input: GQLUserAddress) => UserAddress
  fromArray: (input: GQLUserAddress[]) => UserAddress[]
}

export interface UserFactoryInterface {
  create: (input: GQLUser) => User
}

export interface UserInputFactoryInterface {
  create: (input: User) => GQLUserInput
}

export interface UserAddressInputFactoryInterface {
  create: (input: UserAddress) => GQLUserAddressInput
  fromArray: (input: UserAddress[]) => GQLUserAddressInput[]
}

export interface OrderItemFactoryInterface {
  create: (input: GQLOrderItem) => OrderItem
  fromArray: (input: GQLOrderItem[]) => OrderItem[]
}

export interface UserOrderFactoryInterface {
  create: (input: GQLOrder) => UserOrder
  fromArray: (input: GQLOrder[]) => UserOrder[]
}

export interface ProductFilterInputFactoryInterface {
  create: (input: Record<FilterParam, string[]>) => GQLProductFilterInput
}

export interface IOrderPaymentFactory {
  create: (input: GQLOrderPayment) => OrderPayment
  fromArray: (input: GQLOrderPayment[]) => OrderPayment[]
}

export interface IBannerFactory {
  create: (input: GQLBanner) => Banner
  fromArray: (input: GQLBanner[]) => Banner[]
}

export interface ISeoFactory {
  create: (input: GQLSeo) => Seo
}

export interface IMetaInfoFactory {
  create: (input: MetaInput) => MetaInfo
}
