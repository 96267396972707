import { Link } from '~/entities/link'
import { CategoryTreeType } from '~/entities/category-tree'

export const createLinkFromCategoryTreeType = (
  category: CategoryTreeType,
  path?: string[]
): Link => ({
  name: category.text,
  slug: path?.length
    ? `/${path.join('/')}/${category.data.slug}`
    : category.data.slug,
})
