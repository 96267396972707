import styles from './popup.css?module'
import { PopupProps as Props } from './types'
import {
  Component,
  VueComponent,
  Prop,
  Watch,
  VNode,
} from '~/types/vue-ts-component'

@Component
export class Popup extends VueComponent<Props> {
  @Prop() readonly isShown!: Props['isShown']
  @Prop() readonly title!: Props['title']
  @Prop() readonly width!: Props['width']
  @Prop() readonly whenClose!: Props['whenClose']
  @Prop() readonly whenShow!: Props['whenShow']

  get blockStyles(): string | undefined {
    return this.width ? `--block-width: ${this.width}` : undefined
  }

  @Watch('isShown')
  handleIsShownChange(val: boolean, oldVal: boolean) {
    if (!oldVal && val) {
      // eslint-disable-next-line no-unused-expressions
      this.whenShow?.()
    }
  }

  render(): VNode {
    return this.isShown ? (
      <div class={styles.wrapper}>
        <div class={styles.block} style={this.blockStyles}>
          <div class={styles.heading}>
            {this.title && <h2 class={styles.title}>{this.title}</h2>}
            <div class={styles.closeIcon} onClick={this.whenClose} />
          </div>
          <div class={styles.content}>{this.$slots.default}</div>
          {this.$slots.footer && (
            <footer class={styles.footer}>{this.$slots.footer}</footer>
          )}
        </div>
      </div>
    ) : null
  }
}
