import { inject } from 'inversify'
import { OrderRepositoryInterface, Repository } from '~/abstracts/repository'
import {
  GQLMutation,
  GQLOrderInput,
  GQLOrderItemInput,
  GQLOrderPayInput,
  GQLPaymentForm,
  GQLPaymentStatus,
  GQLQuery,
  GQLUpdatePaymentStatusInput,
} from '~/types/gql'
import DiTypes from '~/config/DiTypes'
import { IOrderPaymentFactory } from '~/abstracts/factory'
import { Apollo } from '~/api/GraphqlClient'
import { OrderPayment } from '~/features/order/order-payment/order-payment.interface'

export class OrderRepository
  extends Repository
  implements OrderRepositoryInterface {
  private orderPaymentFactory: IOrderPaymentFactory

  constructor(
    @inject(DiTypes.ORDER_PAYMENT_FACTORY)
    orderPaymentFactory: IOrderPaymentFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.orderPaymentFactory = orderPaymentFactory
  }

  public async addOrder(input: GQLOrderInput): Promise<string> {
    const { data, errors } = await this.apollo.client.mutate<{
      OrderCreate: GQLMutation['OrderCreate']
    }>({
      mutation: await import('~/graphql/order-create.graphql'),
      variables: { input },
    })
    if (errors?.length || !data?.OrderCreate.orderNumber)
      throw new Error(`Ошибка оформления заказа`)

    return data.OrderCreate.orderNumber
  }

  public async orderPay(input: GQLOrderPayInput): Promise<GQLPaymentForm> {
    const { data } = await this.apollo.client.mutate<{
      OrderPay: GQLMutation['OrderPay']
    }>({
      mutation: await import('~/graphql/order-pay.graphql'),
      variables: { input },
    })

    if (!data?.OrderPay.formUrl) {
      throw new Error(
        `Не удалось получить ссылку для оплаты заказа ${input.order}`
      )
    }

    return data.OrderPay
  }

  public async orderUpdatePaymentStatus(
    input: GQLUpdatePaymentStatusInput
  ): Promise<GQLPaymentStatus> {
    const { data } = await this.apollo.client.mutate<{
      UpdatePaymentStatus: GQLMutation['UpdatePaymentStatus']
    }>({
      mutation: await import('~/graphql/order-update-payment-status.graphql'),
      variables: { input },
    })

    if (!data?.UpdatePaymentStatus.code)
      throw new Error(`Не удалось обновить статус оплаты`)

    return data?.UpdatePaymentStatus
  }

  public async getOrderPayments(
    input: GQLOrderItemInput[]
  ): Promise<OrderPayment[]> {
    const { data } = await this.apollo.client.query<
      { OrderPayments: GQLQuery['OrderPayments'] },
      { input: GQLOrderItemInput[] }
    >({
      query: await import('~/graphql/order/order-payments.graphql'),
      variables: {
        input,
      },
    })

    return this.orderPaymentFactory.fromArray(data.OrderPayments)
  }
}
