// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout_1lz3P{display:grid;grid-row-gap:16px;row-gap:16px;align-content:center;justify-items:center;height:460px}.content_338CF{display:contents}.content_338CF .header_212li{color:var(--color-fragrant-cherry)}.illustrationDesktop_36746{display:none}@media (min-width:941px){.layout_1lz3P{grid-template-columns:[content-start] 1fr [content-end image-start] 600px [image-end];grid-column-gap:40px;-moz-column-gap:40px;column-gap:40px;height:100%}.content_338CF{display:grid;grid-row-gap:24px;row-gap:24px;align-content:center;justify-items:start;font-size:16px;line-height:24px}.content_338CF .header_212li{font-size:120px;line-height:96px}.illustration_1QMtr{height:auto;width:600px}}", ""]);
// Exports
exports.locals = {
	"layout": "layout_1lz3P",
	"content": "content_338CF",
	"header": "header_212li",
	"illustrationDesktop": "illustrationDesktop_36746",
	"illustration": "illustration_1QMtr"
};
module.exports = exports;
