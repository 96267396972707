export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Outlined = 'outlined',
}

export enum ButtonInputType {
  'button' = 'button',
  'reset' = 'reset',
  'submit' = 'submit',
}

export enum ButtonSize {
  M = 'M',
  S = 'S',
}


export interface AppButtonProps {
  type?: ButtonType
  inputType?: ButtonInputType
  size?: ButtonSize
  outlined?: boolean
  small?: boolean
  whenClick?: (e?: Event) => void
}
