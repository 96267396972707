import styles from './container.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

interface Props {
  paddingY?: boolean
}

@Component
export class Container extends VueComponent<Props> {
  @Prop() readonly paddingY!: Props['paddingY']

  render(): VNode {
    return (
      <div class={[styles.container, this.paddingY && styles.paddingY]}>
        {this.$slots.default}
      </div>
    )
  }
}
