// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/home-slider-next.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../images/home-slider-next-mobile.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".swiper-button-next,.swiper-button-prev{position:absolute;top:50%;z-index:99}.swiper-button-prev{left:55px;transform:rotate(-180deg)}.swiper-button-next{right:55px}.swiper-button-next:hover,.swiper-button-prev:hover{cursor:pointer}.swiper-button-next:after,.swiper-button-prev:after{content:\"\";display:block;width:18px;height:48px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{opacity:0}.swiper-button-next__thumbs,.swiper-button-prev__thumbs{top:28px}.swiper-button-next__products,.swiper-button-next__thumbs{right:0}.swiper-button-prev__products,.swiper-button-prev__thumbs{left:0}.swiper-button-next__products,.swiper-button-prev__products{top:45%}.swiper-container.gallery-thumbs .swiper-slide:hover{cursor:pointer;opacity:.6;transition:opacity .3s}.swiper-container.gallery-thumbs .swiper-slide-active figure{border:1px solid #01a29d}.swiper-pagination-bullet{opacity:1;border:2px solid #b4bacb;box-sizing:border-box;background:transparent}.swiper-pagination-bullet-active{background:#b4bacb}@media (max-width:1200px){.swiper_prev{left:20px}.swiper_next{right:20px}}@media (max-width:940px){.swiper-button-next__thumbs,.swiper-button-prev__thumbs{top:20px;transform:translate(0)}.swiper-button-next__thumbs{right:20px}.swiper-button-prev__thumbs{left:20px;transform:rotate(-180deg)}.swiper-button-next,.swiper-button-prev{width:10px;height:22px}.swiper-button-next:after,.swiper-button-prev:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");width:10px;height:22px}}@media (max-width:768px){.swiper-button-next,.swiper-button-prev{display:none}}@media (max-width:520px){.swiper-container.products-carousel .swiper-slide{min-width:136px;max-width:136px}}", ""]);
// Exports
module.exports = exports;
