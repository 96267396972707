import { inject, injectable } from 'inversify'
import { Repository, UserRepositoryInterface } from '~/abstracts/repository'
import {
  GQLLoginBySmsInput,
  GQLMutation,
  GQLQuery,
  GQLUserFavouriteAction,
  GQLUserFavouriteInput,
  GQLUserInput,
} from '~/types/gql'
import { User } from '~/features/user/user.interface'
import { container } from '~/config/invercify'
import {
  UserFactoryInterface,
  UserOrderFactoryInterface,
} from '~/abstracts/factory'
import DiTypes from '~/config/DiTypes'
import { UserOrder } from '~/features/user/user-order/user-order.interface'
import { WithValidationErrors } from '~/types/with-validation-errors'
import { Apollo } from '~/api/GraphqlClient'
import { ValidationErrorFactory } from '~/app/validation-error'
import { ExpandedGraphQLError } from '~/app/validation-error/validation-error.interface'
import { Product } from '~/entities/product'
import { ProductFactoryInterface } from '~/features/product/product-factory'

@injectable()
export class UserRepository
  extends Repository
  implements UserRepositoryInterface {
  constructor(
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo,
    @inject(DiTypes.USER_FACTORY) private userFactory: UserFactoryInterface,
    @inject(DiTypes.PRODUCT_FACTORY)
    private productFactory: ProductFactoryInterface
  ) {
    super(apollo)
  }

  public async getAuthorizationCode(phone: string): Promise<boolean> {
    const { data, errors } = await this.apollo.client.mutate<{
      SendLoginCode: GQLMutation['SendLoginCode']
    }>({
      mutation: await import('~/graphql/user/send-login-code.graphql'),
      variables: { phone },
    })

    if (errors?.length) throw new Error(errors[0].message)

    return Boolean(data?.SendLoginCode.expiredAt)
  }

  public async loginBySms(input: GQLLoginBySmsInput): Promise<string> {
    const { data } = await this.apollo.client.mutate<{
      LoginBySMS: GQLMutation['LoginBySMS']
    }>({
      mutation: await import('~/graphql/user/login-by-sms.graphql'),
      variables: { input },
    })

    if (!data?.LoginBySMS.token) throw new Error('Authentication error-link')

    return data.LoginBySMS.token
  }

  public async getUserDetails(): Promise<User> {
    const { data, errors } = await this.apollo.client.query<{
      Me: GQLQuery['Me']
    }>({
      query: await import('~/graphql/user/user-details.graphql'),
    })

    if (errors?.length) throw new Error('Ошибка авторизации')

    return this.userFactory.create(data.Me)
  }

  public async updateUserDetails(
    input: GQLUserInput
  ): Promise<WithValidationErrors<User>> {
    const { data, errors } = await this.apollo.client.mutate<{
      UpdateMe: GQLMutation['UpdateMe']
    }>({
      mutation: await import('~/graphql/user/update-user-details.graphql'),
      variables: { input },
    })

    return {
      data: data?.UpdateMe ? this.userFactory.create(data.UpdateMe) : null,
      errors: errors?.[0]
        ? ValidationErrorFactory.create(errors[0] as ExpandedGraphQLError)
        : [],
    }
  }

  public async getUserOrders(): Promise<UserOrder[]> {
    const { data } = await this.apollo.client.query<{ Me: GQLQuery['Me'] }>({
      query: await import('~/graphql/user/user_orders.graphql'),
    })

    return container
      .get<UserOrderFactoryInterface>(DiTypes.USER_ORDER_FACTORY)
      .fromArray(data.Me.orders?.data || [])
  }

  public async favouriteAdd(productId: string): Promise<boolean> {
    const { data, errors } = await this.apollo.client.mutate<
      { UserFavouriteUpdate: GQLMutation['UserFavouriteUpdate'] },
      { input: GQLUserFavouriteInput }
    >({
      mutation: await import('~/graphql/user/user-favourite-update.graphql'),
      variables: {
        input: {
          productId,
          action: GQLUserFavouriteAction.Add,
        },
      },
    })

    if (errors?.length) throw new Error(errors[0].message)

    return Boolean(data?.UserFavouriteUpdate)
  }

  public async favouriteRemove(productId: string): Promise<boolean> {
    const { data, errors } = await this.apollo.client.mutate<
      { UserFavouriteUpdate: GQLMutation['UserFavouriteUpdate'] },
      { input: GQLUserFavouriteInput }
    >({
      mutation: await import('~/graphql/user/user-favourite-update.graphql'),
      variables: {
        input: {
          productId,
          action: GQLUserFavouriteAction.Remove,
        },
      },
    })

    if (errors?.length) throw new Error(errors[0].message)

    return Boolean(data?.UserFavouriteUpdate)
  }

  public async getUserFavourites(): Promise<Product[]> {
    const { data } = await this.apollo.client.query<{ Me: GQLQuery['Me'] }>({
      query: await import('~/graphql/user/user-favourites.graphql'),
    })

    return this.productFactory.fromArray(data.Me.favourites?.data || [])
  }
}
