export default {
  APP_IMAGE_FACTORY: Symbol('AppImageFactory'),

  TIMESTAMP: Symbol('Timestamp'),

  LOGGER: Symbol('Logger'),
  APP_LOGGER: Symbol('AppLogger'),
  APP_LOCAL_LOGGER: Symbol('AppLocalLogger'),
  FBQ: Symbol('Fbq'),
  APP_FBQ: Symbol('Fbq'),
  APP_FBQ_LOCAL: Symbol('FbqLocal'),

  ECOMMERCE: Symbol('Ecommerce'),

  GRAPHQL_CLIENT: Symbol('GraphqlClient'),

  PRODUCT_FACTORY: Symbol('ProductFactory'),
  PRODUCT_REPOSITORY: Symbol('ProductRepository'),
  PRODUCT_DATA_FACTORY: Symbol('ProductDataFactory'),
  PRODUCT_FILTER_INPUT_FACTORY: Symbol('ProductFilterInputFactory'),

  ORDER_REPOSITORY: Symbol('OrderRepository'),
  ORDER_INPUT_FACTORY: Symbol('OrderInputFactory'),
  ORDER_ITEM_FACTORY: Symbol('OrderItemFactory'),
  ORDER_PAYMENT_FACTORY: Symbol('OrderPaymentFactory'),

  CATEGORY_TREE_FACTORY: Symbol('CategoryTreeFactory'),
  CATEGORY_REPOSITORY: Symbol('CategoryRepository'),

  CATEGORY_FILTERS_REPOSITORY: Symbol('CategoryFiltersRepository'),

  BANNER_FACTORY: Symbol('BannerFactory'),
  BANNER_REPOSITORY: Symbol('BannerRepository'),

  SEO_FACTORY: Symbol('SeoFactory'),
  META_INFO_FACTORY: Symbol('MetaInfoFactory'),

  CART_REPOSITORY: Symbol('CartRepository'),

  USER_REPOSITORY: Symbol('UserRepository'),
  USER_ADDRESS_FACTORY: Symbol('UserAddressFactory'),
  USER_ADDRESS_INPUT_FACTORY: Symbol('UserAddressInputFactory'),
  USER_FACTORY: Symbol('UserFactory'),
  USER_INPUT_FACTORY: Symbol('UserInputFactory'),
  USER_ORDER_FACTORY: Symbol('UserOrderFactory'),

  BRAND_REPOSITORY: Symbol('BrandRepository'),
}
