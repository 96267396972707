import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { MobileNavigationWrapper } from '~/components/layout/common/mobile-navigation-wrapper'
import { CategoryTreeType } from '~/entities/category-tree'
import { ROUTE } from '~/config/routes'
import { Link } from '~/entities/link'
import { RootModule, useStore } from '~/store/root'
import { unAuthenticate } from '~/store/modules/authorization/autorization.utils'

const NAV_ITEMS: Link[] = [
  {
    name: 'Личные данные',
    slug: ROUTE.PROFILE,
  },
  {
    name: 'Мои заказы',
    slug: ROUTE.USER_ORDERS,
  },
  {
    name: 'Избранное',
    slug: ROUTE.USER_FAVOURITES,
  },
  {
    name: 'Выйти',
    slug: '#',
  },
]

const getNavItem = (link: Link): CategoryTreeType => {
  return {
    text: link.name,
    data: {
      id: ~~(Math.random() * 1000),
      // Временный костыль
      path: '',
      slug: link.slug,
      flags: [],
    },
    state: {
      expanded: false,
      selectable: false,
      disabled: false,
    },
    children: [],
  }
}

@Component
export class MobileMenuProfile extends VueComponent {
  listItems: CategoryTreeType[] = []

  store = useStore<RootModule>(this.$store)

  // TODO: refactor
  async handleClick(listItem: CategoryTreeType): Promise<void> {
    if (this.$route.path === listItem.data.slug) {
      return this.store.layout.setProfileNavigation(false)
    }

    if (listItem.data.slug !== '#') {
      await this.$router.push(listItem.data.slug, () => {
        this.store.layout.setProfileNavigation(false)
      })
    } else {
      await unAuthenticate.call(this).then(() => {
        this.store.layout.setProfileNavigation(false)
      })
    }
  }

  created() {
    this.listItems = NAV_ITEMS.map(getNavItem)
  }

  render(): VNode {
    return (
      <MobileNavigationWrapper
        listItems={this.listItems}
        handleItemClick={this.handleClick}
      />
    )
  }
}
