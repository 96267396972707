import { ParentItem } from './components/parent-item'
import styles from './mobile-menu.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

import { CategoryTreeType } from '~/entities/category-tree'
import { Link } from '~/entities/link'
import { MENU_LINKS } from '~/config/menu-links'

import { GQLCategoryFlag } from '~/types/gql'
import { RootModule, useStore } from '~/store/root'

interface Props {
  toggleMobileMenu: () => void
}

@Component
export class MobileMenu extends VueComponent<Props> {
  @Prop() readonly toggleMobileMenu!: Props['toggleMobileMenu']

  store = useStore<RootModule>(this.$store)

  tree: CategoryTreeType[] = []

  initiateTree(): void {
    this.tree = [
      this.getCatalogNode(),
      ...MENU_LINKS.map(this.linkToTreeMenuItem),
    ]
  }

  getCatalogNode(): CategoryTreeType {
    const catalogNode = this.linkToTreeMenuItem({
      name: 'Каталог',
      slug: '/catalog',
    })
    catalogNode.children = this.store.categoryTree.mainCategoriesFlat
    return catalogNode
  }

  linkToTreeMenuItem(link: Link): CategoryTreeType {
    return {
      text: link.name,
      data: {
        id: ~~(Math.random() * 1000),
        // Временный костыль
        path: '',
        slug: link.slug,
        isTargetPage: true,
        flags: [GQLCategoryFlag.Menu],
      },
      state: {
        expanded: false,
        disabled: false,
        selectable: false,
      },
      children: [],
    }
  }

  /*
   * Возвращает ноды одного уровня с targer
   */
  getSiblingNodes(
    items: CategoryTreeType[],
    target: CategoryTreeType
  ): CategoryTreeType[] {
    const queue: Array<CategoryTreeType[]> = [...[items]]

    while (queue.length) {
      const nodes: CategoryTreeType[] = queue.shift() || []

      for (const { data, children } of nodes) {
        if (data.id === target.data.id) {
          return nodes.filter(({ data: { id } }) => id !== target.data.id)
        }

        children.length && queue.push(children)
      }
    }
    return []
  }

  hideItem(item: CategoryTreeType): void {
    item.state.expanded = false
  }

  hideItems(clickedItem: CategoryTreeType): void {
    // Находим ветви одного уровня с тергетом
    const siblings = this.getSiblingNodes(this.tree, clickedItem)
    // Выключаем expanded для всех родственных элементов
    siblings.forEach(this.hideItem)
    // Выключаем expanded для всех дочерних элементов
    clickedItem.children.forEach(this.hideItem)
  }

  async handleItemClick(item: CategoryTreeType): Promise<void> {
    if (!item.children.length) {
      const path = item.data.isTargetPage
        ? item.data.slug
        : `/catalog/${item.data.slug}`
      return await this.redirectToCategory(path)
    }

    item.state.expanded = !item.state.expanded

    this.hideItems(item)
  }

  async redirectToCategory(path: string): Promise<void> {
    await this.$router.push(path, () => {
      this.toggleMobileMenu()
    })
  }

  created(): void {
    this.initiateTree()
  }

  render(): VNode {
    return (
      <div class={styles.wrapper}>
        <ul class={styles.list}>
          {this.tree.map((item) => (
            <ParentItem
              key={item.data.id}
              item={item}
              handleItemClick={this.handleItemClick}
            />
          ))}
        </ul>
      </div>
    )
  }
}
