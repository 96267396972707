import 'reflect-metadata'
// eslint-disable-next-line import/named
import { Container, interfaces } from 'inversify'

import DiTypes from './DiTypes'

import { Apollo, GraphqlClientInterface } from '~/api/GraphqlClient'
import {
  ProductFactory,
  ProductFactoryInterface,
} from '~/features/product/product-factory'
import { ProductRepository } from '~/repositories/product'
import {
  CategoryFiltersRepositoryInterface,
  CategoryRepositoryInterface,
  IBannerRepository,
  OrderRepositoryInterface,
  ProductRepositoryInterface,
  UserRepositoryInterface,
} from '~/abstracts/repository'
import {
  AppImageFactoryInterface,
  CategoryTreeFactoryInterface,
  OrderInputFactoryInterface,
  OrderItemFactoryInterface,
  ProductDataFactoryInterface,
  UserAddressFactoryInterface,
  UserAddressInputFactoryInterface,
  UserInputFactoryInterface,
  UserOrderFactoryInterface,
  UserFactoryInterface,
  ProductFilterInputFactoryInterface,
  IOrderPaymentFactory,
  ISeoFactory,
  IBannerFactory,
  IMetaInfoFactory,
} from '~/abstracts/factory'
import { ProductDataFactory } from '~/features/product/product-data-factory'
import { OrderInputFactory } from '~/features/order/order-input-factory'
import { PickupOrderInputFactory } from '~/features/order/pickup-order-input-factory'
import { CategoryTreeFactory } from '~/features/category/factory/category-tree-factory'
import { CategoryRepository } from '~/repositories/category'
import { UserRepository } from '~/repositories/user'
import { AppImageFactory } from '~/app/image/app-image.factory'
import { UserAddressFactory } from '~/features/user/user-address/user-address.factory'
import { UserFactory } from '~/features/user/user.factory'
import { UserAddressInputFactory } from '~/features/user/user-address/user-address-input.factory'
import { UserInputFactory } from '~/features/user/user-input.factory'
import { OrderItemFactory } from '~/features/order/order-item/order-item.factory'
import { UserOrderFactory } from '~/features/user/user-order/user-order.factory'
import { AppLogger } from '~/app/logger/logger'
import { AppLocalLogger, ILogger } from '~/app/logger'
import { IEcommerce } from '~/app/ecommerce/ecommerce.types'
import { Ecommerce } from '~/app/ecommerce/ecommerce'
import { ITimestamp } from '~/utils/timestamp/timestamp.interface'
import { Timestamp } from '~/utils/timestamp/timestamp'
import { CategoryFiltersRepository } from '~/repositories/category-filters'
import { ProductFilterInputFactory } from '~/features/product/product-filter/product-filter-input.factory'
import { OrderRepository } from '~/repositories/order'
import { OrderPaymentFactory } from '~/features/order/order-payment/order-payment.factory'
import { BannerFactory } from '~/features/banner/banner.factory'
import { BannerRepository } from '~/repositories/banner'
import { SeoFactory } from '~/features/seo'
import { MetaInfoFactory } from '~/app/meta-info'
import { Fbq, FbqLocal, IFbq } from '~/app/fbq'
import { ICartRepository } from '~/repositories/cart/cart.repository.interface'
import { CartRepository } from '~/repositories/cart/cart.repository'
import { IBrandRepository } from '~/features/brand'
import { BrandRepository } from '~/repositories/brand/brand.repository'

const container: Container = new Container()

container
  .bind<GraphqlClientInterface>(DiTypes.GRAPHQL_CLIENT)
  .toConstantValue(new Apollo())

container.bind<ITimestamp>(DiTypes.TIMESTAMP).to(Timestamp).inSingletonScope()

container.bind<ILogger>(DiTypes.APP_LOGGER).to(AppLogger).inSingletonScope()

container
  .bind<ILogger>(DiTypes.APP_LOCAL_LOGGER)
  .to(AppLocalLogger)
  .inSingletonScope()

container
  .bind<ILogger>(DiTypes.LOGGER)
  .toDynamicValue((context: interfaces.Context) => {
    return process.env.NODE_ENV === 'production'
      ? context.container.get<ILogger>(DiTypes.APP_LOGGER)
      : context.container.get<ILogger>(DiTypes.APP_LOCAL_LOGGER)
  })

container.bind<IFbq>(DiTypes.APP_FBQ).to(Fbq)

container.bind<IFbq>(DiTypes.APP_FBQ_LOCAL).to(FbqLocal)

container
  .bind<IFbq>(DiTypes.FBQ)
  .toDynamicValue((context: interfaces.Context) => {
    return process.env.NODE_ENV === 'production'
      ? context.container.get<IFbq>(DiTypes.APP_FBQ)
      : context.container.get<IFbq>(DiTypes.APP_FBQ_LOCAL)
  })

container
  .bind<ProductFactoryInterface>(DiTypes.PRODUCT_FACTORY)
  .to(ProductFactory)
  .inSingletonScope()

container
  .bind<ProductRepositoryInterface>(DiTypes.PRODUCT_REPOSITORY)
  .to(ProductRepository)
  .inSingletonScope()

container
  .bind<ProductDataFactoryInterface>(DiTypes.PRODUCT_DATA_FACTORY)
  .to(ProductDataFactory)

container
  .bind<OrderRepositoryInterface>(DiTypes.ORDER_REPOSITORY)
  .to(OrderRepository)
  .inSingletonScope()

container
  .bind<OrderInputFactoryInterface>(DiTypes.ORDER_INPUT_FACTORY)
  .to(OrderInputFactory)
  .whenTargetTagged('delivery', true)

container
  .bind<OrderInputFactoryInterface>(DiTypes.ORDER_INPUT_FACTORY)
  .to(PickupOrderInputFactory)
  .whenTargetTagged('delivery', false)

container
  .bind<CategoryTreeFactoryInterface>(DiTypes.CATEGORY_TREE_FACTORY)
  .to(CategoryTreeFactory)
  .inSingletonScope()

container
  .bind<CategoryRepositoryInterface>(DiTypes.CATEGORY_REPOSITORY)
  .to(CategoryRepository)
  .inSingletonScope()

container
  .bind<UserRepositoryInterface>(DiTypes.USER_REPOSITORY)
  .to(UserRepository)

container
  .bind<AppImageFactoryInterface>(DiTypes.APP_IMAGE_FACTORY)
  .to(AppImageFactory)

container
  .bind<UserAddressFactoryInterface>(DiTypes.USER_ADDRESS_FACTORY)
  .to(UserAddressFactory)

container.bind<UserFactoryInterface>(DiTypes.USER_FACTORY).to(UserFactory)

container
  .bind<UserAddressInputFactoryInterface>(DiTypes.USER_ADDRESS_INPUT_FACTORY)
  .to(UserAddressInputFactory)

container
  .bind<UserInputFactoryInterface>(DiTypes.USER_INPUT_FACTORY)
  .to(UserInputFactory)

container
  .bind<OrderItemFactoryInterface>(DiTypes.ORDER_ITEM_FACTORY)
  .to(OrderItemFactory)

container
  .bind<UserOrderFactoryInterface>(DiTypes.USER_ORDER_FACTORY)
  .to(UserOrderFactory)

container.bind<IEcommerce>(DiTypes.ECOMMERCE).to(Ecommerce).inRequestScope()

container
  .bind<CategoryFiltersRepositoryInterface>(DiTypes.CATEGORY_FILTERS_REPOSITORY)
  .to(CategoryFiltersRepository)

container
  .bind<ProductFilterInputFactoryInterface>(
    DiTypes.PRODUCT_FILTER_INPUT_FACTORY
  )
  .toConstantValue(new ProductFilterInputFactory())

container
  .bind<IOrderPaymentFactory>(DiTypes.ORDER_PAYMENT_FACTORY)
  .to(OrderPaymentFactory)

container.bind<IBannerFactory>(DiTypes.BANNER_FACTORY).to(BannerFactory)

container
  .bind<IBannerRepository>(DiTypes.BANNER_REPOSITORY)
  .to(BannerRepository)

container.bind<ISeoFactory>(DiTypes.SEO_FACTORY).to(SeoFactory)

container
  .bind<IMetaInfoFactory>(DiTypes.META_INFO_FACTORY)
  .toConstantValue(new MetaInfoFactory())

container.bind<ICartRepository>(DiTypes.CART_REPOSITORY).to(CartRepository)

container.bind<IBrandRepository>(DiTypes.BRAND_REPOSITORY).to(BrandRepository)

export { container }
