import {
  ExpandedGraphQLError,
  ValidationError,
} from '~/app/validation-error/validation-error.interface'

export class ValidationErrorFactory {
  static create(input: ExpandedGraphQLError): ValidationError[] {
    return input.extensions?.structure.map((item) => ({
      entity: item.entity,
      message: item.message,
    }))
  }
}
