import Vue from 'vue'
import { Context, Plugin } from '@nuxt/types'
import { removeAuthToken } from '~/utils/auth-token'

const UNAUTHENTICATED_MASSAGE = 'Unauthenticated.'

const originalHandler = Vue.config.errorHandler

const authenticationGuardPlugin: Plugin = (ctx: Context) => {
  Vue.config.errorHandler = (err: Error, ...args) => {
    if (err.message === UNAUTHENTICATED_MASSAGE) {
      removeAuthToken()
      ctx.redirect('/')
    }
    originalHandler(err, ...args)
  }
}

export default authenticationGuardPlugin
