import { Action, Mutation, State } from 'vuex-simple'

export class LayoutModule {
  @State()
  public mobileMunuVisible: boolean = false

  @State()
  public profileNavigation: boolean = false

  @Mutation()
  public setMobileMenuVisible(value: boolean): void {
    this.mobileMunuVisible = value
    if (value) {
      this.setProfileNavigation(false)
    }
  }

  @Mutation()
  public setProfileNavigation(value: boolean): void {
    this.profileNavigation = value
    if (value) {
      this.setMobileMenuVisible(false)
    }
  }

  @Action()
  public toggleMobileMenu(): void {
    this.setMobileMenuVisible(!this.mobileMunuVisible)
  }

  @Action()
  public hideMobileMenu(): void {
    this.setMobileMenuVisible(false)
  }
}
