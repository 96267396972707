// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".controlsItem_2BfvG{display:flex;flex-direction:column;align-items:center;position:relative;cursor:pointer}.controlsItem_2BfvG .label_3FkU2,.counter_109l5{font-size:9px}.counter_109l5{display:flex;align-items:center;justify-content:center;position:absolute;top:0;right:50%;transform:translate(100%);border-radius:50%;border:2px solid transparent;padding:2px 4px;background-color:var(--color-fragrant-cherry);color:var(--color-snow-white);line-height:8px}@media (min-width:960px){.controlsItem_2BfvG{color:var(--color-darkest-dark)}.controlsItem_2BfvG .label_3FkU2{font-size:16px;line-height:24px;margin-top:12px;transition:color .2s}.controlsItem_2BfvG:hover .label_3FkU2{color:var(--color-tiffany-cactus)}}", ""]);
// Exports
exports.locals = {
	"controlsItem": "controlsItem_2BfvG",
	"label": "label_3FkU2",
	"counter": "counter_109l5"
};
module.exports = exports;
