import { Getter, Mutation, State, Action } from 'vuex-simple'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { GQLImage } from '~/types/gql'
import { AppImageFactoryInterface } from '~/abstracts/factory'
import { OrderItem } from '~/entities/order-item'
import { IEcommerce } from '~/app/ecommerce/ecommerce.types'
import { deepCopy } from '~/utils/helpers'
import { toLocalStorage } from '~/store/modules/cart/cart.decorators'
import {
  getFromLocalStorage,
  removeSaved,
} from '~/store/modules/cart/cart.utils'
import { IFbq } from '~/app/fbq'

export class CartModule {
  @State()
  cartItems: OrderItem[] = []

  /**
   * Общее кол-во товаров в корзине
   */
  @Getter()
  public get productsCount(): number {
    return this.cartItems.reduce((count, item) => {
      return count + item.qty
    }, 0)
  }

  /**
   * Сумма цены всех товаров в корзине
   */
  @Getter()
  public get amount(): number {
    return this.cartItems.reduce((price, item) => {
      return price + item.product.price * item.qty
    }, 0)
  }

  @Mutation()
  setCartItems(cartItems: OrderItem[] = []) {
    this.cartItems = [...cartItems]
  }

  @Mutation()
  increaseQtyMutation(productID: string) {
    const foundIndex = this.findCartItemIndex(productID)

    if (foundIndex < 0) return

    this.cartItems[foundIndex].qty += 1
  }

  @Mutation()
  decreaseQtyMutation(productID: string) {
    const foundIndex = this.findCartItemIndex(productID)

    if (foundIndex < 0) return

    switch (this.cartItems[foundIndex].qty > 1) {
      case true:
        this.cartItems[foundIndex].qty -= 1
        break
      case false:
        this.cartItems.splice(foundIndex, 1)
        break
    }
  }

  @Mutation()
  addToCartMutation(cartItem: OrderItem): void {
    const foundIndex = this.findCartItemIndex(cartItem.product.ID)

    switch (foundIndex < 0) {
      case true: {
        this.cartItems.push(cartItem)
        container.get<IEcommerce>(DiTypes.ECOMMERCE).add([
          {
            product: {
              id: cartItem.product.ID,
              name: cartItem.product.name,
              price: cartItem.product.price,
            },
            quantity: cartItem.qty,
          },
        ])
        container.get<IFbq>(DiTypes.FBQ).track('AddToCart')
        break
      }
      case false:
        this.cartItems[foundIndex].qty += 1
        break
    }
  }

  @Mutation()
  removeFromCartMutation(productID: string): void {
    const foundIndex = this.findCartItemIndex(productID)

    if (foundIndex < 0) return

    const removedProductCopy = deepCopy(this.cartItems[foundIndex])

    this.cartItems.splice(foundIndex, 1)

    container.get<IEcommerce>(DiTypes.ECOMMERCE).remove([
      {
        product: {
          id: removedProductCopy.product.ID,
          name: removedProductCopy.product.name,
          price: removedProductCopy.product.price,
        },
        quantity: removedProductCopy.qty,
      },
    ])
  }

  @Action()
  @toLocalStorage
  public increaseQty(productID: string): void {
    this.increaseQtyMutation(productID)
  }

  @Action()
  @toLocalStorage
  public decreaseQty(productID: string): void {
    this.decreaseQtyMutation(productID)
  }

  @Action()
  @toLocalStorage
  public addToCart(cartItem: OrderItem): void {
    this.addToCartMutation(cartItem)
  }

  @Action()
  @toLocalStorage
  public removeFromCart(productID: string): void {
    this.removeFromCartMutation(productID)
  }

  public getItemsFromLocalStorage(): void {
    const cartItems = getFromLocalStorage()
    /*
     * Временный хак. После восстановления данныйх из localStorage у изобрадений
     * затираются методы класса. Пробелма уйдет после переноса хранения корзины
     * на be
     * */
    this.setCartItems(
      cartItems.map((cartItem: OrderItem) => ({
        qty: cartItem.qty,
        product: {
          ...cartItem.product,
          image: container
            .get<AppImageFactoryInterface>(DiTypes.APP_IMAGE_FACTORY)
            .create((cartItem.product.image as unknown) as GQLImage),
        },
      }))
    )
  }

  public isInCart(productID: string): boolean {
    return (
      this.cartItems.findIndex(({ product }) => product.ID === productID) >= 0
    )
  }

  public cleanCart(): void {
    this.setCartItems([])
    removeSaved()
  }

  private findCartItemIndex = (productID: string): number => {
    return this.cartItems.findIndex(({ product: { ID } }) => ID === productID)
  }
}
