import styles from './navigation-mobile.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

import { Container } from '~/components/layout/common/container'
import { ControlItem } from '~/components/layout/common/control-item'

import { RootModule, useStore } from '~/store/root'
import { ROUTE } from '~/config/routes'

interface Props {
  toggleMobileMenu: () => void
}

@Component
export class NavigationMobile extends VueComponent<Props> {
  @Prop() readonly toggleMobileMenu!: Props['toggleMobileMenu']

  store = useStore<RootModule>(this.$store)

  get cartItemsCounter(): number {
    return this.store.cart.productsCount
  }

  handleProfileClick(): void {
    this.store.authorization.authenticated
      ? this.store.layout.setProfileNavigation(
          !this.store.layout.profileNavigation
        )
      : this.store.authorization.setPopupShow(true)
  }

  async handleCartClick() {
    await this.$router.push(ROUTE.CART)
    this.store.layout.setProfileNavigation(false)
    this.store.layout.setMobileMenuVisible(false)
  }

  redirectToWA(): void {
    window.open('https://wa.me/79035694983', '_blank')
  }

  render(): VNode {
    return (
      <nav class={styles.navigation}>
        <Container>
          <ul class={styles.navigationList}>
            <ControlItem.Button
              label="Профиль"
              icon={require('~/assets/icons/profile.svg')}
              whenClick={this.handleProfileClick}
            />
            <ControlItem.Button
              label="Каталог"
              icon={require('~/assets/icons/catalog.svg')}
              whenClick={this.toggleMobileMenu}
            />
            <ControlItem.Button
              label="Мой провизор"
              icon={require('~/assets/icons/provizor.svg')}
              whenClick={this.redirectToWA}
            />
            {/* <ControlItem.Link */}
            {/*  label="Избранное" */}
            {/*  icon={require('~/assets/icons/heart.svg')} */}
            {/*  link="/" */}
            {/* /> */}
            <ControlItem.Button
              label="Корзина"
              icon={require('~/assets/icons/cart.svg')}
              counter={this.cartItemsCounter}
              whenClick={this.handleCartClick}
            />
          </ul>
        </Container>
      </nav>
    )
  }
}
