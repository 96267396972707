import styles from './mobile-navigation-wrapper.css?module'
import { Component, Prop, VueComponent, VNode } from '~/types/vue-ts-component'
import { ParentItem } from '~/components/layout/mobile-menu/components/parent-item'
import { CategoryTreeType } from '~/entities/category-tree'

interface Props {
  listItems: CategoryTreeType[]
  handleItemClick: (item: CategoryTreeType) => Promise<void>
}

@Component
export class MobileNavigationWrapper extends VueComponent<Props> {
  @Prop() readonly listItems!: Props['listItems']
  @Prop() readonly handleItemClick!: Props['handleItemClick']

  render(): VNode {
    return (
      <div class={styles.wrapper}>
        <ul class={styles.list}>
          {this.listItems.map((item) => (
            <ParentItem
              key={item.data.id}
              item={item}
              handleItemClick={this.handleItemClick}
            />
          ))}
        </ul>
      </div>
    )
  }
}
