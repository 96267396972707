// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer_h2Drs{margin-top:45px;background-color:#f3f3f6;margin-bottom:52px;position:relative}.footer_h2Drs .desktop_N8s0m{display:none}.illustration_P6c47{position:absolute;right:0;z-index:0}.illustration_P6c47.illustrationDesktop_2XhQh{display:none;top:-85px}.illustration_P6c47.illustrationMobile_1AfFi{top:-16px}@media (min-width:960px){.footer_h2Drs{margin-bottom:0;margin-top:135px}.footer_h2Drs .mobile_1WYRB{display:none}.footer_h2Drs .desktop_N8s0m{display:grid}.illustration_P6c47.illustrationDesktop_2XhQh{display:block}.illustration_P6c47.illustrationMobile_1AfFi{display:none}}", ""]);
// Exports
exports.locals = {
	"footer": "footer_h2Drs",
	"desktop": "desktop_N8s0m",
	"illustration": "illustration_P6c47",
	"illustrationDesktop": "illustrationDesktop_2XhQh",
	"illustrationMobile": "illustrationMobile_1AfFi",
	"mobile": "mobile_1WYRB"
};
module.exports = exports;
