import { inject, injectable } from 'inversify'
import {
  AppImageFactoryInterface,
  Factory,
  ISeoFactory,
} from '~/abstracts/factory'
import { GQLProduct } from '~/types/gql'
import { Product } from '~/entities/product'
import { ProductFactoryInterface } from '~/features/product/product-factory/types'
import { centsToPrice } from '~/utils/format-price'
import { findMainImage } from '~/app/image/app-image.utils'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'

@injectable()
export class ProductFactory
  extends Factory<GQLProduct, Product>
  implements ProductFactoryInterface {
  seoFactory: ISeoFactory

  constructor(@inject(DiTypes.SEO_FACTORY) seoFactory: ISeoFactory) {
    super()
    this.seoFactory = seoFactory
  }

  public create(input: GQLProduct): Product {
    return {
      ID: input.id,
      name: input.name,
      price: centsToPrice(input.price),
      brand: this.getProductBrand(input.brand),
      country: input.country?.name ?? '',
      sku: input.sku,
      oldPrice: this.getOldPrice(input.oldPrice),
      slug: input.slug,
      inFavourite: input.meta.inFavourite,
      seo: this.seoFactory.create(input.seo),
      image: this.getMainImage(input.images),
    }
  }

  private getOldPrice(oldPrice: GQLProduct['oldPrice']): Product['oldPrice'] {
    return oldPrice ? centsToPrice(oldPrice) : undefined
  }

  private getMainImage(images: GQLProduct['images']): Product['image'] {
    return container
      .get<AppImageFactoryInterface>(DiTypes.APP_IMAGE_FACTORY)
      .create(findMainImage(images)?.image)
  }

  private getProductBrand(gqlBrand: GQLProduct['brand']): Product['brand'] {
    if (!gqlBrand) return

    return {
      name: gqlBrand.name,
      slug: gqlBrand.slug,
    }
  }
}
