// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headerMain_3-8oX{height:96px}.container_1z60B,.headerMain_3-8oX{display:flex;align-items:center}.container_1z60B{width:100%}.container_1z60B>:not(:last-child){margin-right:24px}.search_3Ci_1{flex:1}.firstControl_1GSag{margin-left:auto}.logo_b0VAQ{flex:0 0 168px;height:40px}@media (min-width:1024px){.search_3Ci_1{flex:0 1 640px}}", ""]);
// Exports
exports.locals = {
	"headerMain": "headerMain_3-8oX",
	"container": "container_1z60B",
	"search": "search_3Ci_1",
	"firstControl": "firstControl_1GSag",
	"logo": "logo_b0VAQ"
};
module.exports = exports;
