import { CartModule } from '~/store/modules/cart/index'
import { saveToLocalstorage } from '~/store/modules/cart/cart.utils'

export const toLocalStorage = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  target: CartModule,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propertyKey: string,
  descriptor: PropertyDescriptor
): PropertyDescriptor => {
  if (typeof window === 'undefined') return descriptor

  const originalMethod = descriptor.value

  descriptor.value = function (this: CartModule, ...args: any[]) {
    originalMethod.apply(this, args)
    saveToLocalstorage(this.cartItems)
  }

  return descriptor
}
