import { inject, injectable } from 'inversify'
import {
  Factory,
  UserAddressFactoryInterface,
  UserFactoryInterface,
} from '~/abstracts/factory'
import { GQLUser } from '~/types/gql'
import { User } from '~/features/user/user.interface'
import DiTypes from '~/config/DiTypes'

@injectable()
export class UserFactory
  extends Factory<GQLUser, User>
  implements UserFactoryInterface {
  constructor(
    @inject(DiTypes.USER_ADDRESS_FACTORY)
    private userAddressFactory: UserAddressFactoryInterface
  ) {
    super()
  }

  public create(input: GQLUser): User {
    return {
      name: input.name,
      phone: input.phone,
      email: input.email ?? '',
      birthday: input.birthday,
      addresses: this.userAddressFactory.fromArray(input.addresses),
    }
  }
}
