import { HeaderDesktopInfoItemProps } from '../index'
import styles from './styles.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

import { Container } from '~/components/layout/common/container'
import { COMPANY_CONTACTS } from '~/config/company-contacts'
import { inputToPhone } from '~/utils/format-phone'

@Component
class HeaderDesktopInfoItem extends VueComponent<HeaderDesktopInfoItemProps> {
  @Prop() readonly icon!: HeaderDesktopInfoItemProps['icon']
  @Prop() readonly text!: HeaderDesktopInfoItemProps['text']

  render(): VNode {
    return (
      <div class={styles.headerInfoItem}>
        <img src={this.icon} class={styles.icon} />
        {this.$slots.default ?? (
          <span class={styles.headerInfoText}>{this.text}</span>
        )}
      </div>
    )
  }
}

@Component
export class HeaderDesktopInfo extends VueComponent {
  render(): VNode {
    return (
      <Container>
        <div class={styles.headerInfo}>
          <nuxtLink class={styles.headerInfoItem} to="/contacts">
            <img
              src={require('~/assets/icons/location.svg')}
              class={styles.icon}
            />
            <span class={styles.headerInfoText}>
              Петровский Пассаж, Неглинная улица, 13, 1 линия, 1 этаж
            </span>
          </nuxtLink>
          <HeaderDesktopInfoItem
            icon={require('~/assets/icons/clock.svg')}
            text={COMPANY_CONTACTS.operationHours}
          />
          <a rel="nofollow" href={`tel:${COMPANY_CONTACTS.phone.main}`}>
            <HeaderDesktopInfoItem icon={require('~/assets/icons/phone.svg')}>
              <span class={styles.phone}>
                {inputToPhone(COMPANY_CONTACTS.phone.main)}
              </span>
            </HeaderDesktopInfoItem>
          </a>
        </div>
      </Container>
    )
  }
}
