import { inject, injectable } from 'inversify'
import { CategoryRepositoryInterface, Repository } from '~/abstracts/repository'
import { GQLQuery, GQLSeo } from '~/types/gql'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import { CategoryTreeFactoryInterface, ISeoFactory } from '~/abstracts/factory'
import { CategoryTreeType } from '~/entities/category-tree'
import { Seo } from '~/features/seo'

interface CategoryInfo {
  seo: Seo | null
  description?: string
}

@injectable()
export class CategoryRepository
  extends Repository
  implements CategoryRepositoryInterface {
  private categoryTreeFactory: CategoryTreeFactoryInterface
  private seoFactory: ISeoFactory

  constructor(
    @inject(DiTypes.CATEGORY_TREE_FACTORY)
    categoryTreeFactory: CategoryTreeFactoryInterface,
    @inject(DiTypes.SEO_FACTORY) seoFactory: ISeoFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.categoryTreeFactory = categoryTreeFactory
    this.seoFactory = seoFactory
  }

  public getCategoriesTree = async (): Promise<CategoryTreeType[]> => {
    const { data } = await this.apollo.client.query<{
      Categories: GQLQuery['Categories']
    }>({
      query: await import('~/graphql/categories.graphql'),
    })
    return this.categoryTreeFactory.create(data?.Categories || [])
  }

  public async getCategorySeo(categoryID: string): Promise<Seo | null> {
    const { data } = await this.apollo.client.query<
      {
        Category: GQLQuery['Category']
      },
      { categoryID: string }
    >({
      query: await import('~/graphql/category/category-seo.graphql'),
      variables: {
        categoryID,
      },
    })

    if (!data?.Category?.seo) {
      return null
    }

    return this.seoFactory.create(data?.Category?.seo as GQLSeo)
  }

  public async getCategoryInfo(categoryID: string): Promise<CategoryInfo> {
    const { data } = await this.apollo.client.query<
      {
        Category: GQLQuery['Category']
      },
      { categoryID: string }
    >({
      query: await import('~/graphql/category/category-info.graphql'),
      variables: {
        categoryID,
      },
    })

    if (!data?.Category?.seo) {
      return {
        description: data.Category?.description,
        seo: null,
      }
    }

    return {
      description: data.Category?.description,
      seo: this.seoFactory.create(data?.Category?.seo as GQLSeo),
    }
  }
}
