import { BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { createMask } from 'imask'
import styles from './form-input-phone.css?module'

import { FormInputPhoneProps as Props } from './types'
import {
  Component,
  VueComponent,
  Prop,
  Watch,
  VNode,
} from '~/types/vue-ts-component'

const phoneMasker = createMask({
  mask: '+7 (000) 000-00-00',
})

const PHONE_REGEXP = new RegExp(/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/)

@Component
export class FormInputPhone extends VueComponent<Props> {
  @Prop() readonly phone!: Props['phone']
  @Prop() readonly whenChange!: Props['whenChange']
  @Prop() readonly disabled!: Props['disabled']

  phoneIsValid = true

  get phoneEmpty(): boolean {
    return !this.phone.length
  }

  get phoneInputState(): false | undefined {
    return this.phoneIsValid ? undefined : false
  }

  get phoneHasValidFormat(): boolean {
    return PHONE_REGEXP.test(this.phone)
  }

  formatPhone(phone: string): string {
    const normalized = phone.replace(/^8/, '+7')
    return phoneMasker.resolve(normalized)
  }

  validatePhone(): boolean {
    this.phoneIsValid = this.phoneHasValidFormat
    return this.phoneIsValid
  }

  @Watch('phone')
  whenPhoneChanges(phone: string, oldPhone: string) {
    if (
      (!phone.length && oldPhone.length) ||
      (phone.length && !oldPhone.length)
    ) {
      this.phoneIsValid = true
    }
  }

  render(): VNode {
    return (
      <div class={styles.wrapper}>
        <BFormInput
          disabled={this.disabled}
          class={styles.input}
          placeholder="Телефон"
          type="tel"
          value={this.formatPhone(this.phone)}
          state={this.phoneInputState}
          formatter={this.formatPhone}
          onInput={this.whenChange}
        />
        <BFormInvalidFeedback>
          {this.phoneEmpty
            ? 'Введите номер телефона'
            : !this.phoneIsValid && 'Некорректный формат телефона'}
        </BFormInvalidFeedback>
      </div>
    )
  }
}
