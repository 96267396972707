import { Plugin } from '@nuxt/types'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { IFbq } from '~/app/fbq'

declare module 'vue/types/vue' {
  // this.$fbq inside Vue components
  interface Vue {
    $fbq: IFbq
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$fbq inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $fbq: IFbq
  }
  // nuxtContext.$fbq
  interface Context {
    $fbq: IFbq
  }
}

declare module 'vuex/types/index' {
  // this.$fbq inside Vuex stores
  interface Store<S> {
    $fbq: IFbq
  }
}

const fbqPlugin: Plugin = ({ app }, inject) => {
  const fbq = container.get<IFbq>(DiTypes.FBQ)
  // TODO: вынести в env
  fbq.init('435862968043481')

  if (app.router) {
    app.router.afterEach(() => {
      fbq.track('PageView')
    })
  }

  inject('fbq', fbq)
}

export default fbqPlugin
