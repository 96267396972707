import { onError } from 'apollo-link-error'
import {
  graphqlErrorHandler,
  networkErrorHandler,
} from '~/api/GraphqlClient/links/error-link/error.handlers'

export const createErrorLink = () => {
  return onError(({ graphQLErrors, networkError, operation }) => {
    networkError && networkErrorHandler(networkError)
    graphQLErrors?.length && graphqlErrorHandler(operation, graphQLErrors)
  })
}
