import { injectable } from 'inversify'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { DateInput, ITimestamp } from '~/utils/timestamp/timestamp.interface'
import { FORMAT } from '~/utils/timestamp/timestamp.consts'

@injectable()
export class Timestamp implements ITimestamp {
  constructor() {
    dayjs.locale('ru')
  }

  public format(
    date: DateInput,
    template: string | undefined = FORMAT.DATE_FULL
  ): string {
    return dayjs(date).format(template)
  }
}
