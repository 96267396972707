import { Context } from '@nuxt/types'

const paginationMiddleware = ({ route, redirect }: Context): void => {
  if (route.query?.page === '1') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, ...query } = route.query
    redirect({ path: route.path, query })
  }
  if (process.client) {
    window.scrollTo(0, 0)
  }
}

export default paginationMiddleware
