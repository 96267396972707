import { Factory } from '~/abstracts/factory'
import { CheckoutForm } from '~/entities/checkout-form'
import { GQLOrderInput } from '~/types/gql'
import { phoneToInput } from '~/utils/format-phone'

export class OrderCustomerInputFactory extends Factory<
  CheckoutForm['customer'],
  GQLOrderInput['customer']
> {
  public create(customer: CheckoutForm['customer']): GQLOrderInput['customer'] {
    return {
      name: customer.name,
      email: customer.email.length ? customer.email : undefined,
      phone: phoneToInput(customer.phone),
    }
  }
}
