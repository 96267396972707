import { ApolloLink, Context } from '@apollo/client'
import { getAuthToken } from '~/utils/auth-token'

export const getAuthLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: Context) => ({
      headers: {
        Authorization: getAuthToken(),
        ...headers,
      },
    }))
    return forward(operation)
  })
}
