// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navigation_ipqai{position:fixed;bottom:-1px;left:0;z-index:999;width:100%;padding:8px 0;background-color:var(--color-snow-white);border-top:1px solid var(--color-fog-gray)}.navigationList_j5ZmR{display:flex;align-items:center;justify-content:space-between}.listItem_SkI9_{display:flex;flex-direction:column;align-self:center}.listItem_SkI9_ .itemIcon_nrr0H,.listItem_SkI9_ span{text-align:center}.listItem_SkI9_ span{margin-top:4px}@media (min-width:375px){.navigation_ipqai{padding:8px 18px 20px}}@media (min-width:480px){.navigation_ipqai{padding:8px 24px 20px}}@media (min-width:960px){.navigation_ipqai{display:none}}", ""]);
// Exports
exports.locals = {
	"navigation": "navigation_ipqai",
	"navigationList": "navigationList_j5ZmR",
	"listItem": "listItem_SkI9_",
	"itemIcon": "itemIcon_nrr0H"
};
module.exports = exports;
