import {
  LinkPropertyBase,
  LinkPropertyHref,
  LinkPropertyHrefCallback,
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyMicrodata,
  MetaPropertyName,
  MetaPropertyProperty,
  ScriptPropertyJson,
  ScriptPropertySrc,
  ScriptPropertySrcCallback,
  ScriptPropertyText,
} from 'vue-meta/types/vue-meta'
import { Thing, WithContext } from 'schema-dts'
import { MetaInfo } from 'vue-meta'
import { DOMAIN_NAME } from '~/config/routes'
import { Seo } from '~/features/seo'
import { DEFAULT_META_DESCRIPTION } from '~/config/meta-info'

type MetaItem =
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicrodata
  | MetaPropertyProperty

type MetaLink = LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback

type ScriptItem =
  | ScriptPropertyText
  | ScriptPropertySrc
  | ScriptPropertySrcCallback
  | ScriptPropertyJson

export const createMeta = (key: string, value: string): MetaItem => {
  return {
    hid: key,
    name: key,
    content: value,
  }
}

export const createCanonicalLink = (path: string): MetaLink => {
  return {
    rel: 'canonical',
    href: DOMAIN_NAME + formatCanonicalLinkPath(path),
  }
}

export const formatCanonicalLinkPath = (path: string): string =>
  path.toLowerCase()

export const createNoIndexNofollowMetaItem = (): MetaItem => {
  return {
    name: 'robots',
    content: 'noindex,nofollow',
  }
}

export const createSchemaScript = (schema: WithContext<Thing>): ScriptItem => {
  return {
    type: 'application/ld+json',
    json: (schema as unknown) as ScriptPropertyJson['json'],
  }
}

export const createMetaItem = (
  key: keyof Omit<Seo, 'title'>,
  value?: string
): MetaItem | null => {
  switch (key) {
    case 'description': {
      return createMeta(key, value || DEFAULT_META_DESCRIPTION)
    }
    default: {
      return value?.length ? createMeta(key, value) : null
    }
  }
}

export const withPagination = (page: number) => (meta: MetaInfo): MetaInfo => {
  // Для первой страница никакие преобразования не требуются
  if (page === 1) return meta

  const addPageToString = (str: string): string => `${str} - Страница ${page}`

  const formatMetaItem = (metaItem: MetaItem): MetaItem => {
    return {
      ...metaItem,
      content: addPageToString(metaItem.content),
    }
  }

  return {
    ...meta,
    // TODO: заменить MetaInfo на локальный тип
    title: addPageToString(meta.title as string),
    meta: meta.meta?.map((metaItem) => {
      return metaItem.name === 'description'
        ? formatMetaItem(metaItem)
        : metaItem
    }),
  }
}
