import { injectable } from 'inversify'
import { Factory, UserAddressInputFactoryInterface } from '~/abstracts/factory'
import { GQLUserAddressInput } from '~/types/gql'
import { UserAddress } from '~/features/user/user-address/user-address.inteface'

@injectable()
export class UserAddressInputFactory
  extends Factory<UserAddress, GQLUserAddressInput>
  implements UserAddressInputFactoryInterface {
  public create(input: UserAddress): GQLUserAddressInput {
    return {
      id: input.ID,
      default: input.default,
      base: input.base,
      apartment: input.apartment ?? undefined,
      entrance: input.entrance ?? undefined,
      floor: input.floor ?? undefined,
      intercom: input.intercom ?? undefined,
    }
  }
}
