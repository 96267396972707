import Vue from 'vue'
import Vuex from 'vuex'
import { createVuexStore } from 'vuex-simple'
import { authorizationPlugin } from './plugins'

import { RootModule } from './root'

Vue.use(Vuex)

export default function () {
  // create our module class instance
  const instance = new RootModule()
  return createVuexStore(instance, {
    strict: true,
    plugins: [authorizationPlugin],
  })
}
