// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navigation_23igH{padding:24px;height:72px}.navList_6zYHH{display:flex;align-items:center;justify-content:space-between;overflow-x:auto}.navItem_1Sn_E{font-size:16px;line-height:24px;flex-shrink:0}.navItem_1Sn_E:not(:last-child){margin-right:16px}@media (min-width:1024px){.navItem_1Sn_E:not(:last-child){margin-right:0}}", ""]);
// Exports
exports.locals = {
	"navigation": "navigation_23igH",
	"navList": "navList_6zYHH",
	"navItem": "navItem_1Sn_E"
};
module.exports = exports;
