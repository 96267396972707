// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_gh-JM{display:flex;flex-direction:column;align-items:center;justify-content:center}.heading_2x4Zb{font-weight:500;font-size:22px;line-height:28px;margin-top:32px;margin-bottom:12px}.content_1hezW{text-align:center}.action_a_uCE{margin-top:38px;align-self:stretch}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_gh-JM",
	"heading": "heading_2x4Zb",
	"content": "content_1hezW",
	"action": "action_a_uCE"
};
module.exports = exports;
