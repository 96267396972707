// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headerInfo_1G3e8{justify-content:space-between;height:72px;color:var(--color-mystery-gray)}.headerInfo_1G3e8,.headerInfoItem_fZ1HA{display:flex;align-items:center}.headerInfoText_21w6r{font-size:14px;line-height:20px}.headerInfoItem_fZ1HA .icon_1Rs9Q{margin-right:8px}.phone_1XCCL{display:none}@media (min-width:1025px){.phone_1XCCL{display:block;color:var(--color-fragrant-cherry);font-size:16px;font-weight:700}}", ""]);
// Exports
exports.locals = {
	"headerInfo": "headerInfo_1G3e8",
	"headerInfoItem": "headerInfoItem_fZ1HA",
	"headerInfoText": "headerInfoText_21w6r",
	"icon": "icon_1Rs9Q",
	"phone": "phone_1XCCL"
};
module.exports = exports;
