import { useStore } from 'vuex-simple'
import styles from './styles.css?module'
import { VueComponent, Component, Prop, VNode } from '~/types/vue-ts-component'

import { RootModule } from '~/store/root'

interface Props {
  url: string
  text: string
}

@Component
export class ChildItem extends VueComponent<Props> {
  @Prop() readonly url!: Props['url']
  @Prop() readonly text!: Props['text']

  store = useStore<RootModule>(this.$store)

  async redirectToCategory(event: MouseEvent): Promise<void> {
    event.preventDefault()
    await this.$router.push(this.url)
    this.store.layout.toggleMobileMenu()
  }

  render(): VNode {
    return (
      <li class={styles.listItem}>
        <a
          href={this.url}
          class={styles.listItemText}
          onClick={this.redirectToCategory}
        >
          {this.text}
        </a>
      </li>
    )
  }
}
