export interface AppImageInterface {
  src: string
  alt: string
  title: string
  // getResized: (thumb: ImageThumb) => string
}

export interface GQLImageInput {
  name: string
  path: string
  alt: string
  title: string
}

export interface LocalImageInput {
  src: string
  alt: string
  title: string
}

export type AppImageInput = GQLImageInput | LocalImageInput

export enum ImageThumb {
  'thumb80' = 'thumb80',
  'thumb160' = 'thumb160',
  'thumb136' = 'thumb136',
  'thumb272' = 'thumb272',
  'thumb300' = 'thumb300',
  'thumb600' = 'thumb600',
  'thumb416' = 'thumb416',
  'thumb832' = 'thumb832',
  'original' = 'original',
}
