import { AuthorizationPopupProps } from './types'
import styles from './authorization-popup.scss?module'
import { Component, VueComponent, Prop, VNode } from '~/types/vue-ts-component'
import { Popup } from '~/components/UI/ui-kit/popup'
import { AuthorizationPhoneForm } from '~/components/features/authorization/authorization-popup/components/authorization-phone-form'
import { AuthorizationCodeForm } from '~/components/features/authorization/authorization-popup/components/authorization-code-form'
import transitions from '~/assets/styles/transitions.css?module'

import '~/assets/styles/buefy-input-reset.scss'
import { UserRepositoryInterface } from '~/abstracts/repository'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { phoneToInput } from '~/utils/format-phone'
import { setAuthToken } from '~/utils/auth-token'
import { AuthorizationFormError } from '~/components/features/authorization/authorization-popup/components/authorization-form-error'

interface Props extends AuthorizationPopupProps {
  whenSuccess: () => void
}

@Component
export class AuthorizationPopupCart extends VueComponent<Props> {
  @Prop() readonly isShown!: Props['isShown']
  @Prop() readonly whenClose!: Props['whenClose']
  @Prop() readonly whenSuccess!: Props['whenSuccess']

  userRepository!: UserRepositoryInterface

  phoneSent = false

  codeIncorrect = false
  hasError = false

  phone = ''

  async sendCode(phone: string): Promise<void> {
    try {
      const success = await this.userRepository.getAuthorizationCode(
        phoneToInput(phone)
      )

      success && (this.phoneSent = true)
    } catch (error: unknown) {
      this.hasError = true
      await this.$logger.log(error as string)
    }
  }

  async loginBySMS(code: string): Promise<void> {
    try {
      const token = await this.userRepository.loginBySms({
        phone: phoneToInput(this.phone),
        code: Number(code),
      })

      this.codeIncorrect = !token

      if (!token) return

      setAuthToken(token)

      this.handleClose()
      this.clearState()
      this.whenSuccess()
    } catch (error: unknown) {
      await this.$logger.log(error as string)
      this.codeIncorrect = true
    }
  }

  clearState(): void {
    this.phone = ''
    this.phoneSent = false
    this.codeIncorrect = false
  }

  handleClose(): void {
    this.clearState()
    this.whenClose()
  }

  created(): void {
    this.userRepository = container.get(DiTypes.USER_REPOSITORY)
  }

  render(): VNode {
    if (this.hasError) {
      return (
        <Popup
          width="416px"
          isShown={this.isShown}
          whenClose={this.handleClose}
        >
          <AuthorizationFormError whenClick={this.handleClose} />
        </Popup>
      )
    }

    return (
      <Popup width="416px" isShown={this.isShown} whenClose={this.handleClose}>
        <transition
          enterClass={transitions['fade-enter']}
          enterActiveClass={transitions['fade-enter-active']}
          leaveActiveClass={transitions['fade-leave-active']}
          leaveToClass={transitions['fade-leave-active']}
          leaveClass={transitions['fade-leave-to']}
          mode="out-in"
        >
          <section class={styles.wrapper}>
            {this.phoneSent ? (
              <AuthorizationCodeForm
                phone={this.phone}
                resendCode={this.sendCode}
                codeIncorrect={this.codeIncorrect}
                moveBackwards={this.clearState}
                whenSubmit={this.loginBySMS}
              />
            ) : (
              <AuthorizationPhoneForm
                heading="Авторизуйтесь, чтобы продолжить"
                phone={this.phone}
                updatePhone={(phone: string) => {
                  this.phone = phone
                }}
                sendCode={() => this.sendCode(this.phone)}
              >
                <p slot="subHeader" class={styles.subheader}>
                  Для оформления заказа необходимо авторизоваться
                </p>
              </AuthorizationPhoneForm>
            )}
          </section>
        </transition>
      </Popup>
    )
  }
}
