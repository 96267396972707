import { MetaInfo } from 'vue-meta'
import { Factory, IMetaInfoFactory } from '~/abstracts/factory'
import { Seo } from '~/features/seo'
import { Nullable } from '~/types/helpers'
import {
  createCanonicalLink,
  createMetaItem,
} from '~/app/meta-info/meta-info.utils'
import { DEFAULT_META_TITLE } from '~/config/meta-info'

export interface MetaInput {
  seo?: Nullable<Seo>
  path?: string
}

export class MetaInfoFactory
  extends Factory<MetaInput, MetaInfo>
  implements IMetaInfoFactory {
  public create({ seo, path }: MetaInput): MetaInfo {
    const meta: MetaInfo = {}

    if (path) {
      meta.link = [createCanonicalLink(path)]
    }

    if (!seo) return meta

    const seoMeta = Object.entries(seo).reduce<MetaInfo>(
      (acc, [key, value]) => {
        if (key === 'title') {
          acc.title = value || DEFAULT_META_TITLE
          return acc
        }
        const metaItem = createMetaItem(key as keyof Omit<Seo, 'title'>, value)

        if (!metaItem) return acc

        acc.meta = acc.meta ? [...acc.meta, metaItem] : [metaItem]
        return acc
      },
      {}
    )

    return {
      ...seoMeta,
      ...meta,
    }
  }
}
