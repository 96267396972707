import styles from './authorization-form-layout.scss?module'
import { Component, VueComponent, Prop, VNode } from '~/types/vue-ts-component'

interface Props {
  heading: string
}

@Component
export class AuthorizationFormLayout extends VueComponent<Props> {
  @Prop() readonly heading!: Props['heading']

  render(): VNode {
    return (
      <div class={styles.container}>
        <div class={styles.heading}>{this.heading}</div>
        {this.$slots.default}
      </div>
    )
  }
}
