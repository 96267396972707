import styles from './scroll-top-btn.scss?module'
import { ScrollTopBtnProps as Props } from './scroll-top-btn.types'
import transitions from '~/assets/styles/transitions.css?module'
import { Component, VueComponent, Prop, VNode } from '~/types/vue-ts-component'

@Component
export class ScrollTopBtn extends VueComponent<Props> {
  @Prop() readonly whenClicked!: Props['whenClicked']

  render(): VNode {
    return (
      <transition
        appear
        enterActiveClass={transitions['slide-bottom-top-active']}
        enterClass={transitions['slide-bottom-top-enter']}
        enterToClass={transitions['slide-bottom-top-to']}
        leaveActiveClass={transitions['slide-bottom-top-active']}
        leaveClass={transitions['slide-bottom-top-to']}
        leaveToClass={transitions['slide-bottom-top-enter']}
        mode="in-out"
      >
        <div role="button" class={styles.button} onClick={this.whenClicked}>
          <img
            alt="Наверх"
            width={16}
            height={8}
            src={require('~/assets/icons/chevron.svg')}
          />
          <span class={styles.label}>Наверх</span>
        </div>
      </transition>
    )
  }
}
