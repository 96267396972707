interface CompanyContacts {
  readonly phone: {
    main: string
    mobile: string
  }
  readonly email: string
  readonly fillName: string
  readonly ogrn: string
  readonly inn: string
  readonly address: {
    legal: string
    main: string
  }
  readonly operationHours: string
}

export const COMPANY_CONTACTS: CompanyContacts = {
  phone: { main: '+74957378329', mobile: '+79035694983' },
  email: 'apteka@bosco.ru',
  fillName: 'Общество с ограниченной ответственностью «Аптека Боско»',
  ogrn: '1127746146824',
  inn: '7710908022',
  address: {
    legal:
      '109012, г. Москва, Ветошный переулок, д. 17, эт. 4, пом. 1, комн. 17',
    main: '107031, г. Москва, ул. Петровка, д. 10',
  },
  operationHours: 'Ежедневно с 10:00 до 22:00',
}
