import { inject } from 'inversify'
import { IBannerRepository, Repository } from '~/abstracts/repository'
import { Banner } from '~/features/banner/banner.interface'
import DiTypes from '~/config/DiTypes'
import { IBannerFactory } from '~/abstracts/factory'
import { Apollo } from '~/api/GraphqlClient'
import { GQLQuery } from '~/types/gql'

export class BannerRepository extends Repository implements IBannerRepository {
  bannerFactory: IBannerFactory

  constructor(
    @inject(DiTypes.BANNER_FACTORY) bannerFactory: IBannerFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.bannerFactory = bannerFactory
  }

  public async getBanners(): Promise<Banner[]> {
    const { data } = await this.apollo.client.query<{
      Banners: GQLQuery['Banners']
    }>({
      query: await import('~/graphql/banner/banners.graphql'),
    })

    return this.bannerFactory.fromArray(data.Banners)
  }
}
