import { createDecorator } from 'vue-class-component'

export const debounce = function (delay: number = 200) {
  let inDebounce: NodeJS.Timeout

  return createDecorator((vueOptions, key) => {
    // Записываем ссылку на оригинальный метод
    const originalMethod = vueOptions.methods?.[key]
    vueOptions.methods = vueOptions.methods || {}
    vueOptions.methods[key] = function wrapperMethod(...args) {
      clearTimeout(inDebounce)
      inDebounce = setTimeout(
        async () => await originalMethod?.apply(this, args),
        delay
      )
    }
  })
}
