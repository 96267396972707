import Vue from 'vue'
import { Component } from 'vue-property-decorator'

// Nuxt hooks
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'asyncData',
  'fetch',
  'head',
  'middleware',
  'layout',
  'transition',
  'scrollToTop',
  'validate',
])

type CSSClass =
  | string
  | false
  | {
      [key: string]: string
    }

export class VueComponent<P = {}> extends Vue {
  // @ts-ignore
  public $props: P & {
    key?: string | number
    class?: CSSClass | CSSClass[]
    onClick?: (e: Event) => void
    onInput?: (e: any) => void
    vShow?: boolean
    on?: { [key: string]: any }
    slot?: string
    ref?: string
  }
}

export { VNode } from 'vue'
export { Component }
export { Prop, Watch, Emit, Ref, Provide, Inject } from 'vue-property-decorator'
