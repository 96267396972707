import { Operation } from 'apollo-link'
import { GraphQLError } from 'graphql'
import { Breadcrumb, Primitive } from '@sentry/types'
import { isEmpty, stringifyObject, trimObject } from '~/utils/object'

/**
 * Сборка объекта хлебных крошек Sentry с инф по запросу
 * @param operation
 * @param error
 */
export const geBreadcrumb = (
  operation: Operation,
  error: GraphQLError
): Breadcrumb => {
  const data: Record<string, string | undefined> = {
    query: operation.query.loc?.source
      ? operation.query?.loc.source.body
      : undefined,
    variables: stringifyObject(operation.variables),
    error: stringifyObject(error),
  }

  return trimObject({
    data,
    message: error.message,
  })
}

/**
 * Сборка словаря тегов Sentry
 *
 * @param extensions
 */
export const getTagsFromExtensions = (
  extensions: GraphQLError['extensions']
): { [key: string]: Primitive } | undefined => {
  if (!extensions) return

  const tags: { [key: string]: Primitive } = {}

  const targetKeys = ['category']

  Object.entries(extensions).forEach(([key, value]) => {
    if (targetKeys.includes(key)) {
      tags[key] = value
    }
  })

  return (!isEmpty(tags) && tags) || undefined
}
