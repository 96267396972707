import { Plugin } from 'vuex'
import type { RootModule } from '~/store/root'
import { useStore } from '~/store/root'
import { ClientStorageManager } from '~/app/client-storage-manager/client-storage-manager'
import { ClientStorageKey } from '~/app/client-storage-manager/client-storage-manager.types'

// Сайд эффекты при изменении состяния модуля авторизации
export const authorizationPlugin: Plugin<unknown> = ($store) => {
  $store.subscribe((mutation) => {
    const { cart } = useStore<RootModule>($store)

    if (mutation.type === 'authorization/setAuthenticated') {
      if (!mutation.payload) {
        ClientStorageManager.clearValue(ClientStorageKey.CartToken)
        cart.setCartToken()
      }
      cart.getCartItems()
    }
  })
}
