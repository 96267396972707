import { getImageSrc } from './app-image.utils'
import {
  AppImageInput,
  AppImageInterface,
  ImageThumb,
} from '~/app/image/app-image.interface'
import { isGQLImageInput } from '~/app/image/app-image.utils'

export class AppImage implements AppImageInterface {
  private readonly name?: string
  private readonly path?: string

  public src: string
  public alt: string
  public title: string

  constructor(input: AppImageInput) {
    this.alt = input.alt
    this.title = input.title

    if (isGQLImageInput(input)) {
      this.name = input.name
      this.path = input.path
      this.src = AppImage.getResized(ImageThumb.original, this)
    } else {
      this.src = input.src
    }
  }

  static getResized(thumb: ImageThumb, image: AppImage): string {
    if (!image.name || !image.path) return image.src
    return getImageSrc(`${image.path}/${thumb}/${image.name}`)
  }
}
