import { ClientStorageKey } from '~/app/client-storage-manager/client-storage-manager.types'

export class ClientStorageManager {
  static getValue<T>(key: ClientStorageKey): T | undefined {
    if (typeof window === 'undefined') return
    const data = localStorage.getItem(key)

    return data ? JSON.parse(data) : undefined
  }

  static setValue(key: ClientStorageKey, value: unknown): void {
    if (typeof window === 'undefined') return
    localStorage.setItem(key, JSON.stringify(value))
  }

  static clearValue(key: ClientStorageKey): void {
    if (typeof window === 'undefined') return
    localStorage.removeItem(key)
  }
}
