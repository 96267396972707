import { Factory } from '~/abstracts/factory'
import { OrderItem } from '~/entities/order-item'
import { GQLOrderItemInput } from '~/types/gql'

export class OrderItemInputFactory extends Factory<
  OrderItem,
  GQLOrderItemInput
> {
  public create(cartItem: OrderItem): GQLOrderItemInput {
    return {
      id: cartItem.product.ID,
      quantity: cartItem.qty,
    }
  }
}
