import { inject, injectable } from 'inversify'
import { Factory, OrderItemFactoryInterface } from '~/abstracts/factory'
import { GQLOrderItem, GQLProduct } from '~/types/gql'
import { OrderItem } from '~/features/order/order-item/order-item.interface'
import DiTypes from '~/config/DiTypes'
import { ProductFactoryInterface } from '~/features/product/product-factory'
import { Product } from '~/entities/product'
import { generateRandomID } from '~/utils/generate-random'

@injectable()
export class OrderItemFactory
  extends Factory<GQLOrderItem, OrderItem>
  implements OrderItemFactoryInterface {
  constructor(
    @inject(DiTypes.PRODUCT_FACTORY)
    private productFactory: ProductFactoryInterface
  ) {
    super()
  }

  public create(input: GQLOrderItem): OrderItem {
    return {
      qty: input.quantity,
      product: this.createProduct(input),
    }
  }

  private createProduct(orderItem: GQLOrderItem): Product {
    return this.productFactory.create({
      id: generateRandomID(),
      name: orderItem.name,
      slug: orderItem.slug,
      price: orderItem.price,
      oldPrice: orderItem.oldPrice,
      images: orderItem.product?.images ?? [],
      meta: {
        inFavourite: orderItem.product?.meta.inFavourite || false,
      },
    } as GQLProduct)
  }
}
