import { Factory, IBannerFactory } from '~/abstracts/factory'
import { GQLBanner } from '~/types/gql'
import { Banner } from '~/features/banner/banner.interface'
import { cndLink } from '~/utils/cdn'

export class BannerFactory
  extends Factory<GQLBanner, Banner>
  implements IBannerFactory {
  public create(input: GQLBanner): Banner {
    return {
      id: input.id,
      desktopImage: cndLink(`/${input.desktopImage}`),
      mobileImage: cndLink(`/${input.mobileImage}`),
      url: input.url ?? undefined,
    }
  }
}
