import { Action, Mutation, State, Getter } from 'vuex-simple'
import { flatten } from 'ramda'
import { container } from '~/config/invercify'
import { CategoryRepositoryInterface } from '~/abstracts/repository'
import DiTypes from '~/config/DiTypes'
import { CategoryTreeType } from '~/entities/category-tree'
import { GQLCategoryFlag } from '~/types/gql'
import { Link } from '~/entities/link'
import { createLinkFromCategoryTreeType } from '~/utils/create-link'
import { breadcrumbsReducer } from '~/utils/breadcrumbs-reducer'
import {
  filterByFlag,
  findByPath,
} from '~/store/modules/category-tree/category-tree.utils'

export class CategoryTreeModule {
  private categoryRepository: CategoryRepositoryInterface

  @State()
  public items: CategoryTreeType[] = []

  constructor() {
    this.categoryRepository = container.get<CategoryRepositoryInterface>(
      DiTypes.CATEGORY_REPOSITORY
    )
  }

  @Getter()
  get menuCategoryTree(): CategoryTreeType[] {
    return filterByFlag(this.items, GQLCategoryFlag.Menu)
  }

  // переносится эз из, посмотреть по проекту, возможно не нужно
  @Getter()
  get mainCategories(): CategoryTreeType[] {
    return Object.values(this.items)
  }

  @Getter()
  get mainCategoriesFlat(): CategoryTreeType[] {
    return Object.values(this.menuCategoryTree).map((category) => ({
      ...category,
      children: [],
    }))
  }

  @Mutation()
  setCategoryTree(categoryTree: CategoryTreeType[]) {
    this.items = categoryTree
  }

  @Action()
  public async initiate(): Promise<void> {
    const categoryTree = await this.categoryRepository.getCategoriesTree()
    this.setCategoryTree(categoryTree)
  }

  public getCategoryId(path: string): string {
    // TODO: fix types
    return String(findByPath(this.items, path)?.data.id || 0)
  }

  /*
   *   Возвращает список прямых потомков категории по пути
   * */
  public getCategoryChildrenIDs(path: string): string[] {
    const category = findByPath(this.items, path)
    if (!category) return []
    return flatten(category.children).map(({ data }) => String(data.id))
  }

  /*
   *  Возвращает список прямых потомков категории по категории
   * */
  public getCategoryChildrenIDsV2(category: CategoryTreeType): string[] {
    return flatten(category.children).map(({ data }) => String(data.id))
  }

  public findCategoryByPath(path: string): CategoryTreeType | undefined {
    return findByPath(this.items, path)
  }

  /*
   *   Возвращает список всех потомков категории, вне зависимости от уровня вложенности
   * */
  public getCategoryAndDescendentIDs(category: CategoryTreeType): string[] {
    const result: string[] = []

    const traverse = (category: CategoryTreeType) => {
      result.push(String(category.data.id))
      if (!category.children.length) return
      for (const child of category.children) {
        traverse(child)
      }
    }
    traverse(category)

    return result
  }

  public getSubcategories(parentSlug: string): CategoryTreeType[] {
    const parent = Object.values(this.menuCategoryTree).find(
      ({ data: { slug } }) => slug === parentSlug
    )
    return parent?.children || []
  }

  // TODO: refactor
  public getCategoryName(path: string[]): string {
    const [categorySlug, subcategorySlug, subSubCategorySlug] = path
    const category = Object.values(this.items).find(({ data: { slug } }) => {
      return slug === categorySlug
    })
    if (!category) return ''
    const subCategory = Object.values(category.children).find(
      ({ data: { slug } }) => {
        return slug === subcategorySlug
      }
    )
    if (!subCategory) return category.text
    const subSubcategory = Object.values(subCategory.children).find(
      ({ data: { slug } }) => {
        return slug === subSubCategorySlug
      }
    )
    if (!subSubcategory) return subCategory.text
    return subSubcategory.text
  }

  // TODO: refactor
  // Имена ссылок для хлебных крошек
  public getBreadcrumbs(path: string[]): Link[] | undefined {
    const [categorySlug, subcategorySlug, subSubCategorySlug] = path
    const names: Link[] = []

    const category = Object.values(this.items).find(({ data: { slug } }) => {
      return slug === categorySlug
    })

    if (!category) return

    names.push(createLinkFromCategoryTreeType(category))

    const subCategory = Object.values(category.children).find(
      ({ data: { slug } }) => {
        return slug === subcategorySlug
      }
    )

    if (subCategory) {
      names.push(createLinkFromCategoryTreeType(subCategory))

      const subSubcategory = Object.values(subCategory.children).find(
        ({ data: { slug } }) => {
          return slug === subSubCategorySlug
        }
      )

      Boolean(subSubcategory) &&
        names.push(
          createLinkFromCategoryTreeType(subSubcategory as CategoryTreeType)
        )
    }

    return names.reduce(breadcrumbsReducer, [])
  }
}
