// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "button.button_By9n9:active,button.button_By9n9:focus{outline:none}.button_By9n9{--font-weight:500;--height:38px;--font-size:14px;--border-width:2px;display:inline-flex;align-items:center;justify-content:center;box-sizing:border-box;height:calc(var(--height) + var(--border-width));padding:var(--padding);color:var(--color);font-size:var(--font-size);line-height:var(--font-size);font-weight:var(--font-weight);background-color:var(--bg-color);border-radius:28px;border:var(--border-width) solid var(--border-color);transition-timing-function:ease-out;transition-duration:.15s;transition-property:background-color,color,border-color}.button_By9n9:not(.disabled_s4ELq){cursor:pointer}.primary_3vWLV{--bg-color:var(--color-ripe-plum);--border-color:var(--color-ripe-plum);--color:var(--color-snow-white)}.primary_3vWLV:focus,.primary_3vWLV:hover{--bg-color:var(--color-tiffany-cactus);--border-color:var(--color-tiffany-cactus);--color:var(--color-snow-white)}.secondary_X2qXB{--bg-color:var(--color-lightest-gray);--border-color:var(--color-lightest-gray)}.outlined_3K8xm,.secondary_X2qXB{--color:var(--color-ripe-plum)}.outlined_3K8xm{--border-color:var(--color-ripe-plum)}.M_V2W-o{--padding:6px 24px}.S_1mPDg{--padding:6px 16px}@media (min-width:941px){.button_By9n9{--height:38px;--font-size:18px}.M_V2W-o{--padding:8px 52px}.S_1mPDg{--padding:8px 32px}}@media (max-width:414px){.button_By9n9{display:block;width:100%}}", ""]);
// Exports
exports.locals = {
	"button": "button_By9n9",
	"disabled": "disabled_s4ELq",
	"primary": "primary_3vWLV",
	"secondary": "secondary_X2qXB",
	"outlined": "outlined_3K8xm",
	"M": "M_V2W-o",
	"S": "S_1mPDg"
};
module.exports = exports;
