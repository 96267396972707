import { MetaInfo } from 'vue-meta'

import styles from './default.css?module'
import {
  Component,
  Ref,
  VNode,
  VueComponent,
  Watch,
} from '~/types/vue-ts-component'
import { AppFooter } from '~/components/layout/footer'
import { NavigationMobile } from '~/components/layout/navigation/navigation-mobile'

import '~/assets/styles/reset.css'
import { MobileMenu } from '~/components/layout/mobile-menu'
import { RootModule, useStore } from '~/store/root'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { HeaderMobile, HeaderDesktop } from '~/components/layout/header'
import { AuthorizationPopup } from '~/components/features/authorization/authorization-popup'
import { getAuthToken } from '~/utils/auth-token'
import { MobileMenuProfile } from '~/components/layout/mobile-menu-profile/mobile-menu-profile'
import { IMetaInfoFactory } from '~/abstracts/factory'
import { deepCopy } from '~/utils/helpers'
import { WithScrollTopBtn } from '~/components/layout/common/with-scroll-top-btn/with-scroll-top-btn'

@Component
export default class DefaultLayout extends VueComponent {
  head(): MetaInfo {
    return container
      .get<IMetaInfoFactory>(DiTypes.META_INFO_FACTORY)
      .create({ path: this.$route.path })
  }

  store = useStore<RootModule>(this.$store)

  @Ref('headerMobile')
  headerMobile!: HeaderMobile

  get menuVisible(): boolean {
    return this.store.layout.mobileMunuVisible
  }

  get showFooter(): boolean {
    return !this.menuVisible && !this.profileNavigation
  }

  get profileNavigation(): boolean {
    return this.store.layout.profileNavigation
  }

  get authPopup(): boolean {
    return this.store.authorization.authPopup
  }

  get mainContent(): VNode {
    if (this.menuVisible) {
      return <MobileMenu toggleMobileMenu={this.toggleMobileMenu} />
    }
    if (this.profileNavigation) {
      return <MobileMenuProfile />
    }

    return <nuxt />
  }

  closeAllPopups(): void {
    this.menuVisible && this.toggleMobileMenu()
    this.authPopup && this.store.authorization.setPopupShow(false)
    this.profileNavigation && this.store.layout.setProfileNavigation(false)
  }

  toggleMobileMenu(): void {
    this.store.layout.toggleMobileMenu()
  }

  // TODO: refactor
  // нужен сброс состояния элементов дерева при закрытии меню
  // перенести метод в модуль с деревом
  @Watch('menuVisible')
  clearTreeState(val: boolean) {
    if (!val) {
      const treeCopy = deepCopy(this.store.categoryTree.items)
      treeCopy.forEach((item) => {
        item.state.expanded = false
        item.children.forEach((child) => {
          child.state.expanded = false
        })
      })
      this.store.categoryTree.setCategoryTree(treeCopy)
    }
  }

  @Watch('$route.fullPath')
  whenPathChanges() {
    this.store.layout.hideMobileMenu()
    this.headerMobile.clearSearchState()
  }

  render(): VNode {
    return (
      <WithScrollTopBtn>
        <div ref="layout" class={styles.layout}>
          <header>
            <HeaderMobile
              ref="headerMobile"
              menuVisible={this.menuVisible}
              toggleMobileMenu={this.toggleMobileMenu}
              closeAllPopups={this.closeAllPopups}
            />
            <HeaderDesktop />
          </header>
          <div class={styles.contentWrapper}>{this.mainContent}</div>
          {this.showFooter && <AppFooter />}
          <NavigationMobile toggleMobileMenu={this.toggleMobileMenu} />
          {this.authPopup && (
            <AuthorizationPopup
              isShown={this.authPopup}
              whenClose={() => {
                this.store.authorization.setPopupShow(false)
                if (!getAuthToken()) return
                this.store.authorization.setAuthenticated(true)
                this.$router.push('/profile')
              }}
            />
          )}
        </div>
      </WithScrollTopBtn>
    )
  }
}
