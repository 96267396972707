import { GQLProduct } from '~/types/gql'

export const gqlProductSorter = (a: GQLProduct, b: GQLProduct): 1 | -1 | 0 => {
  if (a.score === b.score) return 0
  return a.score > b.score ? -1 : 1
}

export const sortGqlProducts = (products: GQLProduct[]): GQLProduct[] => {
  return products.sort(gqlProductSorter)
}
