import { Brand } from '~/features/brand'
import { Mapper } from '~/repositories/types'
import { GQLBrand } from '~/types/gql'
import { cndLink } from '~/utils/cdn'

export const getBrandOutput: Mapper<GQLBrand[], Brand[]> = (
  input: GQLBrand[]
) => {
  return input.map(({ id, name, slug, logo }) => ({
    id,
    name,
    slug,
    logo: logo ? cndLink('/' + logo) : undefined
  }))
}
