// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_2hlQ7{--size:56px;--gap-x:var(--gap-medium);--gap-y:var(--gap-2xl);position:fixed;bottom:var(--gap-y);left:calc(100% - var(--size) - var(--gap-x));display:inline-flex;flex-direction:column;align-items:center;justify-content:center;width:var(--size);height:var(--size);background-color:var(--color-lightest-gray);border-radius:var(--border-rounded);box-shadow:0 0 4px rgba(180,186,203,.8);z-index:var(--z-floating);cursor:pointer;-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.button_2hlQ7:focus{outline:none!important}.label_1d79A{--label-display:none;font-weight:500;font-size:14px;line-height:20px;display:var(--label-display);color:#b4bacb}@media (min-width:961px){.button_2hlQ7{--size:var(--gap-xl);--gap-x:var(--gap-xl);--gap-y:var(--gap-xl)}.label_1d79A{--label-display:block}}", ""]);
// Exports
exports.locals = {
	"button": "button_2hlQ7",
	"label": "label_1d79A"
};
module.exports = exports;
