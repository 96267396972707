import { ChildItem } from './child-item'
import styles from './styles.css?module'
import { VueComponent, Component, Prop, VNode } from '~/types/vue-ts-component'
import { CategoryTreeType } from '~/entities/category-tree'

interface Props {
  item: CategoryTreeType
  parentItemSlug?: string
  handleItemClick: any
}

@Component
export class ParentItem extends VueComponent<Props> {
  @Prop() readonly item!: Props['item']
  @Prop() readonly parentItemSlug!: Props['parentItemSlug']
  @Prop() readonly handleItemClick!: Props['handleItemClick']

  get hasChildren(): boolean {
    return Boolean(this.item.children.length)
  }

  getChildItemUrl(childItem: CategoryTreeType): string {
    return this.parentItemSlug
      ? `${this.parentItemSlug}/${this.item.data.slug}/${childItem.data.slug}`
      : `${this.item.data.slug}/${childItem.data.slug}`
  }

  getParentItemSlug(): string {
    return this.parentItemSlug
      ? `${this.parentItemSlug}/${this.item.data.slug}`
      : this.item.data.slug
  }

  render(): VNode {
    return (
      <li class={styles.listItem}>
        <div
          class={styles.listItemText}
          onClick={() => this.handleItemClick(this.item)}
        >
          <span>{this.item}</span>
          {this.hasChildren && (
            <img
              src={require(`~/assets/icons/${
                this.item.state.expanded ? 'arrow-down' : 'arrow-right'
              }.svg`)}
            />
          )}
        </div>
        {this.hasChildren && (
          <ul vShow={this.item.state.expanded} class={styles.childList}>
            {this.item.children.map((childItem) =>
              childItem.children.length ? (
                <ParentItem
                  key={`p_${childItem.data.id}`}
                  item={childItem}
                  parentItemSlug={this.getParentItemSlug()}
                  handleItemClick={this.handleItemClick}
                />
              ) : (
                <ChildItem
                  key={`c_${childItem.data.id}`}
                  url={this.getChildItemUrl(childItem)}
                  text={childItem.text}
                />
              )
            )}
          </ul>
        )}
      </li>
    )
  }
}
